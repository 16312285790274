"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AttributesFilterContainer = exports.default = void 0;
var react_1 = __importStar(require("react"));
var ramda_1 = require("ramda");
var react_redux_1 = require("react-redux");
var attributes_filter_component_1 = require("./attributes-filter.component");
var attributes_utils_1 = require("./attributes.utils");
var formal_attributes_1 = require("../../formal-attributes");
var State = __importStar(require("../../../store"));
var store_1 = require("../../../store");
var AttributesFilterContainer = function (_a) {
    var openAttributes = _a.openAttributes, setOpenAttributes = _a.setOpenAttributes, searchTerm = _a.searchTerm, className = _a.className, attributeTotals = _a.attributeTotals, isProductCountsLoading = _a.isProductCountsLoading;
    var includedAttributesIds = (0, store_1.useProductsIncludedAttributesIds)();
    var excludedAttributesIds = (0, store_1.useProductsExcludedAttributesIds)();
    var actualAttributes = (0, store_1.useRefinementsDataSelector)().actualAttributes;
    var refinementsLoading = (0, store_1.useRefinementsLoadingSelector)();
    var dispatch = (0, react_redux_1.useDispatch)();
    var normalizeTermForSearch = function (s) { return s.trim().toLowerCase(); };
    var normalizedSearchTerm = normalizeTermForSearch(searchTerm);
    var _b = (0, formal_attributes_1.useFormalAttributes)(), loadFormalAttributes = _b.loadFormalAttributes, getAttributeName = _b.getAttributeName, getAttributeValueName = _b.getAttributeValueName;
    var isAttributesLoading = refinementsLoading || isProductCountsLoading;
    var actualAttributesMap = actualAttributes.reduce(function (map, actualAttribute) {
        var entry = map.get(actualAttribute.attributeId);
        if (!entry) {
            map.set(actualAttribute.attributeId, entry = [actualAttribute]);
        }
        else {
            entry.push(actualAttribute);
        }
        return map;
    }, new Map());
    (0, react_1.useEffect)(function () {
        if (actualAttributes === null || actualAttributes === void 0 ? void 0 : actualAttributes.length) {
            void loadFormalAttributes(actualAttributes);
        }
    }, [actualAttributes, loadFormalAttributes]);
    var refinementsFilter = (0, store_1.useRefinementsFilterSelector)();
    var filteredExpansionAttributes = (0, react_1.useMemo)(function () { return __spreadArray([], __read(actualAttributesMap.entries()), false).map(function (_a) {
        var _b = __read(_a, 2), attributeId = _b[0], subAttributes = _b[1];
        var subAttributesWithTitle = subAttributes.map(function (subAttribute) {
            var _a, _b;
            // Because "sub attributes" are actually just the same attribute with different valueIds,
            // For now inclusion/exclusion and counts are always going to be the same for all subAttributes.
            var subAttributeId = subAttribute.attributeId;
            var isMatchesFilter = refinementsFilter === State.AvailableRefinementType.Matches;
            var isInIncludedAttributesIds = includedAttributesIds.includes(subAttributeId);
            var isInExcludedAttributesIds = excludedAttributesIds.includes(subAttributeId);
            var isMatch = isInIncludedAttributesIds || isInExcludedAttributesIds;
            var isShown = isMatchesFilter ? isMatch : true;
            var title = (0, attributes_utils_1.getAttributeValueTitle)({ subAttribute: subAttribute, getAttributeName: getAttributeName, getAttributeValueName: getAttributeValueName });
            return isShown && title ? __assign(__assign({}, subAttribute), { id: [subAttributeId, (_a = subAttribute.valueId) !== null && _a !== void 0 ? _a : '', (_b = subAttribute.valueName) !== null && _b !== void 0 ? _b : ''].join(store_1.ATTRIBUTE_PROPERTY_DELIMITER), title: title, total: attributeTotals[subAttribute.attributeId] }) : null;
        })
            .filter(function (subAttributeWithTitle) { return !!subAttributeWithTitle; })
            .sort(function (a, b) { return (a.title > b.title ? 1 : -1); });
        var title = (0, attributes_utils_1.getAttributeValueTitle)({ subAttribute: { attributeId: attributeId }, getAttributeName: getAttributeName, getAttributeValueName: getAttributeValueName });
        return title ? {
            id: attributeId,
            attributeId: attributeId,
            title: title,
            total: attributeTotals[attributeId],
            subAttributes: subAttributesWithTitle,
        } : null;
    })
        .filter(function (attribute) { return !!attribute; })
        .map(function (_a) {
        var title = _a.title, subAttributes = _a.subAttributes, rest = __rest(_a, ["title", "subAttributes"]);
        return (__assign({ title: title, subAttributes: normalizeTermForSearch(title).includes(normalizedSearchTerm)
                ? subAttributes : subAttributes.filter(function (_a) {
                var title = _a.title;
                return normalizeTermForSearch(title).includes(normalizedSearchTerm);
            }) }, rest));
    })
        .filter(function (_a) {
        var subAttributes = _a.subAttributes;
        return !(0, ramda_1.isEmpty)(subAttributes);
    }); }, [
        normalizedSearchTerm,
        excludedAttributesIds,
        includedAttributesIds,
        refinementsFilter,
        attributeTotals,
        getAttributeName,
        getAttributeValueName,
        actualAttributesMap,
    ]);
    var handleHeaderClick = (0, react_1.useCallback)(function (id) { return setOpenAttributes((0, ramda_1.assoc)(id, !openAttributes[id], openAttributes)); }, [openAttributes, setOpenAttributes]);
    var handleExpandAll = (0, react_1.useCallback)(function () { return setOpenAttributes(actualAttributes.reduce(function (acc, attribute) {
        var _a;
        return (__assign(__assign({}, acc), (_a = {}, _a[attribute.attributeId] = true, _a)));
    }, {})); }, [actualAttributes]);
    var handleCollapseAll = (0, react_1.useCallback)(function () { return setOpenAttributes({}); }, [setOpenAttributes]);
    (0, react_1.useEffect)(function () {
        if (actualAttributesMap === null || actualAttributesMap === void 0 ? void 0 : actualAttributesMap.size) {
            dispatch((0, store_1.attributesSetValuesMap)(actualAttributesMap));
        }
    }, [actualAttributesMap]);
    return (react_1.default.createElement(attributes_filter_component_1.AttributesFilter, { className: className, expansionAttributes: filteredExpansionAttributes, onCollapseAll: handleCollapseAll, onExpandAll: handleExpandAll, onHeaderClick: handleHeaderClick, openAttributes: openAttributes, isAttributesLoading: isAttributesLoading }));
};
var MemoizedAttributesFilterContainer = react_1.default.memo(AttributesFilterContainer);
exports.default = MemoizedAttributesFilterContainer;
exports.AttributesFilterContainer = MemoizedAttributesFilterContainer;
