"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Table = void 0;
var react_1 = __importStar(require("react"));
var material_1 = require("@mui/material");
var table_header_1 = require("./partials/table-header");
var table_rows_1 = require("./partials/table-rows");
var table_styles_1 = require("./table.styles");
var pagination_1 = require("../pagination");
var client_constants_1 = require("../../enrich-ai/client/client.constants");
var localization_1 = require("../../localization");
var Table = function (_a) {
    var data = _a.data, _b = _a.isSelectable, isSelectable = _b === void 0 ? true : _b, columns = _a.columns, total = _a.total, pageNumber = _a.pageNumber, rowsPerPage = _a.rowsPerPage, setPage = _a.setPage, setPerPage = _a.setPerPage, _c = _a.selected, selected = _c === void 0 ? [] : _c, selectedCount = _a.selectedCount, _d = _a.onSelect, onSelect = _d === void 0 ? function () { return null; } : _d, isLoading = _a.isLoading, error = _a.error, onRowClick = _a.onRowClick, _e = _a.onWholePageSelect, onWholePageSelect = _e === void 0 ? function () { return null; } : _e, _f = _a.isWholePageSelected, isWholePageSelected = _f === void 0 ? false : _f, className = _a.className, children = _a.children, noDataMessage = _a.noDataMessage, bulkActions = _a.bulkActions, filter = _a.filter, updateFilter = _a.updateFilter;
    var t = (0, localization_1.useLocalization)().formatMessage;
    var tableRef = (0, react_1.useRef)(null);
    var _g = __read((0, react_1.useState)(false), 2), tableHasScrollY = _g[0], setTableHasScrollY = _g[1];
    (0, react_1.useEffect)(function () {
        if (tableRef.current) {
            setTableHasScrollY(tableRef.current.scrollHeight > tableRef.current.clientHeight);
        }
    }, [data.length, selected.length]);
    return (react_1.default.createElement(table_styles_1.StyledTable, { className: className },
        react_1.default.createElement(material_1.TableContainer, { ref: tableRef },
            react_1.default.createElement(material_1.Table, null,
                react_1.default.createElement(table_header_1.TableHeader, { data: data, columns: columns, isMoreOption: !!onRowClick, isIndeterminate: selected.length > 0, isSelectedAll: isWholePageSelected, isSelectable: isSelectable, tableHasScrollY: tableHasScrollY, selectedCount: selectedCount, bulkActions: bulkActions, onSelectCurrentPage: onWholePageSelect, filter: filter, updateFilter: updateFilter, applyText: t({ key: 'APPLY' }), searchLabel: t({ key: 'SEARCH' }), numberFilterErrorText: "", itemsSelectedText: t({ key: 'ITEMS_SELECTED', values: { count: selectedCount, item: t({ key: 'PRODUCT' }) } }), okText: t({ key: 'OK' }), cancelText: t({ key: 'CANCEL' }), searchPlaceholderText: t({ key: 'SEARCH' }), selectAllText: t({ key: 'SELECT_ALL' }), clearText: t({ key: 'CLEAR' }) }),
                react_1.default.createElement(table_rows_1.TableRows, { isSelectable: isSelectable, data: data, selected: selected, onSelect: onSelect, columns: columns, isLoading: isLoading, error: error, onRowClick: onRowClick, noDataMessage: noDataMessage }),
                children),
            data.length > 0 && (react_1.default.createElement(table_styles_1.StyledTablePaginationContainer, null,
                react_1.default.createElement(pagination_1.TablePagination, { itemsCount: total, pageNumber: pageNumber, rowsPerPage: rowsPerPage, rowsPerPageOptions: client_constants_1.PAGE_SIZES, onPageChange: setPage, onRowsPerPageChange: setPerPage }))))));
};
exports.Table = Table;
