"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CategoriesExpansionItem = exports.default = void 0;
var react_1 = __importStar(require("react"));
var ramda_1 = require("ramda");
var react_redux_1 = require("react-redux");
var expansion_item_1 = require("../expansion-item");
var store_1 = require("../../../store");
var CategoriesExpansionItem = function (_a) {
    var id = _a.id, title = _a.title, items = _a.items, isOpen = _a.isOpen, _b = _a.onHeaderClick, onHeaderClick = _b === void 0 ? function () { } : _b, className = _a.className;
    var dispatch = (0, react_redux_1.useDispatch)();
    var includedCategoriesIds = (0, store_1.useProductsIncludedCategoriesIds)();
    var excludedCategoriesIds = (0, store_1.useProductsExcludedCategoriesIds)();
    var changeCategoriesIds = (0, react_1.useCallback)(function (selectedId, prevCategoriesIds) {
        var selectedCategoriesIds = [selectedId];
        return (0, ramda_1.all)(function (selectedCategoryId) { return prevCategoriesIds.includes(selectedCategoryId); }, selectedCategoriesIds)
            ? (0, ramda_1.without)(selectedCategoriesIds)
            : (0, ramda_1.concat)(selectedCategoriesIds);
    }, []);
    var handleCheckboxClick = (0, react_1.useCallback)(function (selectedId) {
        var changeIncludedCategoriesIds = changeCategoriesIds(selectedId, includedCategoriesIds);
        (0, ramda_1.pipe)(changeIncludedCategoriesIds, ramda_1.uniq, store_1.productsSetIncludedCategoriesIds, dispatch)(includedCategoriesIds);
    }, [dispatch, includedCategoriesIds, changeCategoriesIds]);
    var handleBlockIconClick = (0, react_1.useCallback)(function (selectedId) {
        var changeExcludedCategoriesIds = changeCategoriesIds(selectedId, excludedCategoriesIds);
        (0, ramda_1.pipe)(changeExcludedCategoriesIds, ramda_1.uniq, store_1.productsSetExcludedCategoriesIds, dispatch)(excludedCategoriesIds);
    }, [dispatch, excludedCategoriesIds, changeCategoriesIds]);
    return (react_1.default.createElement(expansion_item_1.ExpansionItem, { id: id, items: items, onHeaderClick: onHeaderClick, title: title, className: className, isOpen: isOpen, excludedSelectedIds: excludedCategoriesIds, includedSelectedIds: includedCategoriesIds, onBlockIconClick: handleBlockIconClick, onCheckboxClick: handleCheckboxClick }));
};
var MemoizedCategoriesExpansionItem = react_1.default.memo(CategoriesExpansionItem);
exports.default = MemoizedCategoriesExpansionItem;
exports.CategoriesExpansionItem = MemoizedCategoriesExpansionItem;
