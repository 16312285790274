"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductDetailsAttributes = void 0;
var react_1 = __importStar(require("react"));
var graphql_1 = require("../../../../__generated__/graphql");
var ramda_1 = require("ramda");
var product_details_attribute_1 = require("./product-details-attribute");
var product_details_attributes_constants_1 = require("./product-details-attributes.constants");
var product_details_attributes_styles_1 = require("./product-details-attributes.styles");
var attributes_1 = require("../../../client/attributes");
var store_1 = require("../../../../store");
var formatValue = function (value, unit) { return "".concat(value).concat(unit && unit !== product_details_attributes_constants_1.UNSPECIFIED_UNIT ? " ".concat(unit) : ''); };
var getProductPresentationLabelValues = function (productPresentationAttribute, value, isValueUnknown) { return (!isValueUnknown ? productPresentationAttribute.validValues.concat(productPresentationAttribute === null || productPresentationAttribute === void 0 ? void 0 : productPresentationAttribute.invalidValues) : [value]); };
var ProductDetailsAttributes = function (_a) {
    var productId = _a.productId, productAttributeValuePairs = _a.productAttributeValuePairs, productPresentationAttributes = _a.productPresentationAttributes;
    var selectedAttributeNode = (0, store_1.useSelectedAttributeNode)();
    var formattedAttributes = (0, react_1.useMemo)(function () {
        var productPresentationAttributesMap = new Map(productPresentationAttributes.map(function (item) { return [item.label, item]; }));
        return productAttributeValuePairs.reduce(function (acc, attribute) {
            var _a, _b;
            var _c, _d, _e;
            var newAcc = __assign({}, acc);
            var label = attribute.label, origin = attribute.origin, unformattedValue = attribute.value, unit = attribute.unit;
            if (!label)
                return newAcc;
            if (attributes_1.DEFAULT_CATEGORY_ENRICHED_FIELDS.includes(label))
                return newAcc;
            var existingAttribute = acc[label];
            var existingValuesMap = (existingAttribute === null || existingAttribute === void 0 ? void 0 : existingAttribute.values) || {};
            var productPresentationAttribute = productPresentationAttributesMap.get(label);
            var value = formatValue(unformattedValue, unit);
            var isUnknown = value === attributes_1.SpecialAttributeValue.Unknown;
            var isOriginalOrAssertedValue = [graphql_1.ProductAttributeOrigin.Original, graphql_1.ProductAttributeOrigin.Assertion].includes(origin);
            if (!productPresentationAttribute && !isOriginalOrAssertedValue) {
                return newAcc;
            }
            // original or manually asserted value Out of Strategy
            if (!productPresentationAttribute && isOriginalOrAssertedValue) {
                newAcc[label] = __assign(__assign({}, existingAttribute), { label: label, disabled: true, values: __assign(__assign({}, existingValuesMap), (_a = {}, _a[value] = {
                        unit: unit,
                        selected: true,
                        value: value,
                        origin: origin,
                        valid: false,
                    }, _a)) });
                return newAcc;
            }
            // attribute is In Strategy but the value is not valid and not Original or manually asserted
            var isInvalidValue = (_c = productPresentationAttribute === null || productPresentationAttribute === void 0 ? void 0 : productPresentationAttribute.invalidValues) === null || _c === void 0 ? void 0 : _c.includes(value);
            if (isInvalidValue && !isOriginalOrAssertedValue) {
                return newAcc;
            }
            // new attribute
            if (!Object.keys(existingValuesMap).length) {
                newAcc[label] = {
                    label: label,
                    // the list of In Strategy values allowed for the selected node
                    values: (_d = productPresentationAttribute === null || productPresentationAttribute === void 0 ? void 0 : productPresentationAttribute.allowedValues.reduce(function (innerAcc, item) {
                        innerAcc[item] = {
                            unit: unit,
                            value: item,
                            valid: true,
                            selected: false,
                        };
                        return innerAcc;
                    }, {})) !== null && _d !== void 0 ? _d : {},
                };
            }
            var productPresentationLabelValues = getProductPresentationLabelValues(productPresentationAttribute, value, isUnknown);
            existingValuesMap = ((_e = newAcc[label]) === null || _e === void 0 ? void 0 : _e.values) || {};
            if (productPresentationLabelValues.includes(value)) {
                newAcc[label] = __assign(__assign({}, existingAttribute), { label: label, disabled: !!unit, values: __assign(__assign({}, existingValuesMap), (_b = {}, _b[value] = {
                        unit: unit,
                        selected: true,
                        value: value,
                        origin: origin,
                        valid: !isInvalidValue && !isUnknown,
                    }, _b)) });
            }
            return newAcc;
        }, {});
    }, [productAttributeValuePairs, productPresentationAttributes]);
    var attributeList = (0, react_1.useMemo)(function () { return (0, ramda_1.sortWith)([
        (0, ramda_1.comparator)(function (_a) {
            var label = _a.label;
            return (selectedAttributeNode && label === selectedAttributeNode.label);
        }),
        (0, ramda_1.comparator)(function (_a) {
            var disabled = _a.disabled;
            return !disabled;
        }),
        function (_a, _b) {
            var a = _a.label;
            var b = _b.label;
            return a.localeCompare(b);
        },
    ])(Object.values(formattedAttributes)).map(function (_a) {
        var label = _a.label;
        return label;
    }); }, [formattedAttributes, selectedAttributeNode]);
    var allValues = (0, react_1.useMemo)(function () { return attributeList.reduce(function (acc, attributeKey) {
        var _a;
        var labelValues = Object.values(((_a = formattedAttributes[attributeKey]) === null || _a === void 0 ? void 0 : _a.values) || {});
        var notApplicableValue = labelValues.find(function (_a) {
            var value = _a.value;
            return value === attributes_1.SpecialAttributeValue.NotApplicable;
        });
        if (!notApplicableValue) {
            labelValues = __spreadArray(__spreadArray([], __read(labelValues), false), [
                {
                    value: attributes_1.SpecialAttributeValue.NotApplicable,
                    selected: false,
                    valid: false,
                },
            ], false);
        }
        acc[attributeKey] = labelValues;
        return acc;
    }, {}); }, [attributeList, formattedAttributes]);
    return (react_1.default.createElement(product_details_attributes_styles_1.StyledWrapper, null, attributeList.map(function (attributeKey) {
        var _a, _b;
        return (react_1.default.createElement(product_details_attribute_1.ProductDetailsAttribute, { key: attributeKey, productId: productId, label: (_a = formattedAttributes[attributeKey]) === null || _a === void 0 ? void 0 : _a.label, disabled: (_b = formattedAttributes[attributeKey]) === null || _b === void 0 ? void 0 : _b.disabled, values: allValues[attributeKey] }));
    })));
};
exports.ProductDetailsAttributes = ProductDetailsAttributes;
