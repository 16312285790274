"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useProductBeingReclassified = exports.productBeingReclassifiedSelector = exports.usePageSize = exports.pageSizeSelector = exports.useIsListViewMode = exports.isListViewModeSelector = exports.useIsAggregatedAttributeViewHidden = exports.isAggregatedAttributeViewHiddenSelector = exports.useProductTreeFilters = exports.productTreeFiltersSelector = exports.useAttributesSort = exports.useAttributesCollapseState = exports.useExpandedAttributes = exports.attributesSortItemSelector = exports.attributesCollapseStateSelector = exports.excludedAttributesSelector = exports.expandedAttributesSelector = exports.useSelectedAttributeNode = exports.selectedAttributeNodeSelector = exports.useExpandedNodes = exports.expandedNodesSelector = exports.useCatalogChanges = exports.catalogChangesSelector = exports.useSelectedTreeNodeId = exports.selectedTreeNodeIdSelector = exports.useProductTreeTagValue = exports.productTreeTagsSelector = exports.useIsProductTreeEditingAllowed = exports.productTreePermissionsSelector = exports.useProductTreeId = exports.productTreeIdSelector = exports.useSelectedTreeNodePath = exports.selectedTreeNodePathSelector = exports.useProductTreeRootNodeId = exports.productTreeRootNodeIdSelector = exports.useSelectedCatalogTags = exports.useSelectedCatalogId = exports.selectedCatalogSelector = exports.useSelectedProjectTags = exports.useSelectedProjectId = exports.selectedProjectSelector = exports.selectedEntitiesSelector = exports.enrichAISelector = void 0;
var graphql_1 = require("../../__generated__/graphql");
var ramda_1 = require("ramda");
var react_redux_1 = require("react-redux");
var reselect_1 = require("reselect");
var main_1 = require("./main");
var const_1 = require("../const");
exports.enrichAISelector = (0, reselect_1.createSelector)(main_1.enrichSelector, function (_a) {
    var enrichAI = _a.enrichAI;
    return enrichAI;
});
exports.selectedEntitiesSelector = (0, reselect_1.createSelector)(main_1.enrichSelector, function (_a) {
    var enrichAI = _a.enrichAI;
    return enrichAI.selectedEntities;
});
exports.selectedProjectSelector = (0, reselect_1.createSelector)(exports.selectedEntitiesSelector, function (_a) {
    var selectedProject = _a.selectedProject;
    return selectedProject;
});
var useSelectedProjectId = function () {
    var selectedProject = (0, react_redux_1.useSelector)(exports.selectedProjectSelector);
    return selectedProject === null || selectedProject === void 0 ? void 0 : selectedProject.id;
};
exports.useSelectedProjectId = useSelectedProjectId;
var useSelectedProjectTags = function () {
    var _a;
    var selectedProject = (0, react_redux_1.useSelector)(exports.selectedProjectSelector);
    return (_a = selectedProject === null || selectedProject === void 0 ? void 0 : selectedProject.tags) !== null && _a !== void 0 ? _a : [];
};
exports.useSelectedProjectTags = useSelectedProjectTags;
exports.selectedCatalogSelector = (0, reselect_1.createSelector)(exports.selectedEntitiesSelector, function (_a) {
    var selectedCatalog = _a.selectedCatalog;
    return selectedCatalog;
});
var useSelectedCatalogId = function () {
    var selectedCatalog = (0, react_redux_1.useSelector)(exports.selectedCatalogSelector);
    return selectedCatalog === null || selectedCatalog === void 0 ? void 0 : selectedCatalog.id;
};
exports.useSelectedCatalogId = useSelectedCatalogId;
var useSelectedCatalogTags = function () {
    var _a, _b;
    var selectedProject = (0, react_redux_1.useSelector)(exports.selectedProjectSelector);
    var selectedCatalog = (0, react_redux_1.useSelector)(exports.selectedCatalogSelector);
    var catalogTags = (_a = selectedCatalog === null || selectedCatalog === void 0 ? void 0 : selectedCatalog.tags) !== null && _a !== void 0 ? _a : [];
    return (0, ramda_1.isEmpty)(catalogTags) ? (_b = selectedProject === null || selectedProject === void 0 ? void 0 : selectedProject.tags) !== null && _b !== void 0 ? _b : [] : catalogTags;
};
exports.useSelectedCatalogTags = useSelectedCatalogTags;
exports.productTreeRootNodeIdSelector = (0, reselect_1.createSelector)(exports.selectedEntitiesSelector, function (_a) {
    var _b;
    var productTree = _a.productTree;
    return (_b = productTree === null || productTree === void 0 ? void 0 : productTree.rootNodeId) !== null && _b !== void 0 ? _b : '';
});
var useProductTreeRootNodeId = function () { return (0, react_redux_1.useSelector)(exports.productTreeRootNodeIdSelector); };
exports.useProductTreeRootNodeId = useProductTreeRootNodeId;
exports.selectedTreeNodePathSelector = (0, reselect_1.createSelector)(exports.selectedEntitiesSelector, function (_a) {
    var selectedTreeNodePath = _a.selectedTreeNodePath, productTree = _a.productTree;
    return selectedTreeNodePath !== null && selectedTreeNodePath !== void 0 ? selectedTreeNodePath : productTree === null || productTree === void 0 ? void 0 : productTree.rootNodeId;
});
var useSelectedTreeNodePath = function () { return (0, react_redux_1.useSelector)(exports.selectedTreeNodePathSelector); };
exports.useSelectedTreeNodePath = useSelectedTreeNodePath;
exports.productTreeIdSelector = (0, reselect_1.createSelector)(exports.selectedEntitiesSelector, function (_a) {
    var productTree = _a.productTree;
    return productTree === null || productTree === void 0 ? void 0 : productTree.id;
});
var useProductTreeId = function () { return (0, react_redux_1.useSelector)(exports.productTreeIdSelector); };
exports.useProductTreeId = useProductTreeId;
exports.productTreePermissionsSelector = (0, reselect_1.createSelector)(exports.selectedEntitiesSelector, function (_a) {
    var productTree = _a.productTree;
    return productTree === null || productTree === void 0 ? void 0 : productTree.permissions;
});
var useIsProductTreeEditingAllowed = function () {
    var permissions = (0, react_redux_1.useSelector)(exports.productTreePermissionsSelector);
    return permissions === null || permissions === void 0 ? void 0 : permissions.some(function (permission) { return [graphql_1.PermissionAction.Grant, graphql_1.PermissionAction.Write].includes(permission); });
};
exports.useIsProductTreeEditingAllowed = useIsProductTreeEditingAllowed;
exports.productTreeTagsSelector = (0, reselect_1.createSelector)(exports.selectedEntitiesSelector, function (_a) {
    var productTree = _a.productTree;
    return productTree === null || productTree === void 0 ? void 0 : productTree.tags;
});
var useProductTreeTagValue = function (key) {
    var _a;
    var tags = (0, react_redux_1.useSelector)(exports.productTreeTagsSelector);
    return (_a = tags === null || tags === void 0 ? void 0 : tags.find(function (tag) { return tag.key === key; })) === null || _a === void 0 ? void 0 : _a.value;
};
exports.useProductTreeTagValue = useProductTreeTagValue;
exports.selectedTreeNodeIdSelector = (0, reselect_1.createSelector)(exports.selectedTreeNodePathSelector, function (selectedTreeNodePath) { return selectedTreeNodePath === null || selectedTreeNodePath === void 0 ? void 0 : selectedTreeNodePath.split(const_1.STRATEGY_NODE_PATH_SEPARATOR).pop(); });
var useSelectedTreeNodeId = function () { return (0, react_redux_1.useSelector)(exports.selectedTreeNodeIdSelector); };
exports.useSelectedTreeNodeId = useSelectedTreeNodeId;
exports.catalogChangesSelector = (0, reselect_1.createSelector)(exports.enrichAISelector, function (_a) {
    var selectedCatalog = _a.selectedEntities.selectedCatalog, changes = _a.changes;
    return changes === null || changes === void 0 ? void 0 : changes[selectedCatalog === null || selectedCatalog === void 0 ? void 0 : selectedCatalog.id];
});
var useCatalogChanges = function () { return (0, react_redux_1.useSelector)(exports.catalogChangesSelector); };
exports.useCatalogChanges = useCatalogChanges;
exports.expandedNodesSelector = (0, reselect_1.createSelector)(exports.selectedEntitiesSelector, function (_a) {
    var expandedNodes = _a.expandedNodes;
    return expandedNodes;
});
var useExpandedNodes = function () { return (0, react_redux_1.useSelector)(exports.expandedNodesSelector); };
exports.useExpandedNodes = useExpandedNodes;
exports.selectedAttributeNodeSelector = (0, reselect_1.createSelector)(exports.selectedEntitiesSelector, function (_a) {
    var selectedAttributeNode = _a.selectedAttributeNode;
    return selectedAttributeNode;
});
var useSelectedAttributeNode = function () { return (0, react_redux_1.useSelector)(exports.selectedAttributeNodeSelector); };
exports.useSelectedAttributeNode = useSelectedAttributeNode;
exports.expandedAttributesSelector = (0, reselect_1.createSelector)(exports.selectedEntitiesSelector, function (_a) {
    var aggregatedAttributes = _a.aggregatedAttributes;
    return aggregatedAttributes;
});
exports.excludedAttributesSelector = (0, reselect_1.createSelector)(exports.expandedAttributesSelector, function (_a) {
    var excludedItems = _a.excludedItems;
    return excludedItems;
});
exports.attributesCollapseStateSelector = (0, reselect_1.createSelector)(exports.expandedAttributesSelector, function (_a) {
    var collapseState = _a.collapseState;
    return collapseState;
});
exports.attributesSortItemSelector = (0, reselect_1.createSelector)(exports.expandedAttributesSelector, function (_a) {
    var sort = _a.sort;
    return sort;
});
var useExpandedAttributes = function () { return (0, react_redux_1.useSelector)(exports.excludedAttributesSelector); };
exports.useExpandedAttributes = useExpandedAttributes;
var useAttributesCollapseState = function () { return (0, react_redux_1.useSelector)(exports.attributesCollapseStateSelector); };
exports.useAttributesCollapseState = useAttributesCollapseState;
var useAttributesSort = function () { return (0, react_redux_1.useSelector)(exports.attributesSortItemSelector); };
exports.useAttributesSort = useAttributesSort;
exports.productTreeFiltersSelector = (0, reselect_1.createSelector)(exports.enrichAISelector, function (_a) {
    var productTreeFilters = _a.productTreeFilters;
    return productTreeFilters;
});
var useProductTreeFilters = function () { return (0, react_redux_1.useSelector)(exports.productTreeFiltersSelector); };
exports.useProductTreeFilters = useProductTreeFilters;
exports.isAggregatedAttributeViewHiddenSelector = (0, reselect_1.createSelector)(exports.enrichAISelector, function (_a) {
    var isAggregatedAttributeViewHidden = _a.isAggregatedAttributeViewHidden;
    return isAggregatedAttributeViewHidden;
});
var useIsAggregatedAttributeViewHidden = function () { return (0, react_redux_1.useSelector)(exports.isAggregatedAttributeViewHiddenSelector); };
exports.useIsAggregatedAttributeViewHidden = useIsAggregatedAttributeViewHidden;
exports.isListViewModeSelector = (0, reselect_1.createSelector)(exports.enrichAISelector, function (_a) {
    var isListViewMode = _a.isListViewMode;
    return isListViewMode;
});
var useIsListViewMode = function () { return (0, react_redux_1.useSelector)(exports.isListViewModeSelector); };
exports.useIsListViewMode = useIsListViewMode;
exports.pageSizeSelector = (0, reselect_1.createSelector)(exports.enrichAISelector, function (_a) {
    var pageSize = _a.pageSize;
    return pageSize;
});
var usePageSize = function () { return (0, react_redux_1.useSelector)(exports.pageSizeSelector); };
exports.usePageSize = usePageSize;
exports.productBeingReclassifiedSelector = (0, reselect_1.createSelector)(exports.enrichAISelector, function (_a) {
    var productBeingReclassified = _a.productBeingReclassified;
    return productBeingReclassified;
});
var useProductBeingReclassified = function () { return (0, react_redux_1.useSelector)(exports.productBeingReclassifiedSelector); };
exports.useProductBeingReclassified = useProductBeingReclassified;
