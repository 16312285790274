"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useMatrixData = void 0;
var use_get_all_attribute_values_hook_1 = require("./use-get-all-attribute-values.hook");
var use_get_product_attribute_values_for_strategy_matrix_hook_1 = require("./use-get-product-attribute-values-for-strategy-matrix.hook");
var use_get_total_class_sku_count_hook_1 = require("./use-get-total-class-sku-count.hook");
var useMatrixData = function (_a) {
    var selectedAttribute = _a.selectedAttribute, selectedStrategyNodeId = _a.selectedStrategyNodeId, selectedCatalogId = _a.selectedCatalogId, selectedProductTreeId = _a.selectedProductTreeId;
    var _b = (0, use_get_product_attribute_values_for_strategy_matrix_hook_1.useGetProductAttributesValuesForStrategyMatrix)({
        ids: [selectedCatalogId],
        attribute: selectedAttribute,
        productTreeId: selectedProductTreeId,
    }), _c = _b.data, terminalNodesDataForCellsAndAttributeValuesTotals = _c === void 0 ? { terminalNodesData: [], totalProductsCount: [] } : _c, productAttributesError = _b.error, productAttributesLoading = _b.isLoading;
    var _d = (0, use_get_all_attribute_values_hook_1.useGetAllAttributeValuesForStrategyMatrix)({
        catalog: selectedCatalogId,
        productTreeNodeId: selectedStrategyNodeId,
        selectedAttribute: selectedAttribute,
    }), _e = _d.data, attributeValuesData = _e === void 0 ? [] : _e, attributeValuesError = _d.error, attributeValuesLoading = _d.isLoading;
    var _f = (0, use_get_total_class_sku_count_hook_1.useGetTotalClassSkuCountForStrategyMatrix)({
        catalog: selectedCatalogId,
        productTreeId: selectedProductTreeId,
        selectedAttribute: selectedAttribute,
    }), totalClassSkuCount = _f.data, totalClassSkuError = _f.error, totalClassSkuLoading = _f.IsLoading;
    var isLoading = productAttributesLoading || attributeValuesLoading || totalClassSkuLoading;
    var error = productAttributesError || attributeValuesError || totalClassSkuError;
    return {
        terminalNodesDataForCellsAndAttributeValuesTotals: terminalNodesDataForCellsAndAttributeValuesTotals,
        attributeValuesData: attributeValuesData,
        totalClassSkuCount: totalClassSkuCount,
        isLoading: isLoading,
        error: error,
    };
};
exports.useMatrixData = useMatrixData;
