"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Categories = exports.default = void 0;
var react_1 = __importStar(require("react"));
var ramda_1 = require("ramda");
var categories_expansion_item_component_1 = require("./categories-expansion-item.component");
var generic_1 = require("../../../generic");
var localization_1 = require("../../../localization");
var components_1 = require("..");
var sidebar_section_1 = require("../side-bar/sidebar-section");
var Categories = function (_a) {
    var expansionCategories = _a.expansionCategories, isCategoriesLoading = _a.isCategoriesLoading, openCategories = _a.openCategories, onHeaderClick = _a.onHeaderClick, onCollapseAll = _a.onCollapseAll, onExpandAll = _a.onExpandAll, className = _a.className;
    var t = (0, localization_1.useLocalization)().formatMessage;
    var isNoData = !expansionCategories.length && !isCategoriesLoading;
    var isOpenCategoriesEmpty = (0, react_1.useMemo)(function () { return (0, ramda_1.all)(function (value) { return !value; }, (0, ramda_1.values)(openCategories)); }, [openCategories]);
    var isCollapsed = isNoData || isOpenCategoriesEmpty;
    return (react_1.default.createElement(components_1.SidebarSection, { className: className, title: react_1.default.createElement(react_1.default.Fragment, null,
            t({ key: 'CATEGORIES' }),
            react_1.default.createElement(sidebar_section_1.StyledCollapseAll, { onClick: isCollapsed ? onExpandAll : onCollapseAll }, t({ key: isCollapsed ? 'EXPAND_ALL' : 'COLLAPSE_ALL' }))) },
        react_1.default.createElement(generic_1.Loading, { iconOrientation: "right", isLoading: isCategoriesLoading, progressSize: 25, showEllipsis: false }),
        !isCategoriesLoading && expansionCategories.map(function (_a) {
            var id = _a.id, subCategories = _a.subCategories, title = _a.title;
            return (react_1.default.createElement(categories_expansion_item_component_1.CategoriesExpansionItem, { key: id, id: id, title: title, items: subCategories, isOpen: openCategories[id], onHeaderClick: onHeaderClick }));
        })));
};
var MemoizedCategories = react_1.default.memo(Categories);
exports.default = MemoizedCategories;
exports.Categories = MemoizedCategories;
