"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AttributeHeaderCell = void 0;
var react_1 = __importStar(require("react"));
var partials_1 = require("./partials");
var generic_1 = require("../../enrich-ai/generic");
var localization_1 = require("../../localization");
var store_1 = require("../../store");
var AttributeHeaderCell = function (_a) {
    var attributeName = _a.attributeName;
    var t = (0, localization_1.useLocalization)().formatMessage;
    var productTreeRootNodeId = (0, store_1.useProductTreeRootNodeId)();
    var _b = __read((0, react_1.useState)(false), 2), isGuidelineDialogOpen = _b[0], setGuidelineDialogOpen = _b[1];
    var handleGuidelineButtonClick = function (e) {
        e.stopPropagation();
        setGuidelineDialogOpen(true);
    };
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(generic_1.GuidelineButton, { color: "secondary", tooltipText: t({ key: 'MANAGE_ATTRIBUTE' }), onClick: handleGuidelineButtonClick }),
        attributeName,
        isGuidelineDialogOpen && (react_1.default.createElement(partials_1.EditGuidelineDialog, { selectedAttribute: attributeName, selectedStrategyNodeId: productTreeRootNodeId, selectedStrategyNodePath: productTreeRootNodeId, onClose: function () { return setGuidelineDialogOpen(false); } }))));
};
exports.AttributeHeaderCell = AttributeHeaderCell;
exports.default = exports.AttributeHeaderCell;
