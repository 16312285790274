"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGetProductAttributesValuesForStrategyMatrix = void 0;
var react_1 = require("react");
var client_1 = require("@apollo/client");
var gql_1 = require("../gql");
var client_constants_1 = require("../../../enrich-ai/client/client.constants");
var client_utils_1 = require("../../../enrich-ai/client/client.utils");
var useGetProductAttributesValuesForStrategyMatrix = function (_a) {
    var ids = _a.ids, attribute = _a.attribute, productTreeId = _a.productTreeId, _b = _a.pageSize, pageSize = _b === void 0 ? client_constants_1.PAGE_SIZE_LARGE : _b;
    var _c = (0, client_1.useQuery)(gql_1.GET_PRODUCT_AGGREGATED_ATTRIBUTE_DATA, {
        variables: {
            ids: ids,
            attribute: attribute,
            productTreeId: productTreeId,
            first: pageSize,
        },
        skip: !ids || !attribute || !productTreeId,
    }), data = _c.data, error = _c.error, networkStatus = _c.networkStatus;
    // Extract attributes data
    var terminalNodesData = (0, react_1.useMemo)(function () {
        var _a;
        if (!((_a = data === null || data === void 0 ? void 0 : data.catalogs) === null || _a === void 0 ? void 0 : _a.edges))
            return [];
        return data.catalogs.edges.flatMap(function (catalog) {
            var _a, _b;
            var statistics = ((_b = (_a = catalog.node.statistics) === null || _a === void 0 ? void 0 : _a.attributes) === null || _b === void 0 ? void 0 : _b.edges) || [];
            return statistics.map(function (stat) {
                var node = stat.node;
                var dimensions = node.dimensions;
                var terminalNode = dimensions.productTreeNode;
                return {
                    terminalNodeId: terminalNode === null || terminalNode === void 0 ? void 0 : terminalNode.id,
                    terminalNodeTitle: terminalNode === null || terminalNode === void 0 ? void 0 : terminalNode.title,
                    attribute: dimensions === null || dimensions === void 0 ? void 0 : dimensions.attribute,
                    value: dimensions === null || dimensions === void 0 ? void 0 : dimensions.value,
                    applied: node.applied,
                };
            });
        });
    }, [data]);
    // Extract total products count
    var totalProductsCountData = (0, react_1.useMemo)(function () {
        var _a;
        if (!((_a = data === null || data === void 0 ? void 0 : data.catalogs) === null || _a === void 0 ? void 0 : _a.edges))
            return [];
        return data.catalogs.edges.flatMap(function (catalog) { var _a; return ((_a = catalog.node.statistics) === null || _a === void 0 ? void 0 : _a.groupedTotals) || []; });
    }, [data]);
    return {
        data: {
            terminalNodesData: terminalNodesData,
            totalProductsCount: totalProductsCountData,
        },
        isLoading: (0, client_utils_1.isQueryLoadingOrRefetching)(networkStatus),
        error: error === null || error === void 0 ? void 0 : error.message,
    };
};
exports.useGetProductAttributesValuesForStrategyMatrix = useGetProductAttributesValuesForStrategyMatrix;
