"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useColumnNumberFilterConfig = void 0;
var react_1 = require("react");
var useColumnNumberFilterConfig = function () {
    var getFilterConfig = (0, react_1.useCallback)(function (_a) {
        var id = _a.id;
        return ({
            key: id,
            setNumber: function (_a) {
                var low = _a.low, high = _a.high;
                return ({ field: id, low: low, high: high });
            },
            getNumber: function (filters) { var _a; return (((_a = filters === null || filters === void 0 ? void 0 : filters.numbers) === null || _a === void 0 ? void 0 : _a[id]) || { field: id }); },
        });
    }, []);
    return getFilterConfig;
};
exports.useColumnNumberFilterConfig = useColumnNumberFilterConfig;
