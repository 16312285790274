"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAttributeExpandManager = void 0;
var react_1 = require("react");
var react_redux_1 = require("react-redux");
var product_table_dynamic_columns_1 = require("../../../../hooks/product-table-dynamic-columns");
var models_1 = require("../../../../models");
var utils_1 = require("../../../../utils");
var store_1 = require("../../../../../store");
var useAttributeExpandManager = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    var excludedItems = (0, store_1.useExpandedAttributes)();
    var collapseState = (0, store_1.useAttributesCollapseState)();
    var _a = (0, product_table_dynamic_columns_1.useReviewAttributeColumnManager)(), onToggleReviewAttributeColumn = _a.onToggleReviewAttributeColumn, onRemoveReviewAttributeColumn = _a.onRemoveReviewAttributeColumn, onResetReviewAttributeColumn = _a.onResetReviewAttributeColumn;
    var _b = __read((0, react_1.useTransition)(), 2), _ = _b[0], startTransition = _b[1];
    var isAtLeastOneExpanded = !(collapseState === models_1.CollapseState.Collapse && !excludedItems.length);
    var toggleExpandAll = (0, react_1.useCallback)(function () {
        startTransition(function () {
            dispatch((0, store_1.enrichAISetAttributeCollapseState)(isAtLeastOneExpanded ? models_1.CollapseState.Collapse : models_1.CollapseState.Expand));
        });
        dispatch((0, store_1.enrichAISetAttributesExcludedItems)([]));
        if (isAtLeastOneExpanded) {
            onResetReviewAttributeColumn();
        }
    }, [dispatch, isAtLeastOneExpanded, onResetReviewAttributeColumn]);
    var toggleExpand = (0, react_1.useCallback)(function (item, expanded) {
        var label = item.label, isMeasure = item.isMeasure, isAIEnriched = item.isAIEnriched;
        var value = (collapseState === models_1.CollapseState.Expand && !expanded) || (collapseState === models_1.CollapseState.Collapse && expanded);
        dispatch((0, store_1.enrichAISetAttributesExcludedItems)((0, utils_1.updateUniqueItemListBasedOnValue)(label, value)(excludedItems)));
        var id = (0, product_table_dynamic_columns_1.createColumnId)(label, { isMeasure: isMeasure, isAIEnriched: isAIEnriched });
        if (value) {
            onToggleReviewAttributeColumn(id);
        }
        else {
            onRemoveReviewAttributeColumn(id);
        }
    }, [collapseState, dispatch, excludedItems, onRemoveReviewAttributeColumn, onToggleReviewAttributeColumn]);
    var checkIsExpanded = (0, react_1.useCallback)(function (item) {
        var isExcluded = excludedItems.includes(item);
        return collapseState === models_1.CollapseState.Collapse ? isExcluded : !isExcluded;
    }, [collapseState, excludedItems]);
    return {
        isAtLeastOneExpanded: isAtLeastOneExpanded,
        toggleExpandAll: toggleExpandAll,
        toggleExpand: toggleExpand,
        checkIsExpanded: checkIsExpanded,
    };
};
exports.useAttributeExpandManager = useAttributeExpandManager;
