"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SideBarContainer = exports.ACTIVE_FILTER_TYPES = void 0;
var react_1 = __importStar(require("react"));
var ramda_1 = require("ramda");
var react_redux_1 = require("react-redux");
var side_bar_component_1 = require("./side-bar.component");
var hooks_1 = require("../../../hooks");
var localization_1 = require("../../../localization");
var hooks_2 = require("../../hooks");
var creators_1 = require("../../../store/actions/creators");
var selectors_1 = require("../../../store/selectors");
var State = __importStar(require("../../../store/state"));
var utils_1 = require("../../../utils");
exports.ACTIVE_FILTER_TYPES = [
    State.ProductFilterActiveTypes.All,
    State.ProductFilterActiveTypes.Active,
    State.ProductFilterActiveTypes.Inactive,
];
var SideBarContainer = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    var token = (0, hooks_1.useToken)();
    var resetAllFilters = (0, hooks_2.useResetAllFilters)().resetAllFilters;
    var _a = (0, hooks_2.useProductCounts)(), classTotals = _a.classTotals, categoryTotals = _a.categoryTotals, attributeTotals = _a.attributeTotals, isProductCountsLoading = _a.isLoading;
    var t = (0, localization_1.useLocalization)().formatMessage;
    var selectedOrganizationsIds = (0, selectors_1.useSelectedOrganizationsIdsSelector)();
    var activeFilter = (0, selectors_1.useProductsFilterActive)();
    var resultsFilter = (0, selectors_1.useProductsFilterOperator)();
    var loadRefinements = (0, hooks_2.useRefinements)();
    var _b = (0, hooks_2.useLoadCategories)(), isCategoriesLoading = _b.isLoading, loadCategories = _b.loadCategories;
    var organizations = (0, selectors_1.useOrganizationsSelector)();
    var availableRefinementsFilter = (0, selectors_1.useRefinementsFilterSelector)();
    var performOrganizationSelectionSideEffects = (0, utils_1.useSelectOrganizationSideEffects)().performOrganizationSelectionSideEffects;
    var handleChangeFilter = (0, react_1.useCallback)(function (newValue) {
        dispatch((0, creators_1.productsSetFilterActive)(newValue));
    }, [dispatch]);
    var handleSetOrganization = (0, react_1.useCallback)(function (name) {
        if (name === t({ key: State.ORGANIZATIONS_DEFAULT_OPTIONS.NONE.name })) {
            dispatch((0, creators_1.organizationsSetIds)([]));
        }
        else {
            dispatch((0, creators_1.organizationsSetIds)([organizations.find(function (item) { return name === item.name; }).id]));
        }
        performOrganizationSelectionSideEffects();
    }, [organizations]);
    var selectedOrganization = (0, react_1.useMemo)(function () {
        if (!selectedOrganizationsIds.length) {
            return [t({ key: State.ORGANIZATIONS_DEFAULT_OPTIONS.NONE.name })];
        }
        if ((selectedOrganizationsIds === null || selectedOrganizationsIds === void 0 ? void 0 : selectedOrganizationsIds.length) && organizations.length) {
            return selectedOrganizationsIds.map(function (id) { return organizations.find(function (organizationData) { return id === organizationData.id; }).name; });
        }
        return [];
    }, [t, organizations, selectedOrganizationsIds]);
    var handleRefinementsFilter = (0, react_1.useCallback)(function (newValue) {
        dispatch((0, creators_1.refinementsSetFilter)(newValue));
    }, [dispatch]);
    var handleChangeFilterOperator = (0, react_1.useCallback)(function (newValue) {
        dispatch((0, creators_1.productsSetFilterOperator)(newValue));
    }, [dispatch]);
    var isOrganizationSelected = !!selectedOrganizationsIds.length;
    (0, react_1.useEffect)(function () {
        if (!(0, ramda_1.isEmpty)(selectedOrganizationsIds) && token) {
            void loadRefinements();
            void loadCategories();
        }
    }, [selectedOrganizationsIds, loadCategories, token]);
    return (react_1.default.createElement(side_bar_component_1.SideBarComponent, { selectedOrganization: selectedOrganization, activeFilter: activeFilter, availableRefinementsFilter: availableRefinementsFilter, resultsFilter: resultsFilter, organizations: organizations, classTotals: classTotals, categoryTotals: categoryTotals, attributeTotals: attributeTotals, isProductCountsLoading: isProductCountsLoading, isOrganizationSelected: isOrganizationSelected, isCategoriesLoading: isCategoriesLoading, onSetOrganization: handleSetOrganization, onChangeFilter: handleChangeFilter, onRefinementsFilter: handleRefinementsFilter, onChangeFilterOperator: handleChangeFilterOperator, onResetAllFilters: resetAllFilters }));
};
exports.SideBarContainer = SideBarContainer;
exports.default = exports.SideBarContainer;
