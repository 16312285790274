"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGetAllL1CategoriesAndTerminalNodesForStrategyMatrix = void 0;
var client_1 = require("@apollo/client");
var constants_1 = require("../../constants");
var gql_1 = require("../gql");
var client_constants_1 = require("../../../enrich-ai/client/client.constants");
var client_utils_1 = require("../../../enrich-ai/client/client.utils");
var useGetAllL1CategoriesAndTerminalNodesForStrategyMatrix = function (_a) {
    var _b, _c;
    var productTreeNodeId = _a.productTreeNodeId;
    var _d = (0, client_1.useQuery)(gql_1.GET_ALL_L1_CATEGORIES_AND_TERMINAL_NODES_FOR_STRATEGY_MATRIX, {
        variables: {
            productTreeNodeId: productTreeNodeId,
            filter: constants_1.DEFAULT_DESCENDANTS_FILTER_STRATEGY_MATRIX,
            first: client_constants_1.REQUEST_LIMIT_MEDIUM, // infinity loading???
        },
        skip: !productTreeNodeId,
    }), data = _d.data, error = _d.error, networkStatus = _d.networkStatus;
    return {
        data: (_c = (_b = data === null || data === void 0 ? void 0 : data.productTreeNode) === null || _b === void 0 ? void 0 : _b.children) !== null && _c !== void 0 ? _c : [],
        isLoading: (0, client_utils_1.isQueryLoadingOrRefetching)(networkStatus),
        error: error,
    };
};
exports.useGetAllL1CategoriesAndTerminalNodesForStrategyMatrix = useGetAllL1CategoriesAndTerminalNodesForStrategyMatrix;
