"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGetStrategyValueOptionList = void 0;
var react_1 = require("react");
var ramda_1 = require("ramda");
var use_get_strategy_values_hook_1 = require("./use-get-strategy-values.hook");
var client_utils_1 = require("../../client.utils");
var useGetStrategyValueOptionList = function (_a) {
    var props = __rest(_a, []);
    var _b = (0, use_get_strategy_values_hook_1.useGetStrategyValues)(__assign({}, props)), data = _b.data, previousData = _b.previousData, isLoading = _b.isLoading, isRefetching = _b.isRefetching, refetch = _b.refetch, restData = __rest(_b, ["data", "previousData", "isLoading", "isRefetching", "refetch"]);
    var _c = __read((0, react_1.useState)(''), 2), searchText = _c[0], setSearchText = _c[1];
    var onRefetch = (0, react_1.useCallback)(function (searchString) {
        setSearchText(searchString);
        return refetch({
            filter: {
                some: {
                    value: {
                        contains: (0, client_utils_1.normalizeSearchString)(searchString),
                    },
                },
            },
        });
    }, [refetch]);
    return __assign(__assign({}, restData), { isLoading: isLoading, searchText: searchText, data: isLoading && (!data || (0, ramda_1.isEmpty)(data)) ? previousData : data, refetch: onRefetch });
};
exports.useGetStrategyValueOptionList = useGetStrategyValueOptionList;
