"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GroupedMenuItem = void 0;
var react_1 = __importStar(require("react"));
var ui_components_1 = require("@groupby/ui-components");
var material_1 = require("@mui/material");
var grouped_menu_content_styles_1 = require("./grouped-menu-content.styles");
var GroupedMenuItem = function (_a) {
    var group = _a.group, parentId = _a.parentId, selectedIds = _a.selectedIds, isDisabled = _a.isDisabled, isExpanded = _a.isExpanded, onSelect = _a.onSelect, toggleExpand = _a.toggleExpand;
    var childIds = group.children.map(function (c) { return c.value; });
    var numSelected = childIds.filter(function (id) { return selectedIds.includes(id); }).length;
    var allSelected = numSelected === childIds.length && childIds.length > 0;
    var someSelected = numSelected > 0 && numSelected < childIds.length;
    var handleParentToggle = (0, react_1.useCallback)(function (_e, checked) {
        childIds.forEach(function (childId) { return onSelect(childId, checked); });
    }, [childIds, onSelect]);
    return (react_1.default.createElement("li", null,
        react_1.default.createElement(grouped_menu_content_styles_1.StyledUl, null,
            react_1.default.createElement(grouped_menu_content_styles_1.StyledListSubheader, { disableSticky: false },
                react_1.default.createElement("div", { className: "subheader-content" },
                    react_1.default.createElement(material_1.IconButton, { size: "small", onClick: function () { return toggleExpand(parentId); }, "aria-label": "toggle expand" },
                        react_1.default.createElement(ui_components_1.Icon, { variant: isExpanded ? 'chevronUp' : 'chevronDown' })),
                    react_1.default.createElement(material_1.FormControlLabel, { label: group.label, control: react_1.default.createElement(material_1.Checkbox, { checked: allSelected, indeterminate: someSelected, disabled: isDisabled, onChange: handleParentToggle }) }))),
            isExpanded
                && group.children.map(function (child, childIndex) {
                    var isChecked = selectedIds.includes(child.value);
                    return (react_1.default.createElement(material_1.ListItem, { key: "child-".concat(childIndex) },
                        react_1.default.createElement(material_1.FormControlLabel, { control: react_1.default.createElement(material_1.Checkbox, { checked: isChecked, disabled: isDisabled, onChange: function (_evt, checked) { return onSelect(child.value, checked); } }), label: react_1.default.createElement(material_1.ListItemText, { primary: child.label }) })));
                }))));
};
exports.GroupedMenuItem = GroupedMenuItem;
