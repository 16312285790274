"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useProductsTableSearch = exports.useProductsTableFilter = exports.useProductsTableDynamicColumns = void 0;
var react_redux_1 = require("react-redux");
var reselect_1 = require("reselect");
var reducers_1 = require("./reducers");
var selectors_1 = require("../selectors");
var selectProductsTableDynamicColumns = (0, reselect_1.createSelector)(selectors_1.selectActiveCatalog, function (activeCatalog) { return (activeCatalog === null || activeCatalog === void 0 ? void 0 : activeCatalog.productsTable.dynamicColumns) || reducers_1.defaultProductsTableState.dynamicColumns; });
var useProductsTableDynamicColumns = function () { return (0, react_redux_1.useSelector)(selectProductsTableDynamicColumns); };
exports.useProductsTableDynamicColumns = useProductsTableDynamicColumns;
var selectProductsTableFilter = (0, reselect_1.createSelector)(selectors_1.selectActiveCatalog, function (activeCatalog) { var _a; return ((_a = activeCatalog === null || activeCatalog === void 0 ? void 0 : activeCatalog.productsTable) === null || _a === void 0 ? void 0 : _a.filter) || reducers_1.defaultProductsTableState.filter; });
var useProductsTableFilter = function () { return (0, react_redux_1.useSelector)(selectProductsTableFilter); };
exports.useProductsTableFilter = useProductsTableFilter;
var selectProductsTableSearch = (0, reselect_1.createSelector)(selectors_1.selectActiveCatalog, function (activeCatalog) { var _a; return ((_a = activeCatalog === null || activeCatalog === void 0 ? void 0 : activeCatalog.productsTable) === null || _a === void 0 ? void 0 : _a.search) || reducers_1.defaultProductsTableState.search; });
var useProductsTableSearch = function () { return (0, react_redux_1.useSelector)(selectProductsTableSearch); };
exports.useProductsTableSearch = useProductsTableSearch;
