"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductAttributeControl = void 0;
var react_1 = __importStar(require("react"));
var attributes_1 = require("../../../../client/attributes");
var components_1 = require("../../../../components");
var localization_1 = require("../../../../../localization");
var store_1 = require("../../../../../store");
var ProductAttributeControl = function (_a) {
    var selectedAttributeId = _a.selectedAttributeId, onChange = _a.onChange;
    var t = (0, localization_1.useLocalization)().formatMessage;
    var productTreeNodeId = (0, store_1.useSelectedTreeNodeId)();
    var _b = (0, attributes_1.useGetStrategyAttributes)({
        productTreeNodeId: productTreeNodeId,
    }), data = _b.data, isLoading = _b.isLoading, _c = _b.pagination, hasNextPage = _c.hasNextPage, isLoadingMore = _c.isLoadingMore, onNextPage = _c.onNextPage, onSearch = _b.onSearch;
    var options = (0, react_1.useMemo)(function () { return data === null || data === void 0 ? void 0 : data.map(function (_a) {
        var label = _a.node.label;
        return ({ label: label, value: label });
    }); }, [data]);
    return (react_1.default.createElement(components_1.AutocompleteSelector, { onChange: onChange, options: options, selected: selectedAttributeId, placeholder: t({ key: 'BULK_EDIT_ATTRIBUTES_DIALOG_PLACEHOLDER' }), hasNextPage: hasNextPage, isLoading: isLoading, moreItemsLoading: isLoadingMore, noOptionsText: t({ key: 'NO_DATA_AVAILABLE' }), onLoadMore: onNextPage, onSearch: onSearch }));
};
exports.ProductAttributeControl = ProductAttributeControl;
exports.default = exports.ProductAttributeControl;
