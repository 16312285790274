"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.setAdvancedViewerFilter = exports.clearSelectedProducts = exports.setSelectedProducts = exports.addSelectedProductIds = exports.addSelectedProductId = exports.setIsExclusionModeOn = exports.productsSetExcludedTaxonomyClassIds = exports.clearNotExistTaxonomyClasses = exports.productsSetIncludedTaxonomyClassIds = exports.productsSetExcludedAttributesIds = exports.productsSetIncludedAttributesIds = exports.productsSetExcludedCategoriesIds = exports.productsSetIncludedCategoriesIds = exports.productsSetFilterOperator = exports.productsSetFilterActive = exports.DEFAULT_STATE = void 0;
var ramda_1 = require("ramda");
var Actions = __importStar(require("../actions/types"));
var State = __importStar(require("../state"));
var DEFAULT_ADVANCED_FILTER = {
    field: null,
    operator: null,
    value: '',
    connector: null,
};
exports.DEFAULT_STATE = {
    productFilterActive: State.ProductFilterActiveTypes.All,
    productFilterResults: State.ProductFilterOperatorType.Or,
    includedCategoriesIds: [],
    excludedCategoriesIds: [],
    includedAttributesIds: [],
    excludedAttributesIds: [],
    includedTaxonomyClassIds: [],
    excludedTaxonomyClassIds: [],
    data: null,
    isExclusionModeOn: false,
    selectedProductIds: [],
    total: 0,
    advancedViewerFilterData: [DEFAULT_ADVANCED_FILTER],
};
var productsSetFilterActive = function (state, _a) {
    var data = _a.payload;
    return (__assign(__assign({}, state), { productFilterActive: data.type }));
};
exports.productsSetFilterActive = productsSetFilterActive;
var productsSetFilterOperator = function (state, _a) {
    var data = _a.payload;
    return (__assign(__assign({}, state), { productFilterResults: data.type }));
};
exports.productsSetFilterOperator = productsSetFilterOperator;
var productsSetIncludedCategoriesIds = function (state, includedCategoriesIds) { return (__assign(__assign({}, state), { includedCategoriesIds: includedCategoriesIds, excludedCategoriesIds: (0, ramda_1.without)(includedCategoriesIds, state.excludedCategoriesIds) })); };
exports.productsSetIncludedCategoriesIds = productsSetIncludedCategoriesIds;
var productsSetExcludedCategoriesIds = function (state, excludedCategoriesIds) { return (__assign(__assign({}, state), { excludedCategoriesIds: excludedCategoriesIds, includedCategoriesIds: (0, ramda_1.without)(excludedCategoriesIds, state.includedCategoriesIds) })); };
exports.productsSetExcludedCategoriesIds = productsSetExcludedCategoriesIds;
var productsSetIncludedAttributesIds = function (state, includedAttributesIds) { return (__assign(__assign({}, state), { includedAttributesIds: (0, ramda_1.uniqWith)(function (a, b) { return a.startsWith(b); }, includedAttributesIds.sort()), excludedAttributesIds: state.excludedAttributesIds.filter(function (excludedId) { return !includedAttributesIds.some(function (includedId) { return excludedId.startsWith(includedId); }); }) })); };
exports.productsSetIncludedAttributesIds = productsSetIncludedAttributesIds;
var productsSetExcludedAttributesIds = function (state, excludedAttributesIds) { return (__assign(__assign({}, state), { excludedAttributesIds: (0, ramda_1.uniqWith)(function (a, b) { return a.startsWith(b); }, excludedAttributesIds.sort()), includedAttributesIds: state.includedAttributesIds.filter(function (includedId) { return !excludedAttributesIds.some(function (excludedId) { return includedId.startsWith(excludedId); }); }) })); };
exports.productsSetExcludedAttributesIds = productsSetExcludedAttributesIds;
var productsSetIncludedTaxonomyClassIds = function (state, _a) {
    var id = _a.payload.id;
    var excludedTaxonomyClassIds = state.excludedTaxonomyClassIds, includedTaxonomyClassIds = state.includedTaxonomyClassIds;
    var includedIds = includedTaxonomyClassIds.includes(id)
        ? (0, ramda_1.without)([id], includedTaxonomyClassIds)
        : __spreadArray(__spreadArray([], __read(includedTaxonomyClassIds), false), [id], false);
    return __assign(__assign({}, state), { includedTaxonomyClassIds: includedIds, excludedTaxonomyClassIds: (0, ramda_1.without)(includedIds, excludedTaxonomyClassIds) });
};
exports.productsSetIncludedTaxonomyClassIds = productsSetIncludedTaxonomyClassIds;
var clearNotExistTaxonomyClasses = function (state, _a) {
    var ids = _a.payload.ids;
    var includedTaxonomyClassIds = state.includedTaxonomyClassIds;
    return (__assign(__assign({}, state), { includedTaxonomyClassIds: includedTaxonomyClassIds.filter(function (elem) { return !ids.includes(elem); }) }));
};
exports.clearNotExistTaxonomyClasses = clearNotExistTaxonomyClasses;
var productsSetExcludedTaxonomyClassIds = function (state, _a) {
    var id = _a.payload.id;
    var excludedTaxonomyClassIds = state.excludedTaxonomyClassIds, includedTaxonomyClassIds = state.includedTaxonomyClassIds;
    var excludedIds = excludedTaxonomyClassIds.includes(id)
        ? (0, ramda_1.without)([id], excludedTaxonomyClassIds)
        : __spreadArray(__spreadArray([], __read(excludedTaxonomyClassIds), false), [id], false);
    return __assign(__assign({}, state), { excludedTaxonomyClassIds: excludedIds, includedTaxonomyClassIds: (0, ramda_1.without)(excludedIds, includedTaxonomyClassIds) });
};
exports.productsSetExcludedTaxonomyClassIds = productsSetExcludedTaxonomyClassIds;
var setIsExclusionModeOn = function (state, _a) {
    var isExclusionModeOn = _a.payload.isExclusionModeOn;
    return (__assign(__assign({}, state), { isExclusionModeOn: isExclusionModeOn }));
};
exports.setIsExclusionModeOn = setIsExclusionModeOn;
var addSelectedProductId = function (state, _a) {
    var id = _a.payload.id;
    var selectedProductIds = state.selectedProductIds;
    var index = selectedProductIds.findIndex(function (item) { return item === id; });
    if (index < 0) {
        return __assign(__assign({}, state), { selectedProductIds: __spreadArray(__spreadArray([], __read(selectedProductIds), false), [id], false) });
    }
    return __assign(__assign({}, state), { selectedProductIds: selectedProductIds.filter(function (item) { return item !== id; }) });
};
exports.addSelectedProductId = addSelectedProductId;
var addSelectedProductIds = function (state, _a) {
    var ids = _a.payload.ids;
    var selectedProductIds = state.selectedProductIds;
    var result = __spreadArray([], __read(selectedProductIds), false);
    ids.forEach(function (item) {
        if (!result.includes(item)) {
            result.push(item);
        }
    });
    return __assign(__assign({}, state), { selectedProductIds: result });
};
exports.addSelectedProductIds = addSelectedProductIds;
var setSelectedProducts = function (state, _a) {
    var ids = _a.payload.ids;
    var selectedProductIds = (0, ramda_1.uniq)(__spreadArray(__spreadArray([], __read(state.selectedProductIds), false), __read(ids), false));
    return __assign(__assign({}, state), { selectedProductIds: selectedProductIds });
};
exports.setSelectedProducts = setSelectedProducts;
var clearSelectedProducts = function (state, _a) {
    var ids = _a.payload.ids;
    return (__assign(__assign({}, state), { selectedProductIds: state.selectedProductIds.filter(function (item) { return !ids.includes(item); }) }));
};
exports.clearSelectedProducts = clearSelectedProducts;
var setAdvancedViewerFilter = function (state, _a) {
    var filter = _a.payload.filter;
    return (__assign(__assign({}, state), { advancedViewerFilterData: filter }));
};
exports.setAdvancedViewerFilter = setAdvancedViewerFilter;
// eslint-disable-next-line complexity
exports.default = (function (state, action) {
    if (state === void 0) { state = exports.DEFAULT_STATE; }
    switch (action.type) {
        case Actions.PRODUCTS_SET_FILTER_ACTIVE:
            return (0, exports.productsSetFilterActive)(state, action);
        case Actions.PRODUCTS_SET_FILTER_OPERATOR:
            return (0, exports.productsSetFilterOperator)(state, action);
        case Actions.PRODUCTS_SET_INCLUDED_CATEGORIES_IDS:
            return (0, exports.productsSetIncludedCategoriesIds)(state, action.payload.includedCategoriesIds);
        case Actions.PRODUCTS_SET_EXCLUDED_CATEGORIES_IDS:
            return (0, exports.productsSetExcludedCategoriesIds)(state, action.payload.excludedCategoriesIds);
        case Actions.PRODUCTS_SET_INCLUDED_ATTRIBUTES_IDS:
            return (0, exports.productsSetIncludedAttributesIds)(state, action.payload.includedAttributesIds);
        case Actions.PRODUCTS_SET_EXCLUDED_ATTRIBUTES_IDS:
            return (0, exports.productsSetExcludedAttributesIds)(state, action.payload.excludedAttributesIds);
        case Actions.PRODUCTS_SET_INCLUDED_TAXONOMY_CLASS_IDS:
            return (0, exports.productsSetIncludedTaxonomyClassIds)(state, action);
        case Actions.CLEAR_NOT_EXIST_TAXONOMY_CLASSES:
            return (0, exports.clearNotExistTaxonomyClasses)(state, action);
        case Actions.PRODUCTS_SET_EXCLUDED_TAXONOMY_CLASS_IDS:
            return (0, exports.productsSetExcludedTaxonomyClassIds)(state, action);
        case Actions.PRODUCTS_CLEAR_EXCLUDED_TAXONOMY_CLASS_IDS:
            return __assign(__assign({}, state), { excludedTaxonomyClassIds: [] });
        case Actions.PRODUCTS_CLEAR_INCLUDED_TAXONOMY_CLASS_IDS:
            return __assign(__assign({}, state), { includedTaxonomyClassIds: [] });
        case Actions.PRODUCTS_SET_IS_EXCLUSION_MODE_ON:
            return (0, exports.setIsExclusionModeOn)(state, action);
        case Actions.PRODUCTS_ADD_SELECTED_ID:
            return (0, exports.addSelectedProductId)(state, action);
        case Actions.PRODUCTS_ADD_SELECTED_IDS:
            return (0, exports.addSelectedProductIds)(state, action);
        case Actions.PRODUCTS_SET_SELECTED:
            return (0, exports.setSelectedProducts)(state, action);
        case Actions.PRODUCTS_CLEAR_SELECTED:
            return (0, exports.clearSelectedProducts)(state, action);
        case Actions.PRODUCTS_CLEAR_ALL_SELECTED:
            return __assign(__assign({}, state), { selectedProductIds: [] });
        case Actions.PRODUCTS_SET_TOTAL:
            return __assign(__assign({}, state), { total: action.payload.total });
        case Actions.PRODUCTS_SET_ADVANCED_VIEWER_FILTER:
            return (0, exports.setAdvancedViewerFilter)(state, action);
        case Actions.RESET:
            return exports.DEFAULT_STATE;
        default:
            return state;
    }
});
