"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EnrichAISetProductBeingReclassified = exports.enrichAISetPageSize = exports.enrichAISetIsListViewMode = exports.enrichAISetIsAggregatedAttributeViewHidden = exports.enrichAISetProductTreeFilters = exports.enrichAISetAttributeCollapseState = exports.enrichAISetAttributesSort = exports.enrichAISetAttributesExcludedItems = exports.enrichAISetSelectedAttributeNode = exports.enrichAISetExpandedNodes = exports.enrichAIRemoveChanges = exports.enrichAIAddChanges = exports.enrichAISetProductTree = exports.enrichAISetSelectedTreeNodePath = exports.enrichAISetProjectTags = exports.enrichAISetSelectedProject = exports.enrichAISetCatalogTags = exports.enrichAISetSelectedCatalog = void 0;
var Actions = __importStar(require("../types/enrich-ai"));
var utils_1 = require("../utils");
var enrichAISetSelectedCatalog = function (selectedCatalog) { return (0, utils_1.createAction)(Actions.ENRICH_AI_SET_SELECTED_CATALOG, { selectedCatalog: selectedCatalog }); };
exports.enrichAISetSelectedCatalog = enrichAISetSelectedCatalog;
var enrichAISetCatalogTags = function (id, tags) { return (0, utils_1.createAction)(Actions.ENRICH_AI_SET_CATALOG_TAGS, { id: id, tags: tags }); };
exports.enrichAISetCatalogTags = enrichAISetCatalogTags;
var enrichAISetSelectedProject = function (selectedProject) { return (0, utils_1.createAction)(Actions.ENRICH_AI_SET_SELECTED_PROJECT, { selectedProject: selectedProject }); };
exports.enrichAISetSelectedProject = enrichAISetSelectedProject;
var enrichAISetProjectTags = function (id, tags) { return (0, utils_1.createAction)(Actions.ENRICH_AI_SET_PROJECT_TAGS, { id: id, tags: tags }); };
exports.enrichAISetProjectTags = enrichAISetProjectTags;
var enrichAISetSelectedTreeNodePath = function (selectedTreeNodePath) { return (0, utils_1.createAction)(Actions.ENRICH_AI_SET_SELECTED_TREE_NODE_PATH, { selectedTreeNodePath: selectedTreeNodePath }); };
exports.enrichAISetSelectedTreeNodePath = enrichAISetSelectedTreeNodePath;
var enrichAISetProductTree = function (productTree) { return (0, utils_1.createAction)(Actions.ENRICH_AI_SET_PRODUCT_TREE, { productTree: productTree }); };
exports.enrichAISetProductTree = enrichAISetProductTree;
var enrichAIAddChanges = function (catalogId, changes) { return (0, utils_1.createAction)(Actions.ENRICH_AI_ADD_CHANGES, { catalogId: catalogId, changes: changes }); };
exports.enrichAIAddChanges = enrichAIAddChanges;
var enrichAIRemoveChanges = function (catalogId, strategyNodePath) { return (0, utils_1.createAction)(Actions.ENRICH_AI_REMOVE_CHANGES, { catalogId: catalogId, strategyNodePath: strategyNodePath }); };
exports.enrichAIRemoveChanges = enrichAIRemoveChanges;
var enrichAISetExpandedNodes = function (expandedNodes) { return (0, utils_1.createAction)(Actions.ENRICH_AI_SET_EXPANDED_NODES, { expandedNodes: expandedNodes }); };
exports.enrichAISetExpandedNodes = enrichAISetExpandedNodes;
var enrichAISetSelectedAttributeNode = function (selectedAttributeNode) { return (0, utils_1.createAction)(Actions.ENRICH_AI_SET_SELECTED_ATTRIBUTE_NODE, { selectedAttributeNode: selectedAttributeNode }); };
exports.enrichAISetSelectedAttributeNode = enrichAISetSelectedAttributeNode;
var enrichAISetAttributesExcludedItems = function (excludedItems) { return (0, utils_1.createAction)(Actions.ENRICH_AI_SET_ATTRIBUTES_EXCLUDED_ITEMS, { excludedItems: excludedItems }); };
exports.enrichAISetAttributesExcludedItems = enrichAISetAttributesExcludedItems;
var enrichAISetAttributesSort = function (sort) { return (0, utils_1.createAction)(Actions.ENRICH_AI_SET_ATTRIBUTES_SORT, { sort: sort }); };
exports.enrichAISetAttributesSort = enrichAISetAttributesSort;
var enrichAISetAttributeCollapseState = function (collapseState) { return (0, utils_1.createAction)(Actions.ENRICH_AI_SET_ATTRIBUTES_COLLAPSE_STATE, { collapseState: collapseState }); };
exports.enrichAISetAttributeCollapseState = enrichAISetAttributeCollapseState;
var enrichAISetProductTreeFilters = function (productTreeFilters) { return (0, utils_1.createAction)(Actions.ENRICH_AI_SET_PRODUCT_TREE_FILTERS, { productTreeFilters: productTreeFilters }); };
exports.enrichAISetProductTreeFilters = enrichAISetProductTreeFilters;
var enrichAISetIsAggregatedAttributeViewHidden = function (isAggregatedAttributeViewHidden) { return (0, utils_1.createAction)(Actions.ENRICH_AI_SET_IS_AGGREGATED_ATTRIBUTE_VIEW_HIDDEN, { isAggregatedAttributeViewHidden: isAggregatedAttributeViewHidden }); };
exports.enrichAISetIsAggregatedAttributeViewHidden = enrichAISetIsAggregatedAttributeViewHidden;
var enrichAISetIsListViewMode = function (isListViewMode) { return (0, utils_1.createAction)(Actions.ENRICH_AI_SET_IS_LIST_VIEW_MODE, { isListViewMode: isListViewMode }); };
exports.enrichAISetIsListViewMode = enrichAISetIsListViewMode;
var enrichAISetPageSize = function (pageSize) { return (0, utils_1.createAction)(Actions.ENRICH_AI_SET_PAGE_SIZE, { pageSize: pageSize }); };
exports.enrichAISetPageSize = enrichAISetPageSize;
var EnrichAISetProductBeingReclassified = function (productBeingReclassified) { return (0, utils_1.createAction)(Actions.ENRICH_AI_SET_PRODUCT_BEING_RECLASSIFIED, { productBeingReclassified: productBeingReclassified }); };
exports.EnrichAISetProductBeingReclassified = EnrichAISetProductBeingReclassified;
