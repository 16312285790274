"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.MatchOptionsToMessageKey = exports.MATCH_OPTIONS = void 0;
var ui_components_1 = require("@groupby/ui-components");
exports.MATCH_OPTIONS = [ui_components_1.MatchOptions.Include, ui_components_1.MatchOptions.Exclude];
exports.MatchOptionsToMessageKey = (_a = {},
    _a[ui_components_1.MatchOptions.Include] = 'MATCH_OPTION_INCLUDE',
    _a[ui_components_1.MatchOptions.Exclude] = 'MATCH_OPTION_EXCLUDE',
    _a);
