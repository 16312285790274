"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductViewer = void 0;
var react_1 = __importStar(require("react"));
var ui_components_1 = require("@groupby/ui-components");
var react_resizable_panels_1 = require("react-resizable-panels");
var product_viewer_styles_1 = require("./product-viewer.styles");
var table_container_1 = require("./table/table.container");
var common_1 = require("../components/common");
var formal_attributes_1 = require("../formal-attributes");
var generic_1 = require("../../generic");
var localization_1 = require("../../localization");
var components_1 = require("../components");
var nodes_tree_context_1 = require("../contexts/nodes-tree.context");
var ProductViewer = function () {
    var _a = __read((0, react_1.useState)(null), 2), lastSelectedRowId = _a[0], setLastSelectedRowId = _a[1];
    var t = (0, localization_1.useLocalization)().formatMessage;
    return (react_1.default.createElement(formal_attributes_1.FormalAttributesProvider, null,
        react_1.default.createElement(generic_1.StyledPageContainer, null,
            react_1.default.createElement(nodes_tree_context_1.NodesTreeProvider, null,
                react_1.default.createElement(react_resizable_panels_1.PanelGroup, { direction: 'horizontal' },
                    react_1.default.createElement(react_resizable_panels_1.Panel, { defaultSize: 30 },
                        react_1.default.createElement(components_1.SideBarContainer, null)),
                    react_1.default.createElement(common_1.StyledResizeHandle, null),
                    react_1.default.createElement(react_resizable_panels_1.Panel, null,
                        react_1.default.createElement(ui_components_1.PageTitle, { title: t({ key: 'PRODUCT_VIEWER' }), description: t({ key: 'PRODUCT_VIEWER_DESCRIPTION' }) }),
                        react_1.default.createElement(product_viewer_styles_1.StyledTableWrapper, null,
                            react_1.default.createElement(table_container_1.ProductViewerTableContainer, { lastSelectedRowId: lastSelectedRowId, setLastSelectedRowId: setLastSelectedRowId }))))))));
};
exports.ProductViewer = ProductViewer;
exports.default = exports.ProductViewer;
