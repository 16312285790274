"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CategoriesContainer = exports.default = void 0;
var react_1 = __importStar(require("react"));
var ramda_1 = require("ramda");
var react_redux_1 = require("react-redux");
var categories_component_1 = require("./categories.component");
// this is temporary while work on EN-3049 is in progress
// when EN-3049 is complete, Categoriesv2 will replace Categories
var localization_1 = require("../../../localization");
var State = __importStar(require("../../../store"));
var store_1 = require("../../../store");
var const_1 = require("../../../store/const");
var CategoriesContainer = function (_a) {
    var openCategories = _a.openCategories, setOpenCategories = _a.setOpenCategories, debouncedSearchTerm = _a.debouncedSearchTerm, isCategoriesLoading = _a.isCategoriesLoading, className = _a.className, categoryTotals = _a.categoryTotals, isProductCountsLoading = _a.isProductCountsLoading;
    var t = (0, localization_1.useLocalization)().formatMessage;
    var topLevelCategories = (0, store_1.useTopLevelCategoriesSelector)();
    var categoriesMap = (0, store_1.useCategoriesMapSelector)();
    var includedCategoriesIds = (0, store_1.useProductsIncludedCategoriesIds)();
    var excludedCategoriesIds = (0, store_1.useProductsExcludedCategoriesIds)();
    var refinementsData = (0, store_1.useRefinementsDataSelector)();
    var refinementsFilter = (0, store_1.useRefinementsFilterSelector)();
    var dispatch = (0, react_redux_1.useDispatch)();
    var isLoading = isCategoriesLoading || isProductCountsLoading;
    var getTitle = (0, react_1.useCallback)(function (_a) {
        var subCategory = _a.subCategory;
        var isCategoryUncategorized = subCategory.id === const_1.UNCATEGORIZED_ID;
        var title = isCategoryUncategorized ? t({ key: 'UNCATEGORIZED' }) : subCategory.title;
        return title;
    }, [t]);
    var getSubCategories = (0, react_1.useCallback)(function (categories) { return (0, ramda_1.flatten)(categories.map(function (category) { return category.subcategoryIds.map(function (subCategoryId) { return categoriesMap[subCategoryId]; }); })); }, [categoriesMap]);
    var expansionCategories = (0, react_1.useMemo)(function () {
        var result = [];
        var subCategories = topLevelCategories;
        while (subCategories.length) {
            var title = t({ key: 'CATEGORY_LEVEL', values: { level: result.length + 1 } });
            result.push({
                id: title,
                title: title,
                subCategories: subCategories,
            });
            subCategories = getSubCategories(subCategories);
        }
        return result;
    }, [topLevelCategories, t, getSubCategories]);
    var filteredExpansionCategories = (0, react_1.useMemo)(function () { return expansionCategories
        .map(function (category) {
        var subCategories = category.subCategories.reduce(function (acc, subCategory) {
            var _a;
            var subCategoryId = subCategory.id;
            var isMatchesFilter = refinementsFilter === State.AvailableRefinementType.Matches;
            var isSearchTermInTitle = subCategory.title.toLowerCase().includes(debouncedSearchTerm.toLowerCase());
            var isInRefinements = (_a = refinementsData === null || refinementsData === void 0 ? void 0 : refinementsData.categoryIds) === null || _a === void 0 ? void 0 : _a.includes(subCategoryId);
            var isInIncludedCategoriesIds = includedCategoriesIds.includes(subCategoryId);
            var isInExcludedCategoriesIds = excludedCategoriesIds.includes(subCategoryId);
            var isMatch = isInRefinements || isInIncludedCategoriesIds || isInExcludedCategoriesIds;
            var isShown = isMatchesFilter ? isMatch : true;
            if (isShown && isSearchTermInTitle) {
                return __spreadArray(__spreadArray([], __read(acc), false), [
                    __assign(__assign({}, subCategory), { title: getTitle({ subCategory: subCategory, isMatch: isMatch }), total: categoryTotals[subCategoryId] }),
                ], false);
            }
            return acc;
        }, []);
        var sortedSubCategories = (0, ramda_1.flatten)((0, ramda_1.partition)((0, ramda_1.propEq)('id', const_1.UNCATEGORIZED_ID), subCategories) // set Uncategorized to the top
        );
        return __assign(__assign({}, category), { subCategories: sortedSubCategories });
    })
        .filter(function (category) { return !(0, ramda_1.isEmpty)(category.subCategories); }); }, [
        debouncedSearchTerm,
        expansionCategories,
        refinementsFilter,
        refinementsData === null || refinementsData === void 0 ? void 0 : refinementsData.categoryIds,
        includedCategoriesIds,
        excludedCategoriesIds,
        getTitle,
        categoryTotals,
    ]);
    var handleHeaderClick = (0, react_1.useCallback)(function (id) { return setOpenCategories((0, ramda_1.assoc)(id, !openCategories[id], openCategories)); }, [openCategories, setOpenCategories]);
    var handleExpandAll = (0, react_1.useCallback)(function () { return setOpenCategories(expansionCategories.reduce(function (acc, category) {
        var _a;
        return (__assign(__assign({}, acc), (_a = {}, _a[category.id] = true, _a)));
    }, {})); }, [expansionCategories, dispatch]);
    var handleCollapseAll = (0, react_1.useCallback)(function () { return setOpenCategories({}); }, [setOpenCategories]);
    (0, react_1.useEffect)(function () {
        if (expansionCategories === null || expansionCategories === void 0 ? void 0 : expansionCategories.length) {
            dispatch((0, store_1.categoriesSetCategoryGroups)(expansionCategories));
        }
    }, [expansionCategories, dispatch]);
    return (react_1.default.createElement(categories_component_1.Categories, { className: className, expansionCategories: filteredExpansionCategories, onCollapseAll: handleCollapseAll, onExpandAll: handleExpandAll, onHeaderClick: handleHeaderClick, openCategories: openCategories, isCategoriesLoading: isLoading }));
};
var MemoizedCategoriesContainer = react_1.default.memo(CategoriesContainer);
exports.default = MemoizedCategoriesContainer;
exports.CategoriesContainer = MemoizedCategoriesContainer;
