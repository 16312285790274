"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.progressStateToMessageKeyMap = void 0;
var catalog_1 = require("../client/catalog");
exports.progressStateToMessageKeyMap = (_a = {},
    _a[catalog_1.CatalogProgressState.Uploading] = 'CATALOG_STATE_UPLOADING',
    _a[catalog_1.CatalogProgressState.Waiting] = 'CATALOG_STATE_GENERATING_PREVIEW',
    _a[catalog_1.CatalogProgressState.Importing] = 'CATALOG_STATE_GENERATING_PREVIEW',
    _a[catalog_1.CatalogProgressState.InitialProductsEnrichment] = 'CATALOG_STATE_INITIAL_ENRICHMENT',
    _a[catalog_1.CatalogProgressState.Exporting] = 'CATALOG_STATE_EXPORTING',
    _a[catalog_1.CatalogProgressState.Error] = 'CATALOG_STATE_ERROR',
    _a);
