"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGetDescendantsForStrategyMatrix = void 0;
var client_1 = require("@apollo/client");
var constants_1 = require("../../constants");
var gql_1 = require("../gql");
var client_utils_1 = require("../../../enrich-ai/client/client.utils");
var useGetDescendantsForStrategyMatrix = function (_a) {
    var productTreeNodeId = _a.productTreeNodeId;
    var _b = (0, client_1.useQuery)(gql_1.GET_ALL_TERMINAL_NODES_FOR_STRATEGY_MATRIX, {
        variables: {
            productTreeNodeId: productTreeNodeId,
            filter: constants_1.DEFAULT_DESCENDANTS_FILTER_STRATEGY_MATRIX,
            first: 2000, // should  i add same solution from use-get-all-attribute-values.hook.ts
        },
        skip: !productTreeNodeId,
    }), data = _b.data, error = _b.error, networkStatus = _b.networkStatus, fetchMore = _b.fetchMore;
    // const { edges, pageInfo } = data?.productTreeNode?.descendants ?? {};
    // const pagination = usePagination({
    //   pageInfo,
    //   networkStatus,
    //   pageSize,
    //   fetchMore,
    //   fetchMoreOptions,
    //   lastVariables: variables,
    // });
    return {
        data: data,
        isLoading: (0, client_utils_1.isQueryLoadingOrRefetching)(networkStatus),
        isRefetching: (0, client_utils_1.isQueryRefetching)(networkStatus),
        error: error,
        // pagination,
        fetchMore: fetchMore,
    };
};
exports.useGetDescendantsForStrategyMatrix = useGetDescendantsForStrategyMatrix;
