"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductTableColumnFilterTypes = void 0;
var ProductTableColumnFilterTypes;
(function (ProductTableColumnFilterTypes) {
    ProductTableColumnFilterTypes["Text"] = "text";
    ProductTableColumnFilterTypes["MultiSelect"] = "multiSelect";
    ProductTableColumnFilterTypes["Date"] = "date";
    ProductTableColumnFilterTypes["Number"] = "number";
})(ProductTableColumnFilterTypes = exports.ProductTableColumnFilterTypes || (exports.ProductTableColumnFilterTypes = {}));
