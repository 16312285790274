"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AttributesExpansionItem = exports.default = void 0;
var react_1 = __importStar(require("react"));
var ramda_1 = require("ramda");
var react_redux_1 = require("react-redux");
var expansion_item_1 = require("../expansion-item");
var store_1 = require("../../../store");
var AttributesExpansionItem = function (_a) {
    var id = _a.id, title = _a.title, items = _a.items, isOpen = _a.isOpen, _b = _a.onHeaderClick, onHeaderClick = _b === void 0 ? function () { } : _b, className = _a.className;
    var dispatch = (0, react_redux_1.useDispatch)();
    var includedAttributesIds = (0, store_1.useProductsIncludedAttributesIds)();
    var excludedAttributesIds = (0, store_1.useProductsExcludedAttributesIds)();
    var changeAttributesIds = (0, react_1.useCallback)(function (selectedId, prevAttributesIds) {
        var selectedAttributesIds = [selectedId];
        return (0, ramda_1.all)(function (selectedAttributeId) { return prevAttributesIds.includes(selectedAttributeId); }, selectedAttributesIds)
            ? (0, ramda_1.without)(selectedAttributesIds)
            : (0, ramda_1.concat)(selectedAttributesIds);
    }, []);
    var handleCheckboxClick = (0, react_1.useCallback)(function (selectedId) {
        var changeIncludedAttributesIds = changeAttributesIds(selectedId, includedAttributesIds);
        (0, ramda_1.pipe)(changeIncludedAttributesIds, ramda_1.uniq, store_1.productsSetIncludedAttributesIds, dispatch)(includedAttributesIds);
    }, [dispatch, includedAttributesIds, changeAttributesIds]);
    var handleBlockIconClick = (0, react_1.useCallback)(function (selectedId) {
        var changeExcludedAttributesIds = changeAttributesIds(selectedId, excludedAttributesIds);
        (0, ramda_1.pipe)(changeExcludedAttributesIds, ramda_1.uniq, store_1.productsSetExcludedAttributesIds, dispatch)(excludedAttributesIds);
    }, [dispatch, excludedAttributesIds, changeAttributesIds]);
    return (react_1.default.createElement(expansion_item_1.ExpansionItem, { id: id, items: items, onHeaderClick: onHeaderClick, title: title, className: className, isOpen: isOpen, isTopLevelFilterable: true, excludedSelectedIds: excludedAttributesIds, includedSelectedIds: includedAttributesIds, onBlockIconClick: handleBlockIconClick, onCheckboxClick: handleCheckboxClick }));
};
var MemoizedAttributesExpansionItem = react_1.default.memo(AttributesExpansionItem);
exports.default = MemoizedAttributesExpansionItem;
exports.AttributesExpansionItem = MemoizedAttributesExpansionItem;
