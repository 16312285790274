"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddValueToOtherNodesDialog = void 0;
var react_1 = __importStar(require("react"));
var actions_with_other_nodes_dialog_1 = require("./partials/actions-with-other-nodes-dialog");
var product_tree_nodes_1 = require("../../../client/product-tree-nodes");
var hooks_1 = require("../../../hooks");
var localization_1 = require("../../../../localization");
var localization_utils_1 = require("../../../../localization/localization.utils");
var AddValueToOtherNodesDialog = function (_a) {
    var onClose = _a.onClose, onSubmit = _a.onSubmit, attributes = _a.attributes, isLoading = _a.isLoading;
    var t = (0, localization_1.useLocalization)().formatMessage;
    var selectedProductTreeNode = (0, hooks_1.useSelectedTreeNode)();
    var handleSubmit = function (selectedNodeIds) { return onSubmit(selectedNodeIds); };
    var productTreeNodeFilter = (0, react_1.useMemo)(function () {
        var attributeValuesMap = attributes.reduce(function (acc, _a) {
            var _b;
            var name = _a.name, values = _a.values;
            return (__assign(__assign({}, acc), (_b = {}, _b[name] = values.map(function (node) { return node.name; }), _b)));
        }, {});
        return (0, product_tree_nodes_1.buildFilterToExcludeValues)(attributeValuesMap);
    }, [attributes]);
    var values = (0, react_1.useMemo)(function () { return Object.values(attributes)[0].values.map(function (_a) {
        var name = _a.name;
        return name;
    }); }, [attributes]);
    return (react_1.default.createElement(actions_with_other_nodes_dialog_1.ActionsWithOtherNodesDialog, { productTreeNodeFilter: productTreeNodeFilter, title: t({ key: 'DIALOG_ADD_TO_OTHER_NODES', values: { entity: String(t({ key: 'VALUE' })).toLowerCase() } }), successSingleActionCompleteTitle: t({
            key: 'ADD_ENTITY_SUCCESS',
            values: {
                count: values.length,
                entity: String(t({ key: 'VALUE' })).toLowerCase(),
                entityName: values.join(', '),
                nodeName: selectedProductTreeNode === null || selectedProductTreeNode === void 0 ? void 0 : selectedProductTreeNode.title,
                b: localization_utils_1.renderBoldText,
            },
        }), onSubmit: handleSubmit, onClose: onClose, isLoading: isLoading }));
};
exports.AddValueToOtherNodesDialog = AddValueToOtherNodesDialog;
exports.default = exports.AddValueToOtherNodesDialog;
