"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GroupedMenuContent = void 0;
var react_1 = __importStar(require("react"));
var material_1 = require("@mui/material");
var grouped_menu_content_styles_1 = require("./grouped-menu-content.styles");
var loading_indicator_1 = require("../../loading-indicator");
var grouped_menu_item_content_component_1 = require("./grouped-menu-item-content.component");
var GroupedMenuContent = function (_a) {
    var options = _a.options, selectedIds = _a.selectedIds, onSelect = _a.onSelect, isEmpty = _a.isEmpty, isDisabled = _a.isDisabled, isLoading = _a.isLoading, isSearching = _a.isSearching, moreItemsLoading = _a.moreItemsLoading, registerExpandMethods = _a.registerExpandMethods, onExpandCountChange = _a.onExpandCountChange;
    var _b = __read((0, react_1.useState)([]), 2), expandedParents = _b[0], setExpandedParents = _b[1];
    var toggleExpand = (0, react_1.useCallback)(function (parentId) {
        setExpandedParents(function (prev) {
            var next = new Set(prev);
            if (next.has(parentId)) {
                next.delete(parentId);
            }
            else {
                next.add(parentId);
            }
            return Array.from(next);
        });
    }, []);
    var expandAll = (0, react_1.useCallback)(function () {
        var allIds = options.map(function (group, idx) { var _a; return (_a = group.value) !== null && _a !== void 0 ? _a : "group-".concat(idx); });
        setExpandedParents(allIds);
    }, [options]);
    var collapseAll = (0, react_1.useCallback)(function () {
        setExpandedParents([]);
    }, []);
    (0, react_1.useEffect)(function () {
        if (registerExpandMethods) {
            registerExpandMethods(expandAll, collapseAll);
        }
    }, [registerExpandMethods, expandAll, collapseAll]);
    (0, react_1.useEffect)(function () {
        if (onExpandCountChange) {
            onExpandCountChange(expandedParents.length);
        }
    }, [expandedParents, onExpandCountChange]);
    if (isLoading && isEmpty) {
        return (react_1.default.createElement(grouped_menu_content_styles_1.StyledMenuContent, null,
            react_1.default.createElement(loading_indicator_1.LoadingIndicator, { className: "loading-indicator" })));
    }
    return (react_1.default.createElement(grouped_menu_content_styles_1.StyledMenuContent, null,
        react_1.default.createElement(material_1.List, { sx: {
                width: '100%',
                maxHeight: 300,
                overflow: 'auto',
                position: 'relative',
            }, subheader: react_1.default.createElement("li", null) }, options.map(function (group, gIndex) {
            var _a;
            var parentId = (_a = group.value) !== null && _a !== void 0 ? _a : "group-".concat(gIndex);
            var isExpanded = expandedParents.includes(parentId);
            return (react_1.default.createElement(grouped_menu_item_content_component_1.GroupedMenuItem, { key: parentId, group: group, parentId: parentId, selectedIds: selectedIds, isDisabled: isDisabled, isExpanded: isExpanded, onSelect: onSelect, toggleExpand: toggleExpand }));
        })),
        isSearching && react_1.default.createElement(loading_indicator_1.LoadingIndicator, { className: "loading-indicator" }),
        moreItemsLoading && react_1.default.createElement(loading_indicator_1.LoadingIndicator, { className: "loading-indicator" })));
};
exports.GroupedMenuContent = GroupedMenuContent;
