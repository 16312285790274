"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_intl_1 = require("react-intl");
exports.default = (0, react_intl_1.defineMessages)({
    DASHBOARDS: {
        id: 'dashboards',
        defaultMessage: 'Dashboards',
        description: '',
    },
    OVERVIEW: {
        id: 'overview',
        defaultMessage: 'Overview',
        description: '',
    },
    JOBS: {
        id: 'jobs',
        defaultMessage: 'Jobs',
        description: '',
    },
    PERFORMANCE: {
        id: 'performance',
        defaultMessage: 'Performance',
        description: '',
    },
    LIBRARIES: {
        id: 'libraries',
        defaultMessage: 'Libraries',
        description: '',
    },
    PRODUCT_EDITOR: {
        id: 'product_editor',
        defaultMessage: 'Product Editor',
        description: '',
    },
    ENRICH_AI: {
        id: 'enrich_ai.route',
        defaultMessage: 'Enrich AI',
        description: '',
    },
    ENRICH_AI_TITLE: {
        id: 'enrich_ai.title',
        defaultMessage: 'Enrich AI',
        description: '',
    },
    PRODUCT_EDITOR_TABLE_BUTTONS_CLEAR_ALL: {
        id: 'product_editor.table.buttons.clear_all',
        defaultMessage: 'Clear All Results',
        description: '',
    },
    PRODUCT_EDITOR_TABLE_BUTTONS_SELCECT_ALL: {
        id: 'product_editor.table.buttons.select_all',
        defaultMessage: 'Select All Results',
        description: '',
    },
    PRODUCT_EDITOR_TABLE_COLUMNS_CREATED_DATE: {
        id: 'product_editor.table.columns.created_date',
        defaultMessage: 'Date Created',
        description: '',
    },
    PRODUCT_EDITOR_TABLE_COLUMNS_DESCRIPTION: {
        id: 'product_editor.table.columns.description',
        defaultMessage: 'Product Description',
        description: '',
    },
    PRODUCT_EDITOR_TABLE_COLUMNS_PRODUCT_ID: {
        id: 'product_editor.table.columns.id',
        defaultMessage: 'Product ID',
        description: '',
    },
    PRODUCT_EDITOR_TABLE_COLUMNS_IMAGE: {
        id: 'product_editor.table.columns.image',
        defaultMessage: 'Image',
        description: '',
    },
    PRODUCT_EDITOR_TABLE_COLUMNS_LAST_MODIFIED: {
        id: 'product_editor.table.columns.last_modified',
        defaultMessage: 'Date Updated',
        description: '',
    },
    PRODUCT_EDITOR_TABLE_COLUMNS_NAME: {
        id: 'product_editor.table.columns.name',
        defaultMessage: 'Product Name',
        description: '',
    },
    PRODUCT_EDITOR_TABLE_COLUMNS_PRODUCT_GROUP: {
        id: 'product_editor.table.columns.product_group',
        defaultMessage: 'Variant Group ID',
        description: '',
    },
    PRODUCT_EDITOR_TABLE_COLUMNS_SKU_ID: {
        id: 'product_editor.table.columns.sku_id',
        defaultMessage: 'SKU ID',
        description: '',
    },
    PRODUCT_EDITOR_TABLE_COLUMNS_TAXONOMY_CLASS: {
        id: 'product_editor.table.columns.taxonomy_class',
        defaultMessage: 'Class ID',
        description: '',
    },
    PRODUCT_EDITOR_TABLE_COLUMNS_PRICE: {
        id: 'product_editor.table.columns.price',
        defaultMessage: 'Price',
        description: '',
    },
    PRODUCT_EDITOR_TABLE_COLUMNS_URL: {
        id: 'product_editor.table.columns.url',
        defaultMessage: 'URL',
        description: '',
    },
    PRODUCT_EDITOR_TABLE_COLUMNS_ACTIVE_STATUS: {
        id: 'product_editor.table.columns.active.status',
        defaultMessage: 'Active Status',
        description: '',
    },
    PRODUCT_EDITOR_TABLE_COLUMNS_COMMENTS: {
        id: 'product_editor.table.columns.comments',
        defaultMessage: 'Comments',
        description: '',
    },
    PRODUCT_EDITOR_TABLE_COLUMNS_CLASSIFICATION_PRODUCT_TYPE: {
        id: 'product_editor.table.columns.classification.product_type',
        defaultMessage: 'Classification Product Type',
        description: '',
    },
    PRODUCT_EDITOR_TABLE_ITEMS_NAME: {
        id: 'product_editor.table.items_name',
        defaultMessage: 'products',
        description: '',
    },
    PRODUCT_EDITOR_TABLE_LOADING_ERROR: {
        id: 'product_editor.table.loading_error',
        defaultMessage: 'Failed to load products',
        description: '',
    },
    PRODUCT_LOADING_ERROR: {
        id: 'product.loading_error',
        defaultMessage: 'Failed to load product',
        description: '',
    },
    PRODUCT_EDITOR_TABLE_TABS: {
        id: 'product_editor.table.tabs',
        defaultMessage: 'Failed to load total products',
        description: '',
    },
    ADVANCED_FILTER_VLAUE_LOADING_ERROR: {
        id: 'advanced_filter.value.loading_error',
        defaultMessage: 'Failed to load filter values',
        description: '',
    },
    PRODUCT_EDITOR_TABLE_TABS_ALL: {
        id: 'product_editor.table.tabs.all',
        defaultMessage: 'All',
        description: '',
    },
    PRODUCT_EDITOR_TABLE_TABS_CLASSIFIED: {
        id: 'product_editor.table.tabs.classified',
        defaultMessage: 'Classified',
        description: '',
    },
    PRODUCT_EDITOR_TABLE_TABS_UNCLASSIFIED: {
        id: 'product_editor.table.tabs.unclassified',
        defaultMessage: 'Unclassified',
        description: '',
    },
    PRODUCT_EDITOR_TABLE_TIMEZONE_LABEL: {
        id: 'product_editor.table.timezone_label',
        defaultMessage: 'UTC',
        description: '',
    },
    PRODUCT_VIEWER_NO_COMMENTS_HINT: {
        id: 'product_viewer.no-comments.hint',
        defaultMessage: 'There are no comments at the moment.\nLeave a comment below.',
        description: '',
    },
    PRODUCT_VIEWER_ADD_COMMENT_HINT: {
        id: 'product_viewer.add-comment.hint',
        defaultMessage: 'Add comment',
        description: '',
    },
    COMMENT_CREATE_ERROR: {
        id: 'comment_create.error',
        defaultMessage: 'There is a network failure. Please refresh the page.',
        description: '',
    },
    COMMENT_LOADING_ERROR: {
        id: 'comment_loading.error',
        defaultMessage: 'Failed to load comments',
        description: '',
    },
    COMMENT_CREATE_SUCCESS: {
        id: 'comment_create.success',
        defaultMessage: 'Comment added successfully!',
        description: '',
    },
    SORT_ORDER_OLDEST_FIRST: {
        id: 'sort_order_oldest_first',
        defaultMessage: 'Oldest first',
        description: '',
    },
    SORT_ORDER_NEWEST_FIRST: {
        id: 'sort_order_newest_first',
        defaultMessage: 'Newest first',
        description: '',
    },
    COMMENTS: {
        id: 'comments',
        defaultMessage: "{count, plural,\n      one {# comment}\n      other {# comments}\n    }",
        description: '',
    },
    COMMENT: {
        id: 'comment',
        defaultMessage: 'Comment',
        description: '',
    },
    SENDING: {
        id: 'sending',
        defaultMessage: 'Sending...',
        description: '',
    },
    BULK_COMMENTING_DIALOG_TITLE: {
        id: 'bulk_commenting.dialog.title',
        defaultMessage: 'Comments',
        description: '',
    },
    BULK_COMMENTING_SUCCESS_MESSAGE: {
        id: 'bulk_commenting.success.message',
        defaultMessage: 'Comment added successfully to {count} products!',
        description: '',
    },
    SINGLE_COMMENTING_SUCCESS_MESSAGE: {
        id: 'single_commenting.success.message',
        defaultMessage: 'Comment added successfully!',
        description: '',
    },
    VALIDATION_NOT_EXCEED_LIMIT_CHARACTERS: {
        id: 'validation.must_not_exceed',
        description: '',
        defaultMessage: 'Must not exceed {limit} characters',
    },
    PRODUCT_EDITOR_TABLE_COLUMNS_CURATED_ATTRIBUTES: {
        id: 'product_editor.table.columns.curated_attributes',
        defaultMessage: 'Curated Attributes',
        description: '',
    },
    PRODUCT_EDITOR_TABLE_COLUMNS_STANDARD_FIELDS: {
        id: 'product_editor.table.columns.standard_fields',
        defaultMessage: 'Standard Fields',
        description: '',
    },
    PRODUCT_EDITOR_TABLE_COLUMNS_CUSTOM_FIELDS: {
        id: 'product_editor.table.columns.custom_fields',
        defaultMessage: 'Custom Fields',
        description: '',
    },
    CLASSIFICATION: {
        id: 'classification',
        defaultMessage: 'Classification',
        description: '',
    },
    CURATION: {
        id: 'curation',
        defaultMessage: 'Curation',
        description: '',
    },
    VIEWER: {
        id: 'viewer',
        defaultMessage: 'Viewer',
        description: '',
    },
    STRATEGY_MATRIX: {
        id: 'strategy_matrix',
        defaultMessage: 'Strategy Matrix',
        description: '',
    },
    STRATEGY: {
        id: 'strategy',
        defaultMessage: 'Strategy',
        description: '',
    },
    STRATEGY_PREVIEW: {
        id: 'strategy_preview',
        defaultMessage: 'Preview',
        description: '',
    },
    CLASS_VIEW: {
        id: 'class_view',
        defaultMessage: 'Class View',
        description: '',
    },
    GUIDELINE_VIEW: {
        id: 'guideline_view',
        defaultMessage: 'Guideline View',
        description: '',
    },
    CURATION_RULES: {
        id: 'curation_rules',
        defaultMessage: 'Rules',
        description: '',
    },
    CURATION_TASKS: {
        id: 'curation_tasks',
        defaultMessage: 'Tasks',
        description: '',
    },
    MY_WORK: {
        id: 'my_work',
        defaultMessage: 'My Work',
        description: '',
    },
    NOTIFICATIONS: {
        id: 'notifications',
        defaultMessage: 'Notifications',
        description: '',
    },
    FAQ: {
        id: 'faq',
        description: '',
        defaultMessage: 'FAQ',
    },
    PAGINATION_PER_PAGE_LABEL: {
        id: 'pagination.per_page_label',
        description: '',
        defaultMessage: 'ROWS PER PAGE',
    },
    PAGINATION_COUNT_LABEL: {
        id: 'pagination.count_label',
        description: '',
        defaultMessage: 'of {numPages} pages',
    },
    PAGINATION_CURRENT_RANGE_LABEL: {
        id: 'pagination.current_range_label',
        description: '',
        defaultMessage: '{startRange} - {endRange} of {total} {type}',
    },
    CANCEL: {
        id: 'cancel',
        description: '',
        defaultMessage: 'Cancel',
    },
    SEARCH_INPUT_RESULTS_LABEL: {
        id: 'search_input.results_label',
        description: '',
        defaultMessage: '{numberOfResults} {modelType}',
    },
    SEARCH: {
        id: 'search',
        description: '',
        defaultMessage: 'Search',
    },
    ORGANIZATIONS_FAIL_LOAD: {
        id: 'organization.load.fail',
        description: '',
        defaultMessage: 'Failed to load organizations',
    },
    TAXONOMY_CLASSES: {
        id: 'taxonomy_classes',
        description: '',
        defaultMessage: 'Taxonomy Classes',
    },
    TAXONOMY_CLASSES_LOADING_ERROR: {
        id: 'taxonomy_classes.loading_error',
        defaultMessage: 'Failed to load taxonomy classes',
        description: '',
    },
    PRODUCT_REFINEMENTS_LOADING_ERROR: {
        id: 'product_refinements.loading_error',
        defaultMessage: 'Failed to load refinements',
        description: '',
    },
    CATEGORIES_FAIL_LOAD: {
        id: 'categories.load.fail',
        description: '',
        defaultMessage: 'Failed to load categories',
    },
    CATEGORY_LEVEL: {
        id: 'category_level',
        description: '',
        defaultMessage: 'Category L{level}',
    },
    CATEGORIES: {
        id: 'categories',
        description: '',
        defaultMessage: 'Categories',
    },
    ATTRIBUTES: {
        id: 'attributes',
        description: '',
        defaultMessage: 'Attributes',
    },
    DISPLAY_TABLE: {
        id: 'display_table',
        description: '',
        defaultMessage: 'Display Table',
    },
    L1_CATEGORY: {
        id: 'l1_category',
        description: '',
        defaultMessage: 'L1 Category',
    },
    HIDE_EMPTY_COLUMNS: {
        id: 'hide_empty_columns',
        description: '',
        defaultMessage: 'Hide Empty Columns',
    },
    HIDE_EMPTY_ROWS: {
        id: 'hide_empty_rows',
        description: '',
        defaultMessage: 'Hide Empty Rows',
    },
    COLLAPSE_ALL: {
        id: 'collapse_all',
        description: '',
        defaultMessage: 'Collapse All',
    },
    REFINE: {
        id: 'refine',
        defaultMessage: 'Refine',
        description: '',
    },
    FIELDS: {
        id: 'fields',
        defaultMessage: 'Fields',
        description: '',
    },
    ACTIVE: {
        id: 'active',
        defaultMessage: 'Active',
        description: '',
    },
    NOT_ACTIVE: {
        id: 'not_active',
        defaultMessage: 'not active',
        description: '',
    },
    DEFAULT: {
        id: 'default',
        defaultMessage: 'default',
        description: '',
    },
    ALL: {
        id: 'all',
        description: '',
        defaultMessage: 'All',
    },
    CLEAR: {
        id: 'clear',
        description: '',
        defaultMessage: 'Clear',
    },
    RESET: {
        id: 'reset',
        description: '',
        defaultMessage: 'Reset',
    },
    EXPAND_ALL: {
        id: 'expand_all',
        description: '',
        defaultMessage: 'Expand All',
    },
    BULK_CLASSIFY: {
        id: 'bulk_classify',
        description: '',
        defaultMessage: 'Bulk Classify',
    },
    CLASSIFY: {
        id: 'classify',
        description: '',
        defaultMessage: 'Classify',
    },
    CLASSIFY_MODAL_PRODUCTS: {
        id: 'classify_modal_products',
        description: '',
        defaultMessage: '{productsCount} items selected',
    },
    CLASSIFY_MODAL_SELECTED_CLASS: {
        id: 'classify_modal_selected_class',
        description: '',
        defaultMessage: 'Selected class',
    },
    CLASSIFY_MODAL_TITLE_NOT_SELECTED: {
        id: 'classify_modal_title_not_selected',
        description: '',
        defaultMessage: 'Not selected',
    },
    CLASSIFY_MODAL_SUBTITLE: {
        id: 'classify_modal_subtitle',
        description: '',
        defaultMessage: '{count} items selected for bulk classification.{br}Select a class to continue',
    },
    CLASSIFY_MODAL_TITLE: {
        id: 'classify_modal_title',
        description: '',
        defaultMessage: 'Bulk Classification',
    },
    CLASSIFY_PRODUCTS_ERROR: {
        id: 'classify_products_error',
        description: '',
        defaultMessage: 'Failed to classify products',
    },
    CLASSIFY_PRODUCTS_SUCCESS: {
        id: 'classify_products_success',
        description: '',
        defaultMessage: '{count} Items classified successfully',
    },
    INACTIVE: {
        id: 'inactive',
        description: '',
        defaultMessage: 'Inactive',
    },
    MATCHES: {
        id: 'matches',
        description: '',
        defaultMessage: 'Matches',
    },
    DONE: {
        id: 'done',
        description: '',
        defaultMessage: 'Done',
    },
    NO_DATA_AVAILABLE: {
        id: 'no_data_available',
        description: '',
        defaultMessage: 'No data available',
    },
    SELECT_ATTRIBUTE_USER: {
        id: 'please_select_attribute',
        description: '',
        defaultMessage: 'Please select attribute',
    },
    PRODUCTS: {
        id: 'products',
        defaultMessage: 'Products',
        description: '',
    },
    VIEW: {
        id: 'view',
        defaultMessage: 'View',
        description: '',
    },
    RESULTS: {
        id: 'results',
        description: '',
        defaultMessage: 'Results',
    },
    AND: {
        id: 'and',
        description: '',
        defaultMessage: 'And',
    },
    OR: {
        id: 'or',
        description: '',
        defaultMessage: 'Or',
    },
    UNCATEGORIZED: {
        id: 'uncategorized',
        defaultMessage: 'Uncategorized',
        description: '',
    },
    PRODUCT_VIEWER: {
        id: 'product_viewer',
        defaultMessage: 'Product Viewer',
        description: '',
    },
    PRODUCT_VIEWER_DESCRIPTION: {
        id: 'product_viewer_description',
        defaultMessage: 'Use Product Viewer to collaborate, explore the quality and relevance of newly enriched product details and submit feedback.',
        description: '',
    },
    META_PRODUCTS_LOADING_ERROR: {
        id: 'meta_products_loading_error',
        defaultMessage: 'Failed to load product counts',
        description: '',
    },
    ORGANIZATIONS: {
        id: 'organizations',
        defaultMessage: 'Organizations',
        description: '',
    },
    IS: {
        id: 'filter.options.is',
        defaultMessage: 'is',
        description: '',
    },
    IS_NOT: {
        id: 'filter.options.is_not',
        defaultMessage: 'is not',
        description: '',
    },
    CONTAINS: {
        id: 'filter.options.contains',
        defaultMessage: 'contains',
        description: '',
    },
    NOT_CONTAIN: {
        id: 'filter.options.not_contain',
        defaultMessage: 'does not contain',
        description: '',
    },
    STARTS_WITH: {
        id: 'filter.options.starts_with',
        defaultMessage: 'starts with',
        description: '',
    },
    ENDS_WITH: {
        id: 'filter.options.ends_with',
        defaultMessage: 'ends with',
        description: '',
    },
    ATTRIBUTE: {
        id: 'filter.properties.attribute',
        defaultMessage: 'Attribute',
        description: '',
    },
    VALUE: {
        id: 'filter.properties.value',
        defaultMessage: 'Value',
        description: '',
    },
    TAXONOMY_CLASS: {
        id: 'filter.properties.taxonomy_class',
        defaultMessage: 'Taxonomy Class',
        description: '',
    },
    CATEGORY: {
        id: 'filter.properties.category',
        defaultMessage: 'Category',
        description: '',
    },
    FIELD: {
        id: 'filter.properties.field',
        defaultMessage: 'Field',
        description: '',
    },
    DATE: {
        id: 'filter.properties.date',
        defaultMessage: 'Date',
        description: '',
    },
    DATE_CREATED: {
        id: 'filter.properties.date_created',
        defaultMessage: 'Date Created',
        description: '',
    },
    DATE_UPDATED: {
        id: 'filter.properties.date_updated',
        defaultMessage: 'Date Updated',
        description: '',
    },
    DATE_COMMENT: {
        id: 'filter.properties.date_comment',
        defaultMessage: 'Last Comment Date',
        description: '',
    },
    VIEW_OPTIONS: {
        id: 'filter.view_options',
        defaultMessage: 'View Options',
        description: '',
    },
    FILTERS: {
        id: 'filters',
        defaultMessage: 'Filters',
        description: '',
    },
    FILTER: {
        id: 'filter',
        defaultMessage: 'Filter',
        description: '',
    },
    OPERATOR: {
        id: 'filter.operator',
        defaultMessage: 'Operator',
        description: '',
    },
    BEFORE: {
        id: 'filter.before',
        defaultMessage: 'before',
        description: '',
    },
    AFTER: {
        id: 'filter.after',
        defaultMessage: 'after',
        description: '',
    },
    BETWEEN: {
        id: 'filter.between',
        defaultMessage: 'between',
        description: '',
    },
    FIELD_NAME: {
        id: 'filter.field_name',
        defaultMessage: 'Field Name',
        description: '',
    },
    START_DATE: {
        id: 'filter.start_date',
        defaultMessage: 'Start Date',
        description: '',
    },
    END_DATE: {
        id: 'filter.end_date',
        defaultMessage: 'End Date',
        description: '',
    },
    ADD_FILTER: {
        id: 'filter.add',
        defaultMessage: 'Add Filter',
        description: '',
    },
    NUMBER_SELECTED: {
        id: 'number_selected',
        description: '',
        defaultMessage: '{count} selected (of {total} items)',
    },
    BULK_ACTIONS: {
        id: 'bulk_actions',
        description: '',
        defaultMessage: 'Bulk Actions',
    },
    SELECT_ALL: {
        id: 'select_all',
        description: '',
        defaultMessage: 'Select All',
    },
    SELECT_ALL_N_ITEMS: {
        id: 'select_all_n_items',
        description: '',
        defaultMessage: 'Select All {count} items',
    },
    SELECT_ALL_ON_PAGE: {
        id: 'select_all_on_page',
        description: '',
        defaultMessage: 'Select All on Page',
    },
    DESELECT_ALL: {
        id: 'deselect_all',
        description: '',
        defaultMessage: 'Deselect All',
    },
    CLEAR_ALL: {
        id: 'clear_all',
        description: '',
        defaultMessage: 'Clear All',
    },
    SUBCATEGORY: {
        id: 'subcategory',
        description: '',
        defaultMessage: 'Subcategory',
    },
    NOT_APPLICABLE: {
        id: 'not_appplicable',
        description: '',
        defaultMessage: 'Not Applicable',
    },
    BOOLEAN_VALUE: {
        id: 'boolean_value',
        description: '',
        defaultMessage: 'Boolean Value',
    },
    TRUE: {
        id: 'true',
        description: '',
        defaultMessage: 'True',
    },
    FALSE: {
        id: 'false',
        description: '',
        defaultMessage: 'False',
    },
    CLEAR_ALL_FILTERS: {
        id: 'filters.clear_all',
        description: '',
        defaultMessage: 'Clear All Filters',
    },
    APPLY: {
        id: 'apply',
        description: '',
        defaultMessage: 'Apply',
    },
    NOT_STRATEGY: {
        id: 'not_strategy',
        description: '',
        defaultMessage: 'Not in Strategy',
    },
    PENDING_CURATION: {
        id: 'value.pending_curation',
        description: '',
        defaultMessage: 'Pending Curation',
    },
    NOT_MAPPED: {
        id: 'not_mapped',
        description: '',
        defaultMessage: 'Not Mapped',
    },
    TEXT: {
        id: 'text',
        description: '',
        defaultMessage: 'Text',
    },
    LEGEND: {
        id: 'legend',
        description: '',
        defaultMessage: 'Legend',
    },
    IDENTITY_FAIL_LOAD: {
        id: 'user.identity',
        description: '',
        defaultMessage: 'Failed to load user identity',
    },
    VALUE_NUMBER: {
        id: 'value_number',
        description: '',
        defaultMessage: '{number} Values',
    },
    SETTINGS: {
        id: 'settings',
        description: '',
        defaultMessage: 'Settings',
    },
    DRAG_TO_RESIZE: {
        id: 'drag.resize',
        description: '',
        defaultMessage: 'Drag to resize',
    },
    UPLOAD_FILE_BUTTON_TITLE: {
        id: 'upload_file.button.title',
        description: '',
        defaultMessage: 'Upload File',
    },
    UPLOAD: {
        id: 'upload',
        description: '',
        defaultMessage: 'UPLOAD',
    },
    PREVIEW_PRODUCTS_NO_DATA_TITLE: {
        id: 'preview_products.no-data.title',
        description: '',
        defaultMessage: 'Upload a file to see curated product\'s attributes and values.',
    },
    PREVIEW_PRODUCTS_CATEGORY: {
        id: 'preview_products.category',
        description: '',
        defaultMessage: 'For selected category:',
    },
    PREVIEW_PRODUCTS_PRODUCTS_TAB: {
        id: 'preview_products.products_tab',
        description: '',
        defaultMessage: 'Products',
    },
    PREVIEW_PRODUCTS_ATTRIBUTION_TAB: {
        id: 'preview_products.attribution_tab',
        description: '',
        defaultMessage: 'Attribution',
    },
    CATALOG_STATE_UPLOADING: {
        id: 'catalog_state.uploading',
        description: '',
        defaultMessage: 'Uploading your product catalog... Please do not leave this page',
    },
    DOWNLOAD_CATALOG_BUTTON: {
        id: 'catalog.download_button',
        description: '',
        defaultMessage: 'Download Catalog',
    },
    CATALOG_STATE_GENERATING_PREVIEW: {
        id: 'catalog_state.generating_preview',
        description: '',
        defaultMessage: 'Excellent, upload complete! Now generating a strategy preview based on your catalog. This will take a few minutes.',
    },
    CATALOG_STATE_INITIAL_ENRICHMENT: {
        id: 'catalog_state.initial_enrichment',
        description: '',
        defaultMessage: 'We are enriching your product data to make it shine! This could take a while, feel free to grab a coffee',
    },
    DOWNLOAD_BUTTON_TITLE: {
        id: 'download_button.title',
        description: '',
        defaultMessage: 'Download Data',
    },
    CATALOG_STATE_EXPORTING: {
        id: 'catalog_state.exporting',
        description: '',
        defaultMessage: 'Exporting your product catalog to a ZIP archive...',
    },
    STRATEGY_PREVIEW_TITLE: {
        id: 'strategy_preview.title',
        description: '',
        defaultMessage: 'Product Taxonomy',
    },
    PRODUCT_TILE_NO_IMAGE_AVAILABLE: {
        id: 'product_tile.no_image_available',
        description: '',
        defaultMessage: 'No Image Available',
    },
    PRODUCT_TILE_PRODUCT_PAGE_URL: {
        id: 'product_tile.product_page_url',
        description: '',
        defaultMessage: 'Product Page',
    },
    REF_APP_PAGINATION_PER_PAGE: {
        id: 'reference_application.per_page',
        description: '',
        defaultMessage: 'Rows Per Page',
    },
    CATALOG_STATE_ERROR: {
        id: 'catalog_state.error',
        description: '',
        defaultMessage: 'Something went wrong...',
    },
    TOTAL_RESULTS: {
        id: 'total_results',
        description: '',
        defaultMessage: 'Showing {totalCount} results',
    },
    PRODUCT_DETAILS_DRAWER_DATE_CREATED: {
        id: 'product_details_drawer.date_created',
        description: '',
        defaultMessage: 'Date Created',
    },
    PRODUCT_DETAILS_DRAWER_DATE_UPDATED: {
        id: 'product_details_drawer.date_updated',
        description: '',
        defaultMessage: 'Date Updated',
    },
    PRODUCT_DETAILS_DRAWER_CLASS_ID: {
        id: 'product_details_drawer.class_id',
        description: '',
        defaultMessage: 'Class ID',
    },
    COLLAPSE_ALL_ATTRIBUTES: {
        id: 'collapse_all_attributes',
        description: '',
        defaultMessage: 'Collapse all attributes',
    },
    EXPAND_ALL_ATTRIBUTES: {
        id: 'expand_all_attributes',
        description: '',
        defaultMessage: 'Expand all attributes',
    },
    NO_SELECTED_STRATEGY_PLACEHOLDER: {
        id: 'no_selected_strategy_placeholder',
        description: '',
        defaultMessage: 'Strategy',
    },
    SORT_BY: {
        id: 'sort_by',
        description: '',
        defaultMessage: 'Sort By',
    },
    SORT_BY_TEXT_ASC: {
        id: 'sort_by_text_asc',
        description: '',
        defaultMessage: 'A - Z',
    },
    SORT_BY_TEXT_DESC: {
        id: 'sort_by_text_desc',
        description: '',
        defaultMessage: 'Z - A',
    },
    SORT_BY_PRODUCT_COUNTS_ASC: {
        id: 'sort_by_product_counts_asc',
        description: '',
        defaultMessage: 'Product Counts (Low to High)',
    },
    SORT_BY_PRODUCT_COUNTS_DESC: {
        id: 'sort_by_product_counts_desc',
        description: '',
        defaultMessage: 'Product Counts (High to Low)',
    },
    NO_PRODUCTS: {
        id: 'no_products',
        description: '',
        defaultMessage: 'No Products',
    },
    PRODUCT_DETAILS_DRAWER_CURATED_ATTRIBUTES: {
        id: 'product_details_drawer.curated_attributes',
        description: '',
        defaultMessage: 'Curated Attributes',
    },
    DEFAULT_PROJECT: {
        id: 'default_project',
        description: '',
        defaultMessage: 'Default Project',
    },
    DEFAULT_CATALOG: {
        id: 'default_catalog',
        description: '',
        defaultMessage: 'Default Catalog',
    },
    UPLOADED_CATALOGS: {
        id: 'uploaded_catalogs',
        description: '',
        defaultMessage: 'Uploaded Catalogs',
    },
    UPLOADED_PROJECTS: {
        id: 'uploaded_projects',
        description: '',
        defaultMessage: 'Uploaded Projects',
    },
    NO_CATALOGS: {
        id: 'no_catalogs',
        description: '',
        defaultMessage: 'No Catalogs',
    },
    NO_PROJECTS: {
        id: 'no_projects',
        description: '',
        defaultMessage: 'No Projects',
    },
    PROJECT: {
        id: 'project',
        description: '',
        defaultMessage: 'Project',
    },
    CATALOG: {
        id: 'catalog',
        description: '',
        defaultMessage: 'Catalog',
    },
    SELECT_PROJECT_PLACEHOLDER: {
        id: 'select_project_placeholder',
        description: '',
        defaultMessage: 'Select Project',
    },
    SELECT_CATALOG_PLACEHOLDER: {
        id: 'select_catalog_placeholder',
        description: '',
        defaultMessage: 'Select Catalog',
    },
    ITEMS_SELECTED: {
        id: 'items',
        defaultMessage: "{count, plural,\n      one {{count} {item} selected}\n      other {{count} {item}s selected}\n    }",
        description: '',
    },
    CLEAR_SELECTION: {
        id: 'clear_selection',
        description: '',
        defaultMessage: 'Clear Selection',
    },
    CONFIRMATION_DELETE_TITLE: {
        id: 'confirmation_delete.title',
        description: '',
        defaultMessage: "{count, plural,\n      one {Are you sure you want to delete this {entity} from this node?}\n      other {Are you sure you want to delete {entity}s from this node?}\n    }",
    },
    DELETE_DIALOG_WARNING_MESSAGE: {
        id: 'delete_dialog.warning_message',
        description: '',
        defaultMessage: 'Note: Removing will update strategy and curation.',
    },
    DIALOG_DELETE_FROM_OTHER_NODES_TITLE: {
        id: 'dialog.delete_from_other_nodes.title',
        description: '',
        defaultMessage: "{count, plural,\n      one {Do you want to delete this {entity} from other nodes?}\n      other {Do you want to delete this {entity}s from other nodes?}\n    }",
    },
    DELETE_ENTITY_SUCCESS: {
        id: 'delete_entity.success',
        description: '',
        defaultMessage: "{count, plural,\n      one {{entity} successful deleted from node.}\n      other {{entity}s successful deleted from node.}\n    }",
    },
    DELETE_ENTITY_ERROR: {
        id: 'delete_entity.error',
        description: '',
        defaultMessage: "{count, plural,\n      one {Can not delete {entity} from node.}\n      other {Can not delete {entity}s from node.}\n    }",
    },
    SKIP: {
        id: 'skip',
        description: '',
        defaultMessage: 'Skip',
    },
    SELECTED_NODES_COUNT: {
        id: 'selected.nodes',
        description: '',
        defaultMessage: '{count} of {total} nodes selected',
    },
    ADD_NEW: {
        id: 'add.new',
        description: '',
        defaultMessage: 'Add new',
    },
    DIALOG_ADD_TO_OTHER_NODES: {
        id: 'dialog.add_entity_to_other_nodes',
        description: '',
        defaultMessage: 'Do you want to add this {entity} to other nodes?',
    },
    ADD_ENTITY_SUCCESS: {
        id: 'add_entity.success',
        description: '',
        defaultMessage: "{count, plural,\n      one {Added {entity} <b>{entityName}</b> to node <b>{nodeName}</b>.}\n      other {Added {entity}s <b>{entityName}</b> to node <b>{nodeName}</b>.}\n    }",
    },
    ADD_ENTITY_TO_OTHER_NODES_SUCCESS: {
        id: 'add_entity_to_other_nodes.success',
        description: '',
        defaultMessage: "{count, plural,\n      one {Added {entity} {entityName}.}\n      other {Added {entity}s {entityName}.}\n    }",
    },
    ADD_ENTITY_ERROR: {
        id: 'add_entity.error',
        description: '',
        defaultMessage: "{count, plural,\n      one {Can not add {entity} to selected node.}\n      other {Can not add {entity}s.}\n    }",
    },
    EDIT_GUIDELINES_SUCCESS: {
        id: 'add_guidelines.success',
        description: '',
        defaultMessage: 'Attribute and values guidelines for {entityName} has been updated.',
    },
    ADD_ENTITY_TO_NODE: {
        id: 'add.entity_to_node',
        description: '',
        defaultMessage: 'Add {entity}s to node',
    },
    ADD_ENTITY: {
        id: 'add.entity',
        description: '',
        defaultMessage: 'Add {entity}',
    },
    ENTITY_GUIDELINES: {
        id: 'entity.guidelines',
        description: '',
        defaultMessage: '{entity} guidelines',
    },
    VALIDATION_REQUIRED: {
        id: 'validation.required',
        description: '',
        defaultMessage: 'This is a required field',
    },
    ENRICH_DATA: {
        id: 'enrich_data',
        description: '',
        defaultMessage: 'Enrich data',
    },
    VALIDATION_AT_LEAST_ONE_ITEM: {
        id: 'validation.at_least_one_item',
        description: '',
        defaultMessage: 'Must have at least one value',
    },
    VALUES_FORM_SECTION_TITLE: {
        id: 'values_form_section.title',
        description: '',
        defaultMessage: 'Values in attribute',
    },
    ADD_MULTIPLE_VALUE: {
        id: 'add.multiple_value',
        description: '',
        defaultMessage: 'Add Multiple Values',
    },
    CREATE_NEW: {
        id: 'create_new',
        description: '',
        defaultMessage: 'Create new',
    },
    SEARCH_OR_CREATE: {
        id: 'search_or_create',
        description: '',
        defaultMessage: 'Search or add new value',
    },
    VALIDATION_MUST_BE_UNIQUE: {
        id: 'validation.must_be_unique',
        description: '',
        defaultMessage: '{name} must be unique',
    },
    NOT_APPLICABLE_SHORT: {
        id: 'not_applicable.short',
        description: '',
        defaultMessage: 'N/A',
    },
    SET_TO_NOT_APPLICABLE_LABEL: {
        id: 'set_to_not_applicable_label',
        description: '',
        defaultMessage: 'Set to N/A',
    },
    RE_ENRICH_CATALOG_NOTIFICATION_MESSAGE: {
        id: 're_enrich_catalog.notification_message',
        description: '',
        defaultMessage: '<b>{count} changes</b> in the entire catalog to enrich.',
    },
    RE_ENRICH_CATEGORY_NOTIFICATION_MESSAGE: {
        id: 're_enrich_category.notification_message',
        description: '',
        defaultMessage: '<b>{count} changes</b> in the category to enrich.',
    },
    RE_ENRICH_CATALOG_ACTION_TITLE: {
        id: 're_enrich_catalog.action_title',
        description: '',
        defaultMessage: 'Enrich Catalog',
    },
    RE_ENRICH_CATEGORY_ACTION_TITLE: {
        id: 're_enrich_category.action_title',
        description: '',
        defaultMessage: 'Enrich',
    },
    CHANGES_ONLY: {
        id: 'changes_only',
        description: '',
        defaultMessage: 'Changes Only',
    },
    PAGINATION_CURRENT_OF_LABEL: {
        id: 'pagination.current_of_label',
        description: '',
        defaultMessage: 'of {total}',
    },
    PAGINATION_PAGE_LABEL: {
        id: 'pagination.page_label',
        description: '',
        defaultMessage: 'Page',
    },
    BULK_EDIT: {
        id: 'actions.bulk_edit',
        description: '',
        defaultMessage: 'Bulk Edit',
    },
    PRODUCT: {
        id: 'product',
        description: '',
        defaultMessage: 'Product',
    },
    VIEW_OPTION: {
        id: 'view_option',
        description: '',
        defaultMessage: 'View Option',
    },
    CONFIGURE_FIELDS: {
        id: 'configure_fields',
        description: '',
        defaultMessage: 'Configure Fields',
    },
    ENRICHED: {
        id: 'enriched',
        description: '',
        defaultMessage: 'Enriched',
    },
    ENRICHED_DATA: {
        id: 'enriched_data',
        description: '',
        defaultMessage: 'Enriched Data',
    },
    UNCLASSIFIED: {
        id: 'unclassified',
        description: '',
        defaultMessage: 'Unclassified',
    },
    THIRD_CATEGORY: {
        id: 'third_category',
        description: '',
        defaultMessage: 'Third Category',
    },
    BASE_DATA: {
        id: 'base_data',
        description: '',
        defaultMessage: 'Base Data',
    },
    MEASURES: {
        id: 'measures',
        description: '',
        defaultMessage: 'Measures',
    },
    PRODUCT_RECLASSIFY: {
        id: 'product_reclassify',
        description: '',
        defaultMessage: 'Reclassify',
    },
    PRODUCT_RECLASSIFY_TOTAL: {
        id: 'product_reclassify.total',
        description: '',
        defaultMessage: 'Currently selected <highlight>{total}</highlight> products',
    },
    PRODUCT_RECLASSIFY_TITLE: {
        id: 'product_reclassify.title',
        description: '',
        defaultMessage: 'Classify Selected Products',
    },
    PRODUCT_RECLASSIFY_UNCATEGORIZED_LABEL: {
        id: 'product_reclassify.uncategorized_label',
        description: '',
        defaultMessage: 'Set to Unclassified',
    },
    PRODUCT_RECLASSIFY_SEARCH_TERMINAL_NODE_PLACEHOLDER: {
        id: 'product_reclassify.search_terminal_node_placeholder',
        description: '',
        defaultMessage: 'Search Terminal Node',
    },
    PRODUCT_RECLASSIFY_SUCCESS_MESSAGE: {
        id: 'product_reclassify.success_message',
        description: '',
        defaultMessage: '{totalCount} products have been classified to {title}',
    },
    EDIT_PRODUCT_DETAILS: {
        id: 'edit_product_details',
        description: '',
        defaultMessage: 'Edit Product Details',
    },
    BULK_EDIT_EDIT_OPTIONS: {
        id: 'actions.bulk_edit.edit_option',
        description: '',
        defaultMessage: 'Edit Options',
    },
    BULK_EDIT_CHOOSE_VALUES: {
        id: 'actions.bulk_edit.choose_values',
        description: '',
        defaultMessage: 'Choose Values',
    },
    BULK_EDIT_ATTRIBUTES_DIALOG_TITLE: {
        id: 'bulk_edit_attributes.dialog.title',
        description: '',
        defaultMessage: 'Edit Attributes',
    },
    BULK_EDIT_ATTRIBUTES_DIALOG_PLACEHOLDER: {
        id: 'bulk_edit_attributes.dialog.placeholder',
        description: '',
        defaultMessage: 'Select an attribute',
    },
    BULK_EDIT_VALUES_DIALOG_PLACEHOLDER: {
        id: 'bulk_edit_values.dialog.placeholder',
        description: '',
        defaultMessage: 'Select a value',
    },
    BULK_EDIT_ATTRIBUTES_DIALOG_OPERATION_REPLACE: {
        id: 'bulk_edit_attributes.dialog.operation.replace',
        description: '',
        defaultMessage: 'Replace Values',
    },
    BULK_EDIT_ATTRIBUTES_DIALOG_OPERATION_MERGE: {
        id: 'bulk_edit_attributes.dialog.operation.merge',
        description: '',
        defaultMessage: 'Merge Values',
    },
    BULK_EDIT_ATTRIBUTES_DIALOG_OPERATION_DELETE: {
        id: 'bulk_edit_attributes.dialog.operation.delete',
        description: '',
        defaultMessage: 'Delete Values',
    },
    UPDATED: {
        id: 'updated',
        description: '',
        defaultMessage: 'Updated',
    },
    NEXT: {
        id: 'next',
        description: '',
        defaultMessage: 'Next',
    },
    PREVIOUS: {
        id: 'previous',
        description: '',
        defaultMessage: 'Previous',
    },
    PRODUCT_DETAILS_CLASSIFICATION_PRODUCT_TYPE: {
        id: 'product_details.classification_product_type',
        description: '',
        defaultMessage: 'Classification Product Type',
    },
    PRODUCT_DETAILS_ID: {
        id: 'product_details.id',
        description: '',
        defaultMessage: 'Product ID',
    },
    PRODUCT_DETAILS_URL: {
        id: 'product_details.url',
        description: '',
        defaultMessage: 'Product URL',
    },
    PRODUCT_DETAILS_NAME: {
        id: 'product_details.name',
        description: '',
        defaultMessage: 'Product Name',
    },
    PRODUCT_DETAILS_BRAND: {
        id: 'product_details.brand',
        description: '',
        defaultMessage: 'Brand',
    },
    PRODUCT_DETAILS_DESCRIPTION: {
        id: 'product_details.description',
        description: '',
        defaultMessage: 'Description',
    },
    PRODUCT_DETAILS_CREATED: {
        id: 'product_details.created',
        description: '',
        defaultMessage: 'Created At',
    },
    PRODUCT_DETAILS_CATEGORIES: {
        id: 'product_details.categories',
        description: '',
        defaultMessage: 'Categories',
    },
    BULK_EDIT_SUCCESS_MESSAGE: {
        id: 'bulk_edit.success.message',
        description: '',
        defaultMessage: 'Bulk edit completed successfully!',
    },
    UPDATE_CATALOG_TITLE: {
        id: 'update_catalog.title',
        description: '',
        defaultMessage: 'Update File',
    },
    UPDATE_CATALOG_OPTION_CATALOG: {
        id: 'update_catalog.option.catalog',
        description: '',
        defaultMessage: 'Catalog data',
    },
    UPDATE_CATALOG_OPTION_CATALOG_HINT: {
        id: 'update_catalog.option.catalog.hint',
        description: '',
        defaultMessage: 'Update existing product data, add or remove products from catalog.',
    },
    UPDATE_CATALOG_OPTION_ENRICHED_DATA: {
        id: 'update_catalog.option.enriched_data',
        description: '',
        defaultMessage: 'Enriched data',
    },
    UPDATE_CATALOG_OPTION_ENRICHED_DATA_HINT: {
        id: 'update_catalog.option.enriched_data.hint',
        description: '',
        defaultMessage: 'Update to merge or replace existing enriched data.',
    },
    REPLACE: {
        id: 'Replace',
        description: '',
        defaultMessage: 'Replace',
    },
    MERGE: {
        id: 'merge',
        description: '',
        defaultMessage: 'Merge',
    },
    CHOOSE_FILE: {
        id: 'choose_file',
        description: '',
        defaultMessage: 'Choose file',
    },
    REPLACE_FILE: {
        id: 'replace_file',
        description: '',
        defaultMessage: 'Replace file',
    },
    UPDATE_CATALOG_OPTION_PLACEHOLDER: {
        id: 'update_catalog.option.placeholder',
        description: '',
        defaultMessage: 'Choose an option',
    },
    CSV_PARSE_ERROR_DELIMITER: {
        id: 'csv_parse_error.delimiter',
        description: '',
        defaultMessage: 'Delimiter error',
    },
    CSV_PARSE_ERROR_QUOTES: {
        id: 'csv_parse_error.quotes',
        description: '',
        defaultMessage: 'Quotes error',
    },
    CSV_PARSE_ERROR_FIELD_MISMATCH: {
        id: 'csv_parse_error.field_mismatch',
        description: '',
        defaultMessage: 'Field mismatch error',
    },
    CSV_PARSE_ERROR_DUPLICATED_KEY: {
        id: 'csv_parse_error.duplicated_key',
        description: '',
        defaultMessage: 'Duplicated key field error',
    },
    CSV_PARSE_ERROR_EMPTY_KEY: {
        id: 'csv_parse_error.empty_key',
        description: '',
        defaultMessage: 'Empty key field error',
    },
    CSV_PARSE_ERROR_EMPTY: {
        id: 'csv_parse_error.empty',
        description: '',
        defaultMessage: 'Empty field error',
    },
    CSV_PARSE_ERROR_PARSE_ARRAY: {
        id: 'csv_parse_error.parse_array',
        description: '',
        defaultMessage: 'Parse array error',
    },
    CSV_PARSE_ERROR_PARSE: {
        id: 'csv_parse_error.parse',
        description: '',
        defaultMessage: 'File is invalid',
    },
    CSV_PARSE_ERROR_FORBIDDEN_FIELDS: {
        id: 'csv_parse_error.forbidden_fields',
        description: '',
        defaultMessage: 'Some fields are forbidden: {forbiddenFields}',
    },
    CSV_PARSE_ERROR_MESSAGE: {
        id: 'csv_parse_error.message',
        description: '',
        defaultMessage: '{errorCount} out of {productCount} products have import errors. Only products without errors will update successfully.',
    },
    CSV_PARSE_REQUIRED_COLUMNS_ERROR: {
        id: 'csv_parse_required_columns_error',
        description: '',
        defaultMessage: 'Should contain an id column and at least one attribute',
    },
    CSV_PARSE_SUCCESS_MESSAGE: {
        id: 'csv_parse_error.success_message',
        description: '',
        defaultMessage: 'You are about to update {productCount} products in the catalog to {uploadMode} with the contents of file.',
    },
    GENERAL_ERROR_MESSAGE: {
        id: 'general_error_message',
        description: '',
        defaultMessage: 'Something went wrong',
    },
    SHOW_EMPTY_CATEGORIES: {
        id: 'show_empty_categories',
        description: '',
        defaultMessage: 'Show empty categories',
    },
    MANAGE_ATTRIBUTE: {
        id: 'manage_attribute',
        description: '',
        defaultMessage: 'Manage Attribute',
    },
    EDIT_PRODUCT_TREE_CONFIRMATION_DIALOG_TITLE: {
        id: 'edit_product_tree_confirmation-dialog.title',
        description: '',
        defaultMessage: 'You are about to customize your Strategy',
    },
    EDIT_PRODUCT_TREE_CONFIRMATION_DIALOG_MESSAGE: {
        id: 'edit_product_tree_confirmation-dialog.message',
        description: '',
        defaultMessage: 'By adding attributes or values, you will create a custom version of your Strategy, replacing the default Global Strategy automatically applied to products. This action cannot be undone. Cancel to return to the default Global Strategy.',
    },
    ADD_SUGGESTED_VALUE: {
        id: 'add_suggested_value',
        description: '',
        defaultMessage: 'Add suggested value',
    },
    VALUE_STRATEGIZED: {
        id: 'value_strategized',
        description: '',
        defaultMessage: '{value} for {attribute} is now strategized for {category}',
    },
    EDIT: {
        id: 'edit',
        description: '',
        defaultMessage: 'Edit',
    },
    SAVE: {
        id: 'save',
        description: '',
        defaultMessage: 'Save',
    },
    SAVING: {
        id: 'saving',
        description: '',
        defaultMessage: 'Saving',
    },
    LOADING: {
        id: 'loading',
        description: '',
        defaultMessage: 'Loading',
    },
    STRATEGY_GUIDELINE_TOOLTIP: {
        id: 'strategy_guideline.tooltip',
        description: '',
        defaultMessage: 'Manage Guideline',
    },
    STRATEGY_GUIDELINE_TOOLTIP_PLACEHOLDER: {
        id: 'strategy_guideline.tooltip.placeholder',
        description: '',
        defaultMessage: 'No guideline available',
    },
    STRATEGY_GUIDELINE_DIALOG_TITLE: {
        id: 'strategy_guideline.dialog.title',
        description: '',
        defaultMessage: 'Guideline for {nodeName}',
    },
    STRATEGY_GUIDELINE_DIALOG_GUIDELINE_PLACEHOLDER: {
        id: 'strategy_guideline.dialog.placeholder',
        description: '',
        defaultMessage: 'Guidelines',
    },
    ACTION_SAVE: {
        id: 'action.save',
        description: '',
        defaultMessage: 'Save',
    },
    EDIT_GUIDELINE_SUCCESS: {
        id: 'edit_guideline.success',
        description: '',
        defaultMessage: 'Successfully updated guideline.',
    },
    SEARCH_FOR_NODES_COUNT: {
        id: 'search_for_nodes.count',
        description: '',
        defaultMessage: '{count} of {total}',
    },
    UN_CATEGORIZED_NODE_TITLE: {
        id: 'un_categorized_node.title',
        description: '',
        defaultMessage: 'Products Unclassified',
    },
    ENRICH_OVERVIEW: {
        id: 'enrich_overview',
        description: '',
        defaultMessage: 'Enrich overview',
    },
    GO_TO_WORKSPACE: {
        id: 'go_to_workspace',
        description: '',
        defaultMessage: 'Go to workspace',
    },
    GO_TO_OVERVIEW: {
        id: 'go_to_overview',
        description: '',
        defaultMessage: 'Go to overview',
    },
    PRODUCT_CATALOG: {
        id: 'product_catalog',
        description: '',
        defaultMessage: 'Product Catalog',
    },
    PRODUCT_STATUS: {
        id: 'product_status',
        description: '',
        defaultMessage: 'Product Status',
    },
    ATTRIBUTE_BREAKDOWN: {
        id: 'attribute_breakdown',
        description: '',
        defaultMessage: 'Attribute Breakdown',
    },
    ATTRIBUTION_BREAKDOWN: {
        id: 'attribution_breakdown',
        description: '',
        defaultMessage: 'Attribution breakdown',
    },
    NORMALIZED: {
        id: 'normalized',
        description: '',
        defaultMessage: 'Normalized',
    },
    AUGMENTED: {
        id: 'augmented',
        description: '',
        defaultMessage: 'Augmented',
    },
    ATTRIBUTE_DISTRIBUTION: {
        id: 'attribute_distribution',
        description: '',
        defaultMessage: 'Attribute Distribution',
    },
    ATTRIBUTIONS: {
        id: 'attributions',
        description: '',
        defaultMessage: 'Attributions',
    },
    UNIQUE_VALUES: {
        id: 'unique_values',
        description: '',
        defaultMessage: 'Unique Values',
    },
    LAST_UPDATED: {
        id: 'last_updated',
        description: '',
        defaultMessage: 'Last Updated',
    },
    TOTAL: {
        id: 'total',
        description: '',
        defaultMessage: 'Total',
    },
    CREATED_NEW: {
        id: 'created_new',
        description: '',
        defaultMessage: 'Created New',
    },
    UNKNOWNS: {
        id: 'unknows',
        description: '',
        defaultMessage: 'Unknowns',
    },
    CATEGORY_SELECT: {
        id: 'category_select',
        description: '',
        defaultMessage: 'Category Template',
    },
    NO_CATEGORIES_FOUND: {
        id: 'no_categories_found',
        description: '',
        defaultMessage: 'No categories found',
    },
    CATALOG_NOT_FOUND: {
        id: 'catalog.not_found',
        defaultMessage: 'Catalog is not found',
        description: '',
    },
    CATALOG_DOWNLOAD_URL_FAIL: {
        id: 'catalog.download_url_fail',
        defaultMessage: 'Failed to fetch the catalog download URL:',
        description: '',
    },
    DOWNLOAD_URL_NOT_FOUND: {
        id: 'download_url_not_found',
        defaultMessage: 'Download URL could not be retrieved.',
        description: '',
    },
    APPLIED: {
        id: 'applied',
        description: '',
        defaultMessage: 'Applied',
    },
    UNKNOWN: {
        id: 'unknown',
        description: '',
        defaultMessage: 'Unknown',
    },
    GUIDELINE_DIALOG_TITLE: {
        id: 'guideline_dialog.title',
        description: '',
        defaultMessage: 'View & Edit Guidelines',
    },
    OK: {
        id: 'ok',
        description: '',
        defaultMessage: 'Ok',
    },
    MATCH_OPTION_INCLUDE: {
        id: 'match_option.include',
        description: '',
        defaultMessage: 'Contains',
    },
    MATCH_OPTION_EXCLUDE: {
        id: 'match_option.exclude',
        description: '',
        defaultMessage: 'Does not contain',
    },
});
