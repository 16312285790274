"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AttributeFormListContainer = void 0;
var react_1 = __importStar(require("react"));
var ui_components_1 = require("@groupby/ui-components");
var create_attribute_form_constants_1 = require("./create-attribute-form.constants");
var create_attribute_form_styles_1 = require("./create-attribute-form.styles");
var partials_1 = require("./partials");
var attribute_form_list_item_component_1 = require("./partials/attribute-form-list-item.component");
var value_form_list_container_1 = require("./value-form-list.container");
var utils_1 = require("../../../utils");
var localization_1 = require("../../../../localization");
var AttributeFormListContainer = function (_a) {
    var name = _a.name, values = _a.values, _b = _a.attributesOptionList, attributesOptionList = _b === void 0 ? [] : _b, isLoading = _a.isLoading, hasNextPage = _a.hasNextPage, error = _a.error, hideDescription = _a.hideDescription, valueOptionListStrategyNodeId = _a.valueOptionListStrategyNodeId, isRemoveDisabled = _a.isRemoveDisabled, moreItemsLoading = _a.moreItemsLoading, allowCreation = _a.allowCreation, onRemove = _a.onRemove, onAdd = _a.onAdd, getFieldValueByName = _a.getFieldValueByName, fetchMore = _a.fetchMore, onSearch = _a.onSearch;
    var t = (0, localization_1.useLocalization)().formatMessage;
    var filteredOptionList = (0, react_1.useMemo)(function () {
        var existingValues = values.map(function (_a) {
            var name = _a.name;
            return name;
        });
        return attributesOptionList.filter(function (_a) {
            var value = _a.value;
            return value && !existingValues.includes(value);
        });
    }, [values, attributesOptionList]);
    var isAddDisabled = values === null || values === void 0 ? void 0 : values[0].readonly;
    // Formik does not provide an error for the array itself if there are errors in the nested fields
    var isDuplicationError = (0, react_1.useMemo)(function () { return (0, utils_1.areDuplicatesExist)({ list: values, propName: 'name' }); }, [values]);
    return (react_1.default.createElement(create_attribute_form_styles_1.StyledFormSection, null, values === null || values === void 0 ? void 0 :
        values.map(function (_item, index) {
            var attributeLabel = getFieldValueByName(values, "".concat(index, ".").concat(create_attribute_form_constants_1.AttributeFormFieldNames.Name));
            var isNewLabel = !!attributeLabel
                && attributesOptionList.findIndex(function (_a) {
                    var label = _a.label;
                    return (0, utils_1.areEqualCaseInsensitive)(label, attributeLabel);
                }) === -1;
            return (react_1.default.createElement(attribute_form_list_item_component_1.AttributeFormListItem, { isEditingAllowedForReadonlyValues: allowCreation, key: index, name: "".concat(name, ".").concat(index), nameFieldTitle: t({ key: 'ATTRIBUTE' }), descriptionFieldTitle: t({ key: 'ENTITY_GUIDELINES', values: { entity: t({ key: 'ATTRIBUTE' }) } }), options: filteredOptionList, isLoading: isLoading, hasNextPage: hasNextPage, moreItemsLoading: moreItemsLoading, error: error, isRemoveDisabled: isRemoveDisabled, hideDescription: hideDescription, onRemoveClick: function () { return onRemove(index); }, fetchMore: fetchMore, onSearch: onSearch },
                react_1.default.createElement(partials_1.FormListManager, { name: "".concat(name, ".").concat(index, ".").concat(create_attribute_form_constants_1.AttributeFormFieldNames.Values), component: value_form_list_container_1.ValueFormList, componentProps: {
                        hideDescription: hideDescription,
                        attributeLabel: attributeLabel,
                        isNewLabel: isNewLabel,
                        valueOptionListStrategyNodeId: valueOptionListStrategyNodeId,
                        allowCreation: allowCreation,
                    } })));
        }),
        isDuplicationError && (react_1.default.createElement(ui_components_1.Typography, { color: "error", variant: "caption" }, t({ key: 'VALIDATION_MUST_BE_UNIQUE', values: { name: t({ key: 'ATTRIBUTE' }) } }))),
        !isAddDisabled && (react_1.default.createElement(partials_1.AddButton, { disabled: !filteredOptionList.length, entityName: t({ key: 'ATTRIBUTE' }), onClick: function () { return onAdd(create_attribute_form_constants_1.initialAttributeItem); } }))));
};
exports.AttributeFormListContainer = AttributeFormListContainer;
exports.default = exports.AttributeFormListContainer;
