"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setTableSearch = exports.SET_TABLE_SEARCH = exports.setTableFilter = exports.SET_TABLE_FILTER = exports.setDynamicTableColumns = exports.SET_DYNAMIC_TABLE_COLUMNS = void 0;
var actions_1 = require("../actions");
var utils_1 = require("../../../../store/actions/utils");
exports.SET_DYNAMIC_TABLE_COLUMNS = "".concat(actions_1.CATALOG_PREFIX, "SET_DYNAMIC_TABLE_COLUMNS");
var setDynamicTableColumns = function (catalogId, changes) { return (0, utils_1.createAction)(exports.SET_DYNAMIC_TABLE_COLUMNS, { id: catalogId, changes: changes }); };
exports.setDynamicTableColumns = setDynamicTableColumns;
exports.SET_TABLE_FILTER = "".concat(actions_1.CATALOG_PREFIX, "SET_TABLE_FILTER");
var setTableFilter = function (catalogId, changes) { return (0, utils_1.createAction)(exports.SET_TABLE_FILTER, { id: catalogId, changes: changes }); };
exports.setTableFilter = setTableFilter;
exports.SET_TABLE_SEARCH = "".concat(actions_1.CATALOG_PREFIX, "SET_TABLE_SEARCH");
var setTableSearch = function (catalogId, changes) { return (0, utils_1.createAction)(exports.SET_TABLE_SEARCH, { id: catalogId, changes: changes }); };
exports.setTableSearch = setTableSearch;
