"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StrategyMatrix = void 0;
var react_1 = __importStar(require("react"));
var ui_components_1 = require("@groupby/ui-components");
var attributes_matrix_selector_container_1 = require("../components/attributes-matrix-selector/attributes-matrix-selector.container");
var display_table_select_component_1 = require("../components/display-table-select.component");
var l1_category_matrix_selector_component_1 = require("../components/l1-category-matrix-selector.component");
var matrix_table_component_1 = require("../components/matrix-table.component");
var table_styles_1 = require("../components/table.styles");
var localization_1 = require("../../localization");
var store_1 = require("../../store");
var StrategyMatrix = function () {
    var t = (0, localization_1.useLocalization)().formatMessage;
    var selectedCatalogId = (0, store_1.useSelectedCatalogId)();
    var productTreeRootNodeId = (0, store_1.useProductTreeRootNodeId)();
    var selectedProductTreeId = (0, store_1.useProductTreeId)();
    var _a = __read((0, react_1.useState)(null), 2), selectedAttribute = _a[0], setSelectedAttribute = _a[1];
    var _b = __read((0, react_1.useState)(null), 2), selectedL1Categories = _b[0], setSelectedL1Categories = _b[1];
    var _c = __read((0, react_1.useState)(null), 2), selectedDisplayTable = _c[0], setSelectedDisplayTable = _c[1];
    var _d = __read((0, react_1.useState)(false), 2), categoriesIsLoading = _d[0], setCategoriesIsLoading = _d[1];
    var handleSelectAttribute = function (selected) {
        setSelectedAttribute(selected[0]);
    };
    var handleSelectL1Category = function (selected) {
        setSelectedL1Categories(selected);
    };
    var handleSelectDisplayTable = function (selected) {
        setSelectedDisplayTable(selected);
    };
    return (react_1.default.createElement(table_styles_1.StyledMatrixContainer, null,
        react_1.default.createElement(ui_components_1.PageTitle, { className: "title", title: t({ key: 'STRATEGY_MATRIX' }) }),
        react_1.default.createElement(table_styles_1.SelectorsWrapper, null,
            react_1.default.createElement(l1_category_matrix_selector_component_1.L1CategorySelect, { selectedCatalogId: selectedCatalogId, onLoadingChange: function (isLoading) { return setCategoriesIsLoading(isLoading); }, selectedProductTreeId: selectedProductTreeId, productTreeRootNodeId: productTreeRootNodeId, onSelect: handleSelectL1Category }),
            react_1.default.createElement(attributes_matrix_selector_container_1.AttributeSelect, { productTreeRootNodeId: productTreeRootNodeId, selectedCatalogId: selectedCatalogId, onSelect: handleSelectAttribute }),
            react_1.default.createElement(display_table_select_component_1.DisplayTableSelect, { selectedCatalogId: selectedCatalogId, onSelect: handleSelectDisplayTable })),
        react_1.default.createElement(matrix_table_component_1.MatrixTable, { selectedProductTreeId: selectedProductTreeId, selectedCatalogId: selectedCatalogId, selectedStrategyNodeId: productTreeRootNodeId, selectedAttribute: selectedAttribute, selectedL1Categories: selectedL1Categories, categoriesIsLoading: categoriesIsLoading, selectedDisplayTable: selectedDisplayTable })));
};
exports.StrategyMatrix = StrategyMatrix;
exports.default = exports.StrategyMatrix;
