"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SingleCommentingDialog = void 0;
var react_1 = __importStar(require("react"));
var sort_1 = require("@groupby/enrich-sdk/sort");
var ui_components_1 = require("@groupby/ui-components");
var ramda_1 = require("ramda");
var use_comments_hook_1 = require("./hooks/use-comments.hook");
var partials_1 = require("./partials");
var single_commenting_dialog_styles_1 = require("./single-commenting-dialog.styles");
var partials_2 = require("../partials");
var generic_1 = require("../../../../../generic");
var icons_1 = require("../../../../../icons");
var localization_1 = require("../../../../../localization");
var SingleCommentingDialog = function (_a) {
    var _b = _a.product, id = _b.id, proprietaryId = _b.proprietaryId, title = _b.title, onClose = _a.onClose;
    var t = (0, localization_1.useLocalization)().formatMessage;
    var _c = (0, use_comments_hook_1.useComments)({ productId: id }), comments = _c.comments, toggleCommentsSorting = _c.toggleCommentsSorting, createComment = _c.createComment, sortOrder = _c.sortOrder, isLoading = _c.isLoading, isSubmitting = _c.isSubmitting, lastCreatedId = _c.lastCreatedId;
    var handleClose = function () { return onClose(!!lastCreatedId); };
    var shouldCommentBeHighlighted = (0, react_1.useCallback)(function (_a, previousComment) {
        var id = _a.id;
        return id === previousComment.id && id === lastCreatedId;
    }, [lastCreatedId]);
    return (react_1.default.createElement(ui_components_1.Dialog, { maxWidth: "sm", title: react_1.default.createElement(partials_2.DialogTitle, { title: title, subtitle: proprietaryId, onClose: handleClose }) },
        react_1.default.createElement(single_commenting_dialog_styles_1.StyledDialogContent, null,
            isLoading && react_1.default.createElement(generic_1.SimpleLoading, null),
            !isLoading && (0, ramda_1.isEmpty)(comments) && (react_1.default.createElement(generic_1.NoDataFallback, { icon: react_1.default.createElement(icons_1.NoCommentsIcon, null), title: t({ key: 'PRODUCT_VIEWER_NO_COMMENTS_HINT' }) })),
            !isLoading && !(0, ramda_1.isEmpty)(comments) && (react_1.default.createElement(single_commenting_dialog_styles_1.StyledCommentsContainer, null,
                react_1.default.createElement("div", { className: "comments-container-toolbar" },
                    react_1.default.createElement(ui_components_1.Typography, { strong: true, color: "textPrimary", variant: "body2" }, t({ key: 'COMMENTS', values: { count: comments.length } })),
                    react_1.default.createElement(single_commenting_dialog_styles_1.StyledButton, { title: t({ key: sortOrder === sort_1.SortDirection.Ascending ? 'SORT_ORDER_OLDEST_FIRST' : 'SORT_ORDER_NEWEST_FIRST' }), endIcon: react_1.default.createElement(icons_1.ArrowUpDownIcon, null), onClick: toggleCommentsSorting })),
                react_1.default.createElement("div", { className: "comments-container-content" }, comments.map(function (comment) { return (react_1.default.createElement(partials_1.Comment, { shouldCommentBeHighlighted: shouldCommentBeHighlighted, key: comment.id, comment: comment })); })))),
            react_1.default.createElement(partials_2.CommentTextBox, { isSubmitDisabled: isLoading, isSubmitting: isSubmitting, onSubmit: createComment }))));
};
exports.SingleCommentingDialog = SingleCommentingDialog;
