"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.productsTableReducer = exports.defaultProductsTableState = void 0;
var Actions = __importStar(require("./actions"));
var utils_1 = require("../../../hooks/product-table-dynamic-columns/utils");
exports.defaultProductsTableState = {
    dynamicColumns: {
        columnsOrder: [],
        excludedColumns: [],
        filterOptions: [],
        selectAllMode: false,
        reviewAttribute: undefined,
    },
    filter: {},
    search: '',
};
// eslint-disable-next-line default-param-last
var productsTableReducer = function (state, action) {
    if (state === void 0) { state = exports.defaultProductsTableState; }
    switch (action.type) {
        case Actions.SET_DYNAMIC_TABLE_COLUMNS:
            return updateDynamicTableColumns(state, action.payload);
        case Actions.SET_TABLE_FILTER:
            return updateTableFilter(state, action.payload);
        case Actions.SET_TABLE_SEARCH:
            return updateTableSearch(state, action.payload);
        default:
            return state;
    }
};
exports.productsTableReducer = productsTableReducer;
var updateDynamicTableColumns = function (state, _a) {
    var _b;
    var changes = _a.changes;
    var newDynamicColumns = __assign(__assign(__assign({}, exports.defaultProductsTableState.dynamicColumns), state.dynamicColumns), changes);
    var newFilter = Object.keys((_b = state.filter) !== null && _b !== void 0 ? _b : {}).reduce(function (acc, fieldKey) {
        var _a;
        var _b;
        if (!(0, utils_1.checkIsColumnSelected)(fieldKey, newDynamicColumns)) {
            return acc;
        }
        return __assign(__assign({}, acc), (_a = {}, _a[fieldKey] = (_b = state.filter) === null || _b === void 0 ? void 0 : _b[fieldKey], _a));
    }, {});
    return __assign(__assign({}, state), { dynamicColumns: newDynamicColumns, filter: newFilter });
};
var updateTableFilter = function (state, _a) {
    var changes = _a.changes;
    return (__assign(__assign({}, state), { filter: changes }));
};
var updateTableSearch = function (state, _a) {
    var changes = _a.changes;
    return (__assign(__assign({}, state), { search: changes }));
};
