"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CategorySelector = void 0;
var react_1 = __importStar(require("react"));
var common_1 = require("../../../../../../common");
var localization_1 = require("../../../../../../localization");
var store_1 = require("../../../../../../store");
var CategorySelector = function (_a) {
    var onOptionChange = _a.onOptionChange, selected = _a.selected;
    var t = (0, localization_1.useLocalization)().formatMessage;
    var categoryGroups = (0, store_1.useCategoryGroupsSelector)();
    var options = (0, react_1.useMemo)(function () { return categoryGroups.reduce(function (groupedOptions, categoryGroup) {
        var groupTitle = categoryGroup.title;
        var levelOptions = categoryGroup.subCategories.map(function (subCat) { return ({
            label: subCat.title,
            value: subCat.id,
            group: groupTitle,
        }); });
        return __spreadArray(__spreadArray([], __read(groupedOptions), false), __read(levelOptions), false);
    }, []); }, [categoryGroups]);
    return (react_1.default.createElement(common_1.AutocompleteSelector, { options: options, groupBy: function (option) { return option.group; }, onChange: onOptionChange, selected: selected, placeholder: t({ key: 'CATEGORY' }) }));
};
exports.CategorySelector = CategorySelector;
exports.default = exports.CategorySelector;
