"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useProducts = void 0;
var react_1 = require("react");
var utils_1 = require("./utils");
var hooks_1 = require("../products-preview/products-list-view/hooks");
var use_get_products_hook_1 = require("../../../client/product/hooks/use-get-products.hook");
var utils_2 = require("../../../client/product/hooks/utils");
var products_table_1 = require("../../../store/catalogs/products-table");
var feature_flags_1 = require("../../../../feature-flags");
var hooks_2 = require("../../../../hooks");
var store_1 = require("../../../../store");
var useProducts = function () {
    var featureFlagsService = (0, hooks_2.useFeatureFlagsService)().featureFlagsService;
    var isMeasureEnabled = featureFlagsService.isFeatureEnabled(feature_flags_1.FeatureFlag.Measures);
    var selectedAttributeNode = (0, store_1.useSelectedAttributeNode)();
    var catalogId = (0, store_1.useSelectedCatalogId)();
    var productTreeNodeId = (0, store_1.useSelectedTreeNodeId)();
    var searchTerm = (0, products_table_1.useProductsTableSearch)();
    var isListViewMode = (0, store_1.useIsListViewMode)();
    var tableFilter = (0, products_table_1.useProductsTableFilter)();
    // Have to get table config regardless of active view mode
    var _a = (0, hooks_1.useProductsTableConfig)({}).tableColumns.selection, excludedLabelColumns = _a.excludedLabelColumns, excludedMeasureColumns = _a.excludedMeasureColumns, selectAllMode = _a.selectAllMode;
    // fetch only the required product text attributes
    var productTextAttributesFilter = (0, react_1.useMemo)(function () { return (isListViewMode ? (0, utils_1.getProductTextAttributesFilter)(excludedLabelColumns, selectAllMode) : null); }, [isListViewMode, excludedLabelColumns, selectAllMode]);
    // fetch only the required product numeric attributes (measures)
    var productMeasuresFilter = (0, react_1.useMemo)(function () { return (isMeasureEnabled && isListViewMode ? (0, utils_1.getProductMeasuresFilter)(excludedMeasureColumns, selectAllMode) : null); }, [isMeasureEnabled, isListViewMode, excludedMeasureColumns, selectAllMode]);
    /*
    * - Table filtering logic
    * - Search by input text
    * - Filter by selected value from the middle section
    */
    var filter = (0, react_1.useMemo)(function () {
        var _a;
        var _b = (_a = (0, utils_1.getProductListFilter)(tableFilter)) !== null && _a !== void 0 ? _a : {}, _c = _b.attributes, productListAttributesFilter = _c === void 0 ? [] : _c, product = _b.product;
        var _d = product !== null && product !== void 0 ? product : {}, _e = _d.attributes, attributes = _e === void 0 ? [] : _e, productListProductFilter = __rest(_d, ["attributes"]);
        var selectedAttributeValueFilter = selectedAttributeNode ? (0, utils_2.createFilterFromAttributeNode)(selectedAttributeNode) : [];
        var productListProductAttributesFilter = attributes.concat(selectedAttributeValueFilter);
        var mergedFilter = (0, utils_2.mergeProductPresentationAttributesFilter)(productListAttributesFilter, productListProductAttributesFilter, productListProductFilter, searchTerm);
        return mergedFilter;
    }, [searchTerm, selectedAttributeNode, tableFilter]);
    // Get actual products
    var productsResponse = (0, use_get_products_hook_1.useGetProducts)({
        catalogId: catalogId,
        productTreeNodeId: productTreeNodeId,
        attributeNode: selectedAttributeNode,
        skipMeasures: !isMeasureEnabled,
        filter: filter,
        productTextAttributesFilter: productTextAttributesFilter,
        productMeasuresFilter: productMeasuresFilter,
    });
    return productsResponse;
};
exports.useProducts = useProducts;
