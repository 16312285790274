"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSearchL1CategoriesAndTerminalNodesForStrategyMatrix = void 0;
var react_1 = require("react");
var client_1 = require("@apollo/client");
var graphql_1 = require("../../../__generated__/graphql");
var gql_1 = require("../gql");
var client_constants_1 = require("../../../enrich-ai/client/client.constants");
var client_utils_1 = require("../../../enrich-ai/client/client.utils");
var useSearchL1CategoriesAndTerminalNodesForStrategyMatrix = function (_a) {
    var productTreeId = _a.productTreeId, searchTerm = _a.searchTerm;
    var _b = (0, client_1.useQuery)(gql_1.SEARCH_L1_CATEGORIES_AND_TERMINAL_NODES_FOR_STRATEGY_MATRIX, {
        variables: {
            productTreeId: productTreeId,
            first: 2,
            query: searchTerm,
            sort: [{ distance: graphql_1.SortOrder.Asc }],
            searchProductTreeNodeFirst: client_constants_1.SEARCH_RESULTS_LIMIT,
        },
        skip: !productTreeId || !searchTerm,
    }), data = _b.data, error = _b.error, networkStatus = _b.networkStatus;
    var isLoading = (0, client_utils_1.isQueryLoadingOrRefetching)(networkStatus);
    /**
     * We assume we ALWAYS have at least 2 ancestors, the first is "Ontology"
     * and the second is the L1 category we want.
     */
    var groupedOptions = (0, react_1.useMemo)(function () {
        if (!(data === null || data === void 0 ? void 0 : data.searchProductTreeNode))
            return [];
        var map = new Map();
        data.searchProductTreeNode.forEach(function (_a) {
            var _b, _c;
            var productTreeNode = _a.productTreeNode;
            var ancestors = productTreeNode.ancestors.edges;
            var secondAncestor = (_c = (_b = ancestors[1]) === null || _b === void 0 ? void 0 : _b.node) === null || _c === void 0 ? void 0 : _c.ancestor;
            if (!secondAncestor)
                return;
            var parentId = secondAncestor.id;
            var parentTitle = secondAncestor.title;
            if (!map.has(parentId)) {
                map.set(parentId, {
                    label: parentTitle,
                    value: parentId,
                    children: [],
                });
            }
            var parentObj = map.get(parentId);
            if (!parentObj) {
                // If an ancestor is missing or the query returns fewer than two ancestors
                return;
            }
            parentObj.children.push({
                label: productTreeNode.title,
                value: productTreeNode.id,
            });
        });
        return Array.from(map.values());
    }, [data]);
    return {
        data: groupedOptions,
        isLoading: isLoading,
        error: error,
    };
};
exports.useSearchL1CategoriesAndTerminalNodesForStrategyMatrix = useSearchL1CategoriesAndTerminalNodesForStrategyMatrix;
