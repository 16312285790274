"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGetAllAttributeValuesForStrategyMatrix = void 0;
var react_1 = require("react");
var client_1 = require("@apollo/client");
var gql_1 = require("../gql");
var client_constants_1 = require("../../../enrich-ai/client/client.constants");
var client_utils_1 = require("../../../enrich-ai/client/client.utils");
var useGetAllAttributeValuesForStrategyMatrix = function (_a) {
    var productTreeNodeId = _a.productTreeNodeId, catalog = _a.catalog, selectedAttribute = _a.selectedAttribute;
    var filter = (0, react_1.useMemo)(function () { return (selectedAttribute ? { some: { label: { equals: selectedAttribute } } } : undefined); }, [selectedAttribute]);
    var _b = __read((0, react_1.useState)([]), 2), allEdges = _b[0], setAllEdges = _b[1];
    var _c = __read((0, react_1.useState)(false), 2), fullyLoaded = _c[0], setFullyLoaded = _c[1];
    // A ref to ensure we don’t fetch again while still fetching.
    var isFetching = (0, react_1.useRef)(false);
    var _d = (0, client_1.useQuery)(gql_1.GET_ALL_ATTRIBUTE_VALUES_FOR_STRATEGY_MATRIX, {
        variables: {
            productTreeNodeId: productTreeNodeId,
            first: client_constants_1.REQUEST_LIMIT_MAX,
            filter: filter,
            after: null,
        },
        skip: !productTreeNodeId || !selectedAttribute || !catalog,
        notifyOnNetworkStatusChange: true,
        onCompleted: function (initialData) {
            var _a, _b, _c, _d, _e, _f, _g;
            var firstEdges = (_g = (_f = (_e = (_d = (_c = (_b = (_a = initialData === null || initialData === void 0 ? void 0 : initialData.productTreeNode) === null || _a === void 0 ? void 0 : _a.labelValidation) === null || _b === void 0 ? void 0 : _b.edges) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.node) === null || _e === void 0 ? void 0 : _e.values) === null || _f === void 0 ? void 0 : _f.edges) !== null && _g !== void 0 ? _g : [];
            setAllEdges(firstEdges);
        },
    }), data = _d.data, error = _d.error, networkStatus = _d.networkStatus, fetchMore = _d.fetchMore;
    // will be redone in next ticket EN-4137
    // look for solution here use-get-all-aggregated-attributes.hook.ts
    // add func to dynamically set number of values
    // use updateQuery
    (0, react_1.useEffect)(function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
        if (!data || fullyLoaded || isFetching.current) {
            return;
        }
        var pageInfo = (_f = (_e = (_d = (_c = (_b = (_a = data === null || data === void 0 ? void 0 : data.productTreeNode) === null || _a === void 0 ? void 0 : _a.labelValidation) === null || _b === void 0 ? void 0 : _b.edges) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.node) === null || _e === void 0 ? void 0 : _e.values) === null || _f === void 0 ? void 0 : _f.pageInfo;
        var aggregatedEdges = (_o = (_m = (_l = (_k = (_j = (_h = (_g = data === null || data === void 0 ? void 0 : data.productTreeNode) === null || _g === void 0 ? void 0 : _g.labelValidation) === null || _h === void 0 ? void 0 : _h.edges) === null || _j === void 0 ? void 0 : _j[0]) === null || _k === void 0 ? void 0 : _k.node) === null || _l === void 0 ? void 0 : _l.values) === null || _m === void 0 ? void 0 : _m.edges) !== null && _o !== void 0 ? _o : [];
        var fetchAllPages = function () { return __awaiter(void 0, void 0, void 0, function () {
            var moreData, newEdges, newPageInfo, err_1;
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
            return __generator(this, function (_p) {
                switch (_p.label) {
                    case 0:
                        isFetching.current = true;
                        _p.label = 1;
                    case 1:
                        if (!(pageInfo === null || pageInfo === void 0 ? void 0 : pageInfo.hasNextPage)) return [3 /*break*/, 6];
                        _p.label = 2;
                    case 2:
                        _p.trys.push([2, 4, , 5]);
                        return [4 /*yield*/, fetchMore({
                                variables: {
                                    first: client_constants_1.REQUEST_LIMIT_MAX,
                                    after: pageInfo.endCursor,
                                },
                            })];
                    case 3:
                        moreData = (_p.sent()).data;
                        newEdges = (_g = (_f = (_e = (_d = (_c = (_b = (_a = moreData === null || moreData === void 0 ? void 0 : moreData.productTreeNode) === null || _a === void 0 ? void 0 : _a.labelValidation) === null || _b === void 0 ? void 0 : _b.edges) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.node) === null || _e === void 0 ? void 0 : _e.values) === null || _f === void 0 ? void 0 : _f.edges) !== null && _g !== void 0 ? _g : [];
                        newPageInfo = (_o = (_m = (_l = (_k = (_j = (_h = moreData === null || moreData === void 0 ? void 0 : moreData.productTreeNode) === null || _h === void 0 ? void 0 : _h.labelValidation) === null || _j === void 0 ? void 0 : _j.edges) === null || _k === void 0 ? void 0 : _k[0]) === null || _l === void 0 ? void 0 : _l.node) === null || _m === void 0 ? void 0 : _m.values) === null || _o === void 0 ? void 0 : _o.pageInfo;
                        if (!newEdges.length || newPageInfo.endCursor === pageInfo.endCursor) {
                            return [3 /*break*/, 6];
                        }
                        aggregatedEdges = __spreadArray(__spreadArray([], __read(aggregatedEdges), false), __read(newEdges), false);
                        setAllEdges(aggregatedEdges);
                        pageInfo = newPageInfo;
                        return [3 /*break*/, 5];
                    case 4:
                        err_1 = _p.sent();
                        console.error('Error fetching more pages:', err_1);
                        return [3 /*break*/, 6];
                    case 5: return [3 /*break*/, 1];
                    case 6:
                        setFullyLoaded(true);
                        isFetching.current = false;
                        return [2 /*return*/];
                }
            });
        }); };
        fetchAllPages();
    }, [data, fullyLoaded, fetchMore]);
    return {
        data: allEdges,
        isLoading: (0, client_utils_1.isQueryLoadingOrRefetching)(networkStatus) && !fullyLoaded,
        error: error === null || error === void 0 ? void 0 : error.message,
    };
};
exports.useGetAllAttributeValuesForStrategyMatrix = useGetAllAttributeValuesForStrategyMatrix;
