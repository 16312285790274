"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.setAdvancedViewerFilter = exports.productsSetTotal = exports.clearSelectedProducts = exports.setSelectedProducts = exports.clearAllSelectedProducts = exports.addSelectedProductIds = exports.addSelectedProductId = exports.setIsExclusionModeOn = exports.productsClearExcludedTaxonomyClassIds = exports.productsClearIncludedTaxonomyClassIds = exports.productsSetExcludedTaxonomyClassIds = exports.clearNotExistTaxonomyClasses = exports.productsSetIncludedTaxonomyClassIds = exports.productsSetExcludedAttributesIds = exports.productsSetIncludedAttributesIds = exports.productsSetExcludedCategoriesIds = exports.productsSetIncludedCategoriesIds = exports.productsSetFilterOperator = exports.productsSetFilterActive = void 0;
var Actions = __importStar(require("../types/products"));
var utils_1 = require("../utils");
var productsSetFilterActive = function (type) { return ((0, utils_1.createAction)(Actions.PRODUCTS_SET_FILTER_ACTIVE, { type: type })); };
exports.productsSetFilterActive = productsSetFilterActive;
var productsSetFilterOperator = function (type) { return ((0, utils_1.createAction)(Actions.PRODUCTS_SET_FILTER_OPERATOR, { type: type })); };
exports.productsSetFilterOperator = productsSetFilterOperator;
var productsSetIncludedCategoriesIds = function (includedCategoriesIds) { return ((0, utils_1.createAction)(Actions.PRODUCTS_SET_INCLUDED_CATEGORIES_IDS, { includedCategoriesIds: includedCategoriesIds })); };
exports.productsSetIncludedCategoriesIds = productsSetIncludedCategoriesIds;
var productsSetExcludedCategoriesIds = function (excludedCategoriesIds) { return ((0, utils_1.createAction)(Actions.PRODUCTS_SET_EXCLUDED_CATEGORIES_IDS, { excludedCategoriesIds: excludedCategoriesIds })); };
exports.productsSetExcludedCategoriesIds = productsSetExcludedCategoriesIds;
var productsSetIncludedAttributesIds = function (includedAttributesIds) { return ((0, utils_1.createAction)(Actions.PRODUCTS_SET_INCLUDED_ATTRIBUTES_IDS, { includedAttributesIds: includedAttributesIds })); };
exports.productsSetIncludedAttributesIds = productsSetIncludedAttributesIds;
var productsSetExcludedAttributesIds = function (excludedAttributesIds) { return ((0, utils_1.createAction)(Actions.PRODUCTS_SET_EXCLUDED_ATTRIBUTES_IDS, { excludedAttributesIds: excludedAttributesIds })); };
exports.productsSetExcludedAttributesIds = productsSetExcludedAttributesIds;
var productsSetIncludedTaxonomyClassIds = function (id) { return ((0, utils_1.createAction)(Actions.PRODUCTS_SET_INCLUDED_TAXONOMY_CLASS_IDS, { id: id })); };
exports.productsSetIncludedTaxonomyClassIds = productsSetIncludedTaxonomyClassIds;
var clearNotExistTaxonomyClasses = function (ids) { return ((0, utils_1.createAction)(Actions.CLEAR_NOT_EXIST_TAXONOMY_CLASSES, { ids: ids })); };
exports.clearNotExistTaxonomyClasses = clearNotExistTaxonomyClasses;
var productsSetExcludedTaxonomyClassIds = function (id) { return ((0, utils_1.createAction)(Actions.PRODUCTS_SET_EXCLUDED_TAXONOMY_CLASS_IDS, { id: id })); };
exports.productsSetExcludedTaxonomyClassIds = productsSetExcludedTaxonomyClassIds;
var productsClearIncludedTaxonomyClassIds = function () { return (0, utils_1.createAction)(Actions.PRODUCTS_CLEAR_INCLUDED_TAXONOMY_CLASS_IDS); };
exports.productsClearIncludedTaxonomyClassIds = productsClearIncludedTaxonomyClassIds;
var productsClearExcludedTaxonomyClassIds = function () { return (0, utils_1.createAction)(Actions.PRODUCTS_CLEAR_EXCLUDED_TAXONOMY_CLASS_IDS); };
exports.productsClearExcludedTaxonomyClassIds = productsClearExcludedTaxonomyClassIds;
var setIsExclusionModeOn = function (isExclusionModeOn) { return ((0, utils_1.createAction)(Actions.PRODUCTS_SET_IS_EXCLUSION_MODE_ON, { isExclusionModeOn: isExclusionModeOn })); };
exports.setIsExclusionModeOn = setIsExclusionModeOn;
var addSelectedProductId = function (id) { return (0, utils_1.createAction)(Actions.PRODUCTS_ADD_SELECTED_ID, { id: id }); };
exports.addSelectedProductId = addSelectedProductId;
var addSelectedProductIds = function (ids) { return ((0, utils_1.createAction)(Actions.PRODUCTS_ADD_SELECTED_IDS, { ids: ids })); };
exports.addSelectedProductIds = addSelectedProductIds;
var clearAllSelectedProducts = function () { return (0, utils_1.createAction)(Actions.PRODUCTS_CLEAR_ALL_SELECTED); };
exports.clearAllSelectedProducts = clearAllSelectedProducts;
var setSelectedProducts = function (ids) { return (0, utils_1.createAction)(Actions.PRODUCTS_SET_SELECTED, { ids: ids }); };
exports.setSelectedProducts = setSelectedProducts;
var clearSelectedProducts = function (ids) { return ((0, utils_1.createAction)(Actions.PRODUCTS_CLEAR_SELECTED, { ids: ids })); };
exports.clearSelectedProducts = clearSelectedProducts;
var productsSetTotal = function (total) { return ((0, utils_1.createAction)(Actions.PRODUCTS_SET_TOTAL, { total: total })); };
exports.productsSetTotal = productsSetTotal;
var setAdvancedViewerFilter = function (filter) { return ((0, utils_1.createAction)(Actions.PRODUCTS_SET_ADVANCED_VIEWER_FILTER, { filter: filter })); };
exports.setAdvancedViewerFilter = setAdvancedViewerFilter;
