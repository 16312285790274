"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGetDescendantsForL1CategoryForStrategyMatrix = void 0;
var client_1 = require("@apollo/client");
var constants_1 = require("../../constants");
var gql_1 = require("../gql");
var client_utils_1 = require("../../../enrich-ai/client/client.utils");
var useGetDescendantsForL1CategoryForStrategyMatrix = function (_a) {
    var ancestralProductTreeId = _a.ancestralProductTreeId, selectedL1Categories = _a.selectedL1Categories;
    var baseFilter = { id: { in: selectedL1Categories } };
    var _b = (0, client_1.useQuery)(gql_1.GET_DESCENDANTS_FOR_L1_CATEGORY_STRATEGY_MATRIX, {
        variables: {
            ancestralProductTreeId: ancestralProductTreeId,
            filter: baseFilter,
            descendantsFilter: constants_1.DEFAULT_DESCENDANTS_FILTER_STRATEGY_MATRIX,
            descendantsFirst: 1900,
            productTreeNodesFirst: selectedL1Categories === null || selectedL1Categories === void 0 ? void 0 : selectedL1Categories.length,
        },
        skip: !ancestralProductTreeId || !selectedL1Categories,
    }), data = _b.data, error = _b.error, networkStatus = _b.networkStatus;
    // const { edges, pageInfo } = data?.productTreeNode?.descendants ?? {};
    // const pagination = usePagination({
    //   pageInfo,
    //   networkStatus,
    //   pageSize,
    //   fetchMore,
    //   fetchMoreOptions,
    //   lastVariables: variables,
    // });
    return {
        data: data,
        isLoading: (0, client_utils_1.isQueryLoadingOrRefetching)(networkStatus),
        isRefetching: (0, client_utils_1.isQueryRefetching)(networkStatus),
        error: error === null || error === void 0 ? void 0 : error.message,
    };
};
exports.useGetDescendantsForL1CategoryForStrategyMatrix = useGetDescendantsForL1CategoryForStrategyMatrix;
