"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.useRefetchQueries = exports.QueryType = void 0;
var react_1 = require("react");
var client_1 = require("@apollo/client");
var utilities_1 = require("@apollo/client/utilities");
var gql_1 = require("../attributes/gql");
var measures_1 = require("../measures");
var get_product_presentation_query_1 = require("../product/get-product-presentation.query");
var get_products_query_1 = require("../product/gql/get-products.query");
var gql_2 = require("../values/gql");
var store_1 = require("../../../store");
var QueryType;
(function (QueryType) {
    QueryType[QueryType["StrategyAttributes"] = 0] = "StrategyAttributes";
    QueryType[QueryType["ProductAttributes"] = 1] = "ProductAttributes";
    QueryType[QueryType["ProductCounters"] = 2] = "ProductCounters";
    QueryType[QueryType["Products"] = 3] = "Products";
    QueryType[QueryType["ProductDetails"] = 4] = "ProductDetails";
    QueryType[QueryType["StrategyAttributeValues"] = 5] = "StrategyAttributeValues";
})(QueryType = exports.QueryType || (exports.QueryType = {}));
var QueryTypeToDocumentNodeMap = (_a = {},
    _a[QueryType.StrategyAttributes] = [gql_1.GET_AGGREGATED_TEXT_ATTRIBUTES],
    _a[QueryType.ProductAttributes] = [gql_1.GET_AGGREGATED_TEXT_ATTRIBUTES, measures_1.GET_AGGREGATED_PRODUCT_MEASURES],
    _a[QueryType.Products] = [get_products_query_1.GET_PRODUCTS],
    _a[QueryType.ProductDetails] = [get_product_presentation_query_1.GET_PRODUCT_TREE_NODE_PRESENTATION],
    _a[QueryType.StrategyAttributeValues] = [
        gql_2.GET_AGGREGATED_TEXT_ATTRIBUTE_VALUES,
        get_products_query_1.GET_PRODUCTS,
    ],
    _a[QueryType.ProductCounters] = [gql_1.GET_AGGREGATED_TEXT_ATTRIBUTES, gql_2.GET_AGGREGATED_TEXT_ATTRIBUTE_VALUES],
    _a);
var CacheEntity;
(function (CacheEntity) {
    CacheEntity["ProductTreeNode"] = "ProductTreeNode";
})(CacheEntity || (CacheEntity = {}));
var CacheEntityField;
(function (CacheEntityField) {
    CacheEntityField["StrategyAttribute"] = "productAggregatedLabelsNew";
    CacheEntityField["ProductAttribute"] = "productAggregatedLabelsNew";
    CacheEntityField["ProductMeasure"] = "productAggregateMeasures";
    CacheEntityField["ProductPresentations"] = "productPresentations";
})(CacheEntityField || (CacheEntityField = {}));
var updateCache = function (catalogId, selectedTreeNodeId, attributeName) { return function (cache) {
    var measuresFilter = attributeName && { some: { dimension: { equals: attributeName } } };
    var textAttributesFilter = attributeName && { label: { equals: attributeName }, isRequirement: true };
    cache.evict({ id: "".concat(CacheEntity.ProductTreeNode, ":").concat(selectedTreeNodeId), fieldName: CacheEntityField.ProductAttribute, args: textAttributesFilter && { filter: textAttributesFilter, catalog: catalogId } });
    cache.evict({ id: "".concat(CacheEntity.ProductTreeNode, ":").concat(selectedTreeNodeId), fieldName: CacheEntityField.ProductMeasure, args: measuresFilter && { filter: measuresFilter, catalog: catalogId } });
    cache.gc();
}; };
var getQueryList = function (queries) { return queries.flatMap(function (queryType) { return QueryTypeToDocumentNodeMap[queryType]; }); };
var useRefetchQueries = function () {
    var client = (0, client_1.useApolloClient)();
    var catalogId = (0, store_1.useSelectedCatalogId)();
    var refetchProductTreeNodes = (0, react_1.useCallback)(function () { return client.refetchQueries({
        include: 'active',
        updateCache: function (cache) {
            cache.evict({ id: "".concat(CacheEntity.ProductTreeNode) });
            cache.gc();
        },
    }); }, [client]);
    /**
     *
     * Refetch strategy attributes in selected node and clear cache for others
     * */
    var refetchQueriesOnStrategyAttributeChange = (0, react_1.useCallback)(function (options) {
        var selectedTreeNodeId = options.selectedTreeNodeId, _a = options.relatedTreeNodeIds, relatedTreeNodeIds = _a === void 0 ? [] : _a;
        var allProductTreeNodes = relatedTreeNodeIds.concat(selectedTreeNodeId);
        return client.refetchQueries({
            include: getQueryList([QueryType.StrategyAttributes]),
            updateCache: function (cache) {
                /*
                  remove productAggregatedLabelsNew field from normalized object ProductTreeNode:id in cache.
                  All related queries will be reloaded
                */
                allProductTreeNodes.forEach(function (productTreeNodeId) {
                    cache.evict({ id: "".concat(CacheEntity.ProductTreeNode, ":").concat(productTreeNodeId), fieldName: CacheEntityField.StrategyAttribute });
                });
                // remove all unreachable references
                cache.gc();
            },
            onQueryUpdated: function (observableQuery) {
                // prevent values and attributes in other nodes to be reloaded
                var queryName = observableQuery.queryName, variables = observableQuery.variables;
                if (queryName === (0, utilities_1.getOperationName)(gql_1.GET_AGGREGATED_TEXT_ATTRIBUTES)
                    && variables.productTreeNodeId === selectedTreeNodeId) {
                    return observableQuery.refetch();
                }
                return false;
            },
        });
    }, [client]);
    // caching enabled for values, so we need to refetch queries in the current node and clear in other related nodes for a specific attribute
    var refetchQueriesOnStrategyValueChange = (0, react_1.useCallback)(function (options) {
        var selectedTreeNodeId = options.selectedTreeNodeId, _a = options.relatedTreeNodeIds, relatedTreeNodeIds = _a === void 0 ? [] : _a, attributeName = options.attributeName;
        var allProductTreeNodes = relatedTreeNodeIds.concat(selectedTreeNodeId);
        var filter = { isRequirement: true, label: { equals: attributeName } };
        return client.refetchQueries({
            include: getQueryList([QueryType.StrategyAttributeValues]),
            updateCache: function (cache) {
                allProductTreeNodes.forEach(function (selectedNodeId) {
                    // remove values for a specific attribute from cache
                    cache.evict({ id: "".concat(CacheEntity.ProductTreeNode, ":").concat(selectedNodeId), fieldName: CacheEntityField.StrategyAttribute, args: { filter: filter, catalog: catalogId } });
                });
                cache.gc();
            },
            onQueryUpdated: function (observableQuery) {
                // prevent values in other nodes to be reloaded
                var queryName = observableQuery.queryName, variables = observableQuery.variables;
                if (queryName === (0, utilities_1.getOperationName)(gql_2.GET_AGGREGATED_TEXT_ATTRIBUTE_VALUES)
                    && variables.productTreeNodeId === selectedTreeNodeId) {
                    return observableQuery.refetch();
                }
                if (queryName === (0, utilities_1.getOperationName)(get_products_query_1.GET_PRODUCTS)) {
                    return observableQuery.refetch();
                }
                return false;
            },
        });
    }, [catalogId, client]);
    var onQueryUpdated = (0, react_1.useCallback)(function (observableQuery) {
        if (observableQuery.queryName === (0, utilities_1.getOperationName)(gql_2.GET_AGGREGATED_TEXT_ATTRIBUTE_VALUES)) {
            return false;
        }
        return observableQuery.refetch();
    }, []);
    // refetch products and product attributes and evict cache for product values without refetching
    var refetchProductList = (0, react_1.useCallback)(function (selectedTreeNodeId) { return client.refetchQueries({
        include: getQueryList([QueryType.Products]),
        updateCache: function (cache) {
            cache.evict({ id: "".concat(CacheEntity.ProductTreeNode, ":").concat(selectedTreeNodeId), fieldName: CacheEntityField.ProductPresentations });
            cache.gc();
        },
        onQueryUpdated: function (observableQuery) {
            if (observableQuery.queryName !== (0, utilities_1.getOperationName)(get_products_query_1.GET_PRODUCTS)) {
                return false;
            }
            return observableQuery.refetch();
        },
    }); }, [client]);
    var refetchProductCounters = (0, react_1.useCallback)(function (options) {
        var selectedTreeNodeId = options.selectedTreeNodeId, attributeName = options.attributeName;
        return client.refetchQueries({
            include: getQueryList([QueryType.ProductCounters]),
            updateCache: updateCache(catalogId, selectedTreeNodeId, attributeName),
            onQueryUpdated: function (observableQuery) {
                var _a, _b;
                // prevent values and attributes in other nodes to be reloaded
                var queryName = observableQuery.queryName, variables = observableQuery.variables;
                var productTreeNodeId = variables.productTreeNodeId;
                if (productTreeNodeId !== selectedTreeNodeId) {
                    return false;
                }
                if (queryName === (0, utilities_1.getOperationName)(gql_1.GET_AGGREGATED_TEXT_ATTRIBUTES)
                    && variables.filter.isRequirement) {
                    return observableQuery.refetch();
                }
                if (queryName === (0, utilities_1.getOperationName)(gql_2.GET_AGGREGATED_TEXT_ATTRIBUTE_VALUES)
                    && ((_b = (_a = variables.filter) === null || _a === void 0 ? void 0 : _a.label) === null || _b === void 0 ? void 0 : _b.equals) === attributeName) {
                    return observableQuery.refetch();
                }
                return false;
            },
        });
    }, [client, catalogId]);
    // refetch product details and product attributes and evict cache for product values without refetching
    var refetchProductDetailsAttributes = function (selectedTreeNodeId) { return client.refetchQueries({
        include: getQueryList([QueryType.ProductAttributes, QueryType.ProductDetails]),
        updateCache: updateCache(catalogId, selectedTreeNodeId),
        onQueryUpdated: onQueryUpdated,
    }); };
    return {
        refetchProductTreeNodes: refetchProductTreeNodes,
        refetchQueriesOnStrategyAttributeChange: refetchQueriesOnStrategyAttributeChange,
        refetchQueriesOnStrategyValueChange: refetchQueriesOnStrategyValueChange,
        refetchProductList: refetchProductList,
        refetchProductDetailsAttributes: refetchProductDetailsAttributes,
        refetchProductCounters: refetchProductCounters,
    };
};
exports.useRefetchQueries = useRefetchQueries;
