"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useColumnDateFilterConfig = void 0;
var react_1 = require("react");
var useColumnDateFilterConfig = function () {
    var getFilterConfig = (0, react_1.useCallback)(function (_a) {
        var id = _a.id;
        return ({
            key: id,
            getRange: function (filters) { var _a; return (((_a = filters === null || filters === void 0 ? void 0 : filters.range) === null || _a === void 0 ? void 0 : _a[id]) || { field: id }); },
            setRange: function (_a) {
                var startAt = _a.startAt, endAt = _a.endAt;
                return ({ field: id, startAt: startAt, endAt: endAt });
            },
        });
    }, []);
    return getFilterConfig;
};
exports.useColumnDateFilterConfig = useColumnDateFilterConfig;
