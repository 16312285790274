"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGetProductsTagView = void 0;
var react_1 = require("react");
var client_constants_1 = require("../../../../../../client/client.constants");
var values_1 = require("../../../../../../client/values");
var useGetProductsTagView = function (_a) {
    var catalogId = _a.catalogId, productTreeNodeId = _a.productTreeNodeId, attributeName = _a.attributeName, options = _a.options;
    var strategyValuesRequestOptions = (0, react_1.useMemo)(function () { return ({ skip: options === null || options === void 0 ? void 0 : options.skip, fetchPolicy: 'no-cache' }); }, [options]);
    var _b = (0, values_1.useGetStrategyValues)({
        productTreeNodeId: productTreeNodeId,
        pageSize: client_constants_1.REQUEST_LIMIT_MEDIUM,
        catalogId: catalogId,
        attributeName: attributeName,
        options: strategyValuesRequestOptions,
    }), strategyValues = _b.data, isStrategyValuesLoading = _b.isLoading, strategyValuesError = _b.error;
    var allowedValues = (0, react_1.useMemo)(function () { var _a; return (_a = strategyValues === null || strategyValues === void 0 ? void 0 : strategyValues.map(function (_a) {
        var value = _a.node.value;
        return value;
    })) !== null && _a !== void 0 ? _a : []; }, [strategyValues]);
    return {
        data: allowedValues,
        isLoading: isStrategyValuesLoading,
        error: strategyValuesError,
    };
};
exports.useGetProductsTagView = useGetProductsTagView;
