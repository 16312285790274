"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useProductTreeNodes = void 0;
var react_1 = require("react");
var client_1 = require("@apollo/client");
var fetch_more_options_utils_1 = require("./fetch-more-options.utils");
var client_constants_1 = require("../../client.constants");
var client_utils_1 = require("../../client.utils");
var hooks_1 = require("../../hooks");
var gql_1 = require("../gql");
var useProductTreeNodes = function (_a) {
    var _b;
    var productTreeId = _a.productTreeId, filter = _a.filter, _c = _a.pageSize, pageSize = _c === void 0 ? client_constants_1.REQUEST_LIMIT_MEDIUM : _c, options = _a.options;
    var _d = (0, client_1.useQuery)(gql_1.GET_ALL_PRODUCT_TREE_NODES, {
        variables: {
            productTreeId: productTreeId,
            filter: filter,
            first: pageSize,
        },
        notifyOnNetworkStatusChange: true,
        skip: options === null || options === void 0 ? void 0 : options.skip,
    }), data = _d.data, previousData = _d.previousData, error = _d.error, fetchMore = _d.fetchMore, refetch = _d.refetch, networkStatus = _d.networkStatus, variables = _d.variables;
    var _e = (_b = data === null || data === void 0 ? void 0 : data.productTreeNodes) !== null && _b !== void 0 ? _b : {}, edges = _e.edges, pageInfo = _e.pageInfo, _f = _e.totalCount, totalCount = _f === void 0 ? 0 : _f;
    var pagination = (0, hooks_1.usePagination)({
        pageInfo: pageInfo,
        pageSize: pageSize,
        fetchMore: fetchMore,
        fetchMoreOptions: fetch_more_options_utils_1.fetchMoreOptions,
        networkStatus: networkStatus,
        lastVariables: variables,
    });
    var search = (0, hooks_1.useClientSearch)({ networkStatus: networkStatus, refetch: refetch });
    var formattedData = (0, react_1.useMemo)(function () { return edges === null || edges === void 0 ? void 0 : edges.map(function (item) { return item.node; }); }, [edges]);
    var formattedPreviousData = (0, react_1.useMemo)(function () { var _a, _b; return (_b = (_a = previousData === null || previousData === void 0 ? void 0 : previousData.productTreeNodes) === null || _a === void 0 ? void 0 : _a.edges) === null || _b === void 0 ? void 0 : _b.map(function (item) { return item.node; }); }, [previousData]);
    var isLoading = (0, client_utils_1.isQueryLoadingOrRefetching)(networkStatus);
    return {
        data: formattedData || (isLoading ? formattedPreviousData : []) || [],
        isLoading: isLoading,
        error: error,
        totalCount: totalCount,
        pagination: pagination,
        search: search,
    };
};
exports.useProductTreeNodes = useProductTreeNodes;
exports.default = exports.useProductTreeNodes;
