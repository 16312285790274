"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.mergeProductPresentationAttributesFilter = exports.createProposalPresentationAttributesFilter = exports.createFilterFromAttributeNode = void 0;
var graphql_1 = require("../../../../__generated__/graphql");
var ramda_1 = require("ramda");
var attributes_1 = require("../../attributes");
var createFilterFromAttributeNode = function (_a) {
    // TODO: add filter to unknown value
    var _b, _c;
    var label = _a.label, value = _a.value, unit = _a.unit;
    var filterKey = unit ? 'measures' : 'labels';
    var labelFilterKey = unit ? 'dimension' : 'label';
    return [(_b = {},
            _b[filterKey] = {
                some: __assign((_c = {}, _c[labelFilterKey] = {
                    equals: label,
                }, _c.value = {
                    equals: value,
                }, _c), unit ? {
                    unit: {
                        equals: unit,
                    },
                } : null),
            },
            _b)];
};
exports.createFilterFromAttributeNode = createFilterFromAttributeNode;
// Temporary solution. Remove this when BE data model is changed (status field is required)
var createProposalPresentationAttributesFilter = function (_a) {
    var label = _a.label, value = _a.value;
    var isUnknownValue = value === attributes_1.SpecialAttributeValue.Unknown;
    if (!isUnknownValue) {
        return null;
    }
    return {
        some: {
            labels: {
                some: {
                    label: {
                        equals: label,
                    },
                    error: {
                        in: [graphql_1.ProductPresentationLabelErrorType.InvalidValue, graphql_1.ProductPresentationLabelErrorType.MissingValue],
                    },
                },
            },
        },
    };
};
exports.createProposalPresentationAttributesFilter = createProposalPresentationAttributesFilter;
var mergeProductPresentationAttributesFilter = function (productListAttributesFilter, productListProductAttributesFilter, productListProductFilter, search) {
    if (!search && !productListAttributesFilter.length && !productListProductAttributesFilter.length && (0, ramda_1.isEmpty)(productListProductFilter)) {
        return null;
    }
    return __assign(__assign({}, !!productListAttributesFilter.length && { attributes: productListAttributesFilter }), (!!productListProductAttributesFilter.length || !(0, ramda_1.isEmpty)(productListProductFilter) || search) && {
        product: __assign(__assign(__assign({}, productListProductFilter), !!productListProductAttributesFilter.length && { attributes: productListProductAttributesFilter }), search && { search: search }),
    });
};
exports.mergeProductPresentationAttributesFilter = mergeProductPresentationAttributesFilter;
