"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAttributeValidationSchema = void 0;
var Yup = __importStar(require("yup"));
var generic_1 = require("../../../generic");
var utils_1 = require("../../../utils");
var localization_1 = require("../../../../localization");
var useAttributeValidationSchema = function () {
    var _a, _b, _c;
    var t = (0, localization_1.useLocalization)().formatMessage;
    return Yup.object().shape((_a = {},
        _a[generic_1.AttributeFormFieldNames.Attributes] = Yup.array()
            .test('unique-values', t({ key: 'VALIDATION_MUST_BE_UNIQUE', values: { name: t({ key: 'ATTRIBUTE' }) } }), function (attributes) { return !(0, utils_1.areDuplicatesExist)({ list: attributes, propName: 'name' }); })
            .of(Yup.object().shape((_b = {},
            _b[generic_1.AttributeFormFieldNames.Name] = Yup.string()
                .required(t({ key: 'VALIDATION_REQUIRED' })),
            _b[generic_1.AttributeFormFieldNames.Description] = Yup.string().required(t({ key: 'VALIDATION_REQUIRED' }))
                .max(2000, t({ key: 'VALIDATION_NOT_EXCEED_LIMIT_CHARACTERS', values: { limit: '2000' } })),
            _b[generic_1.AttributeFormFieldNames.Values] = Yup.array()
                .test('unique-values', t({ key: 'VALIDATION_MUST_BE_UNIQUE', values: { name: t({ key: 'VALUE' }) } }), function (values) { return !(0, utils_1.areDuplicatesExist)({ list: values, propName: 'name', useLowerCase: false }); })
                .of(Yup.object().shape((_c = {},
                _c[generic_1.AttributeFormFieldNames.Name] = Yup.string()
                    .required(t({ key: 'VALIDATION_REQUIRED' })),
                _c[generic_1.AttributeFormFieldNames.Description] = Yup.string()
                    .max(500, t({ key: 'VALIDATION_NOT_EXCEED_LIMIT_CHARACTERS', values: { limit: '500' } })),
                _c))),
            _b))),
        _a));
};
exports.useAttributeValidationSchema = useAttributeValidationSchema;
