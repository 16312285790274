"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useColumnTextFilterConfig = void 0;
var react_1 = require("react");
var constants_1 = require("../constants");
var localization_1 = require("../../../../localization");
var useColumnTextFilterConfig = function () {
    var t = (0, localization_1.useLocalization)().formatMessage;
    var matchOptionsTranslate = (0, react_1.useCallback)(function (value) { return t({ key: constants_1.MatchOptionsToMessageKey[value] }); }, [t]);
    var getFilterConfig = (0, react_1.useCallback)(function (_a) {
        var id = _a.id;
        return ({
            key: id,
            matchOptions: constants_1.MATCH_OPTIONS,
            matchOptionsTranslate: matchOptionsTranslate,
            setText: function (_a) {
                var search = _a.search, match = _a.match;
                return ({ field: id, search: search, match: match });
            },
            getText: function (filters) { var _a; return (((_a = filters === null || filters === void 0 ? void 0 : filters.text) === null || _a === void 0 ? void 0 : _a[id]) || { field: id }); },
        });
    }, [matchOptionsTranslate]);
    return getFilterConfig;
};
exports.useColumnTextFilterConfig = useColumnTextFilterConfig;
