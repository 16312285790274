"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SEARCH_RESULTS_LIMIT = exports.REQUEST_LIMIT_MAX = exports.REQUEST_LIMIT_MEDIUM = exports.SEARCH_DELAY = exports.MIN_SEARCH_CHARACTERS_ALLOWED = exports.PAGE_SIZE_EXTRA_LARGE = exports.PAGE_SIZE_LARGE = exports.PAGE_SIZE_DEFAULT = exports.PAGE_SIZES = void 0;
exports.PAGE_SIZES = [24, 36, 120, 240, 360];
exports.PAGE_SIZE_DEFAULT = exports.PAGE_SIZES[0];
exports.PAGE_SIZE_LARGE = 100;
exports.PAGE_SIZE_EXTRA_LARGE = 300;
exports.MIN_SEARCH_CHARACTERS_ALLOWED = 3;
exports.SEARCH_DELAY = 500;
exports.REQUEST_LIMIT_MEDIUM = 1000;
exports.REQUEST_LIMIT_MAX = 2000;
exports.SEARCH_RESULTS_LIMIT = 10;
