"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AutocompleteSelector = void 0;
var react_1 = __importStar(require("react"));
var ui_components_1 = require("@groupby/ui-components");
var Autocomplete_1 = require("@mui/material/Autocomplete");
var autocomplete_selector_styles_1 = require("./autocomplete-selector.styles");
var utils_1 = require("../../enrich-ai/utils");
var localization_1 = require("../../localization");
var AutocompleteSelector = function (_a) {
    var options = _a.options, onChange = _a.onChange, selected = _a.selected, placeholder = _a.placeholder, groupBy = _a.groupBy, className = _a.className, error = _a.error, errorMessage = _a.errorMessage, isLoading = _a.isLoading, noOptionsText = _a.noOptionsText, allowCreation = _a.allowCreation, onBlur = _a.onBlur;
    var t = (0, localization_1.useLocalization)().formatMessage;
    var _b = __read((0, react_1.useState)(''), 2), inputValue = _b[0], setInputValue = _b[1];
    var filter = (0, Autocomplete_1.createFilterOptions)();
    var handleInputChange = function (_e, newValue) {
        setInputValue(newValue);
    };
    var handleChange = function (_e, option) {
        setInputValue(option === null || option === void 0 ? void 0 : option.label);
        onChange(option === null || option === void 0 ? void 0 : option.value);
    };
    var renderFilterOptions = function (options, params) {
        var filtered = filter(options, params);
        var inputValue = params.inputValue;
        var isExisting = options.some(function (option) { return (0, utils_1.areEqualCaseInsensitive)(inputValue, option.label); });
        if (inputValue && !isExisting) {
            filtered.push({
                value: inputValue,
                label: "".concat(String(t({ key: 'CREATE_NEW' })), " \"").concat(inputValue, "\""),
            });
        }
        return filtered;
    };
    var getSelectionLabel = function (selected) { var _a; return (_a = options.find(function (option) { return option.value === selected; })) === null || _a === void 0 ? void 0 : _a.label; };
    var derivedInputValue = inputValue || (selected && getSelectionLabel(selected)) || '';
    return (react_1.default.createElement(autocomplete_selector_styles_1.StyledAutocompleteSelector, { background: ui_components_1.baseColors.white, InputProps: { placeholder: placeholder }, onChange: handleChange, onInputChange: handleInputChange, inputValue: derivedInputValue, options: options, value: selected !== null && selected !== void 0 ? selected : '', variant: 'outlined', selectOnFocus: true, groupBy: groupBy, className: className, error: error, errorMsg: errorMessage, onBlur: onBlur, loading: isLoading, filterOptions: allowCreation ? renderFilterOptions : undefined, noOptionsText: noOptionsText }));
};
exports.AutocompleteSelector = AutocompleteSelector;
exports.default = exports.AutocompleteSelector;
