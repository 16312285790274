"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a, _b, _c, _d, _e;
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAggregatedAttributesFilter = exports.getStrategyAttributesProductFilter = exports.getStrategyAttributesExcludingDefaultFilter = exports.sortingItemToPropName = exports.sortingItemToLocalSortMap = exports.sortingItemToStrategyAttributesSortMap = exports.sortingItemToProductAttributesSortMap = exports.sortingItemToAggregatedAttributesSortMap = void 0;
var graphql_1 = require("../../../../__generated__/graphql");
var ramda_1 = require("ramda");
var attributes_constants_1 = require("./attributes.constants");
var models_1 = require("../../../models");
var utils_1 = require("../../../utils");
exports.sortingItemToAggregatedAttributesSortMap = (_a = {},
    _a[models_1.SortingItem.SortByTextAsc] = { label: graphql_1.SortOrder.Asc },
    _a[models_1.SortingItem.SortByTextDesc] = { label: graphql_1.SortOrder.Desc },
    _a[models_1.SortingItem.SortByProductCountsAsc] = { productCount: graphql_1.SortOrder.Asc },
    _a[models_1.SortingItem.SortByProductCountsDesc] = { productCount: graphql_1.SortOrder.Desc },
    _a);
exports.sortingItemToProductAttributesSortMap = (_b = {},
    _b[models_1.SortingItem.SortByTextAsc] = [{ label: graphql_1.OrderBy.Asc }],
    _b[models_1.SortingItem.SortByTextDesc] = [{ label: graphql_1.OrderBy.Desc }],
    _b[models_1.SortingItem.SortByProductCountsAsc] = [{ productCount: graphql_1.OrderBy.Asc }],
    _b[models_1.SortingItem.SortByProductCountsDesc] = [{ productCount: graphql_1.OrderBy.Desc }],
    _b);
exports.sortingItemToStrategyAttributesSortMap = (_c = {},
    _c[models_1.SortingItem.SortByTextAsc] = [{ label: graphql_1.OrderBy.Asc }],
    _c[models_1.SortingItem.SortByTextDesc] = [{ label: graphql_1.OrderBy.Desc }],
    _c[models_1.SortingItem.SortByProductCountsAsc] = [{ label: graphql_1.OrderBy.Asc }],
    _c[models_1.SortingItem.SortByProductCountsDesc] = [{ label: graphql_1.OrderBy.Asc }],
    _c);
exports.sortingItemToLocalSortMap = (_d = {},
    _d[models_1.SortingItem.SortByTextAsc] = (0, utils_1.sortByTextFieldCaseInsensitive)(utils_1.SortOrder.Ascend),
    _d[models_1.SortingItem.SortByTextDesc] = (0, utils_1.sortByTextFieldCaseInsensitive)(utils_1.SortOrder.Descend),
    _d[models_1.SortingItem.SortByProductCountsAsc] = (0, utils_1.sortByNumberField)(utils_1.SortOrder.Ascend),
    _d[models_1.SortingItem.SortByProductCountsDesc] = (0, utils_1.sortByNumberField)(utils_1.SortOrder.Descend),
    _d);
exports.sortingItemToPropName = (_e = {},
    _e[models_1.SortingItem.SortByTextAsc] = 'label',
    _e[models_1.SortingItem.SortByTextDesc] = 'label',
    _e[models_1.SortingItem.SortByProductCountsAsc] = 'productCount',
    _e[models_1.SortingItem.SortByProductCountsDesc] = 'productCount',
    _e);
var getStrategyAttributesExcludingDefaultFilter = function () { return ({
    some: {
        label: {
            notIn: attributes_constants_1.DEFAULT_CATEGORY_ENRICHED_FIELDS,
        },
    },
}); };
exports.getStrategyAttributesExcludingDefaultFilter = getStrategyAttributesExcludingDefaultFilter;
var getStrategyAttributesProductFilter = function (filter) {
    var _a;
    return (__assign(__assign({}, filter), { some: __assign(__assign({}, filter === null || filter === void 0 ? void 0 : filter.some), { label: __assign({}, (_a = filter === null || filter === void 0 ? void 0 : filter.some) === null || _a === void 0 ? void 0 : _a.label) }) }));
};
exports.getStrategyAttributesProductFilter = getStrategyAttributesProductFilter;
var getAggregatedAttributesFilter = function (filter, isRequirement) {
    var _a, _b;
    if (isRequirement === void 0) { isRequirement = false; }
    return (__assign(__assign({ 
        // include "In Strategy" attributes that are not associated with any products (`productCount = 0`).
        isRequirement: isRequirement, includeNA: true }, filter), { label: __assign(__assign({}, filter === null || filter === void 0 ? void 0 : filter.label), { notIn: (0, ramda_1.uniq)(__spreadArray(__spreadArray([], __read(attributes_constants_1.DEFAULT_CATEGORY_ENRICHED_FIELDS), false), __read(((_b = (_a = filter === null || filter === void 0 ? void 0 : filter.label) === null || _a === void 0 ? void 0 : _a.notIn) !== null && _b !== void 0 ? _b : [])), false)) }) }));
};
exports.getAggregatedAttributesFilter = getAggregatedAttributesFilter;
