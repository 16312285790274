"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useColumnMultiSelectFilterConfig = void 0;
var react_1 = require("react");
var use_strategy_values_short_list_hook_1 = require("../client/use-strategy-values-short-list.hook");
var client_constants_1 = require("../../../client/client.constants");
var product_table_dynamic_columns_1 = require("../../../hooks/product-table-dynamic-columns");
var models_1 = require("../../../models");
var useColumnMultiSelectFilterConfig = function () {
    var _a = (0, use_strategy_values_short_list_hook_1.useGetStrategyValuesShortList)({ pageSize: client_constants_1.REQUEST_LIMIT_MAX, sortingItem: models_1.SortingItem.SortByTextAsc, options: { fetchPolicy: 'cache-first' } }), getStrategyValuesShortList = _a.getStrategyValuesShortList, isLoading = _a.isLoading;
    var getFilterConfig = (0, react_1.useCallback)(function (_a) {
        var id = _a.id;
        return ({
            key: id,
            searchable: true,
            get: function (filters) { var _a, _b; return (((_b = (_a = filters === null || filters === void 0 ? void 0 : filters.includes) === null || _a === void 0 ? void 0 : _a[id]) === null || _b === void 0 ? void 0 : _b.list) || []); },
            loadOptions: function () { return getStrategyValuesShortList((0, product_table_dynamic_columns_1.parseColumnId)(id).label); },
            areOptionsLoading: isLoading,
            set: function (value) { return ({ field: id, list: value }); },
        });
    }, [isLoading, getStrategyValuesShortList]);
    return getFilterConfig;
};
exports.useColumnMultiSelectFilterConfig = useColumnMultiSelectFilterConfig;
