"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGetStrategyValuesShortList = void 0;
var react_1 = require("react");
var client_1 = require("@apollo/client");
var get_strategy_values_short_list_query_1 = require("./get-strategy-values-short-list.query");
var client_utils_1 = require("../../../client/client.utils");
var values_utils_1 = require("../../../client/values/values.utils");
var store_1 = require("../../../../store");
var useGetStrategyValuesShortList = function (_a) {
    var pageSize = _a.pageSize, sortingItem = _a.sortingItem, options = _a.options;
    var productTreeNodeId = (0, store_1.useSelectedTreeNodeId)();
    var sort = values_utils_1.sortingItemToStrategyValuesSortMap[sortingItem];
    var _b = __read((0, client_1.useLazyQuery)(get_strategy_values_short_list_query_1.GET_STRATEGY_VALUES_SHORT_LIST, {
        variables: {
            productTreeNodeId: productTreeNodeId,
            sort: sort,
            label: '',
            first: pageSize,
        },
        fetchPolicy: options === null || options === void 0 ? void 0 : options.fetchPolicy,
    }), 2), getStrategyValuesShortList = _b[0], _c = _b[1], data = _c.data, previousData = _c.previousData, networkStatus = _c.networkStatus, restData = __rest(_c, ["data", "previousData", "networkStatus"]);
    var loadStrategyValuesShortList = (0, react_1.useCallback)(function (attributeId) { return __awaiter(void 0, void 0, void 0, function () {
        var response, e_1;
        var _a, _b, _c, _d, _e, _f, _g, _h;
        return __generator(this, function (_j) {
            switch (_j.label) {
                case 0:
                    _j.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, getStrategyValuesShortList({ variables: { label: attributeId, productTreeNodeId: productTreeNodeId } })];
                case 1:
                    response = _j.sent();
                    (0, client_utils_1.genericGraphQLErrorsHandler)(response);
                    return [2 /*return*/, (_h = (_g = (_f = (_e = (_d = (_c = (_b = (_a = response.data) === null || _a === void 0 ? void 0 : _a.productTreeNode) === null || _b === void 0 ? void 0 : _b.labelValidation) === null || _c === void 0 ? void 0 : _c.edges) === null || _d === void 0 ? void 0 : _d[0]) === null || _e === void 0 ? void 0 : _e.node) === null || _f === void 0 ? void 0 : _f.values) === null || _g === void 0 ? void 0 : _g.edges) === null || _h === void 0 ? void 0 : _h.map(function (_a) {
                            var node = _a.node;
                            return ({ label: node.value, value: node.value });
                        })];
                case 2:
                    e_1 = _j.sent();
                    return [2 /*return*/, []];
                case 3: return [2 /*return*/];
            }
        });
    }); }, [productTreeNodeId, getStrategyValuesShortList]);
    return __assign(__assign({}, restData), { getStrategyValuesShortList: loadStrategyValuesShortList, isLoading: (0, client_utils_1.isQueryLoadingOrRefetching)(networkStatus), data: data });
};
exports.useGetStrategyValuesShortList = useGetStrategyValuesShortList;
