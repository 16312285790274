"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useComments = exports.sortCommentsByDate = void 0;
var react_1 = require("react");
var sort_1 = require("@groupby/enrich-sdk/sort");
var predicate_language_1 = require("@groupby/predicate-language");
var ramda_1 = require("ramda");
var hooks_1 = require("../../../../../../hooks");
var localization_1 = require("../../../../../../localization");
var sortCommentsByDate = function (a, b) { return a.createdAt.valueOf() - b.createdAt.valueOf(); };
exports.sortCommentsByDate = sortCommentsByDate;
var useComments = function (_a) {
    var productId = _a.productId;
    var t = (0, localization_1.useLocalization)().formatMessage;
    var client = (0, hooks_1.useEnrichClient)();
    var addError = (0, hooks_1.useNotification)().addError;
    var token = (0, hooks_1.useToken)();
    var _b = __read((0, react_1.useState)([]), 2), comments = _b[0], setComments = _b[1];
    var _c = __read((0, react_1.useState)(false), 2), isLoading = _c[0], setIsLoading = _c[1];
    var _d = __read((0, react_1.useState)(sort_1.SortDirection.Ascending), 2), sortOrder = _d[0], setSortOrder = _d[1];
    var _e = __read((0, react_1.useState)(false), 2), isSubmitting = _e[0], setIsSubmitting = _e[1];
    var lastCreatedId = (0, react_1.useRef)(null);
    (0, react_1.useEffect)(function () {
        var loadComments = function () { return __awaiter(void 0, void 0, void 0, function () {
            var loadedComments, sortedComments, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setIsLoading(true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, client.comments.getMany({ filter: (0, predicate_language_1.where)((0, predicate_language_1.f)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["subjectIds"], ["subjectIds"]))), '@>', [(0, predicate_language_1.uuid)(productId)]) })];
                    case 2:
                        loadedComments = _a.sent();
                        sortedComments = loadedComments.sort(exports.sortCommentsByDate);
                        setComments(sortedComments);
                        return [3 /*break*/, 5];
                    case 3:
                        e_1 = _a.sent();
                        addError(t({ key: 'COMMENT_LOADING_ERROR' }));
                        return [3 /*break*/, 5];
                    case 4:
                        setIsLoading(false);
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        if (token) {
            void loadComments();
        }
    }, [addError, client, productId, t, token]);
    var createComment = (0, react_1.useCallback)(function (comment) { return __awaiter(void 0, void 0, void 0, function () {
        var newComment_1, e_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsSubmitting(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    return [4 /*yield*/, client.comments.createOne({ content: comment, subjectIds: [productId] })];
                case 2:
                    newComment_1 = _a.sent();
                    lastCreatedId.current = newComment_1.id;
                    setComments(function (commentList) { return (sortOrder === sort_1.SortDirection.Ascending ? __spreadArray(__spreadArray([], __read(commentList), false), [newComment_1], false) : __spreadArray([newComment_1], __read(commentList), false)); });
                    return [2 /*return*/, newComment_1];
                case 3:
                    e_2 = _a.sent();
                    addError(t({ key: 'COMMENT_CREATE_ERROR' }));
                    throw new Error(e_2.message);
                case 4:
                    setIsSubmitting(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); }, [addError, client, comments, productId, sortOrder, t]);
    var toggleCommentsSorting = (0, react_1.useCallback)(function () {
        setSortOrder(function (order) { return (order === sort_1.SortDirection.Ascending ? sort_1.SortDirection.Descending : sort_1.SortDirection.Ascending); });
        setComments(function (prevComments) { return (0, ramda_1.reverse)(prevComments); });
    }, []);
    return {
        comments: comments,
        isLoading: isLoading,
        sortOrder: sortOrder,
        isSubmitting: isSubmitting,
        lastCreatedId: lastCreatedId.current,
        toggleCommentsSorting: toggleCommentsSorting,
        createComment: createComment,
    };
};
exports.useComments = useComments;
var templateObject_1;
