"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PreviewPanelPagination = void 0;
var react_1 = __importStar(require("react"));
var ui_components_1 = require("@groupby/ui-components");
var ramda_1 = require("ramda");
var styled_components_1 = require("styled-components");
var pagination_styles_1 = require("./pagination.styles");
var client_constants_1 = require("../../../client/client.constants");
var pagination_utils_1 = require("../../../../generic/pagination/pagination.utils");
var localization_1 = require("../../../../localization");
var MAX_PAGES_DISPLAY = 10;
var PreviewPanelPagination = function (_a) {
    var _b;
    var itemsCount = _a.itemsCount, pageNumber = _a.pageNumber, pageSize = _a.pageSize, onPageChange = _a.onPageChange, onPageSizeChange = _a.onPageSizeChange;
    var t = (0, localization_1.useLocalization)().formatMessage;
    var palette = (0, styled_components_1.useTheme)().palette;
    var _c = (0, pagination_utils_1.getPaginationValues)({ itemsCount: itemsCount, pageNumber: pageNumber, pageSize: pageSize }), pagesCount = _c.pagesCount, startRange = _c.startRange, endRange = _c.endRange;
    var _d = __read((0, react_1.useState)(null), 2), anchor = _d[0], setAnchor = _d[1];
    var isValidPageNumber = pageNumber > 0 && pageNumber <= pagesCount;
    var getArrowColor = function (canClick) { return (canClick ? ui_components_1.baseColors.primaryGreen500 : ui_components_1.baseColors.primaryBlueMed200); };
    var handleCloseMenu = function () {
        setAnchor(null);
    };
    var updatePage = function (pageNumber) {
        var pageNumberValue = Number(pageNumber);
        if (pageNumberValue > 0) {
            onPageChange(pageNumberValue);
        }
    };
    var handleChange = function (pageNumber) { return function () {
        handleCloseMenu();
        updatePage(pageNumber);
    }; };
    var pageNumbers = pageNumber <= MAX_PAGES_DISPLAY || !isValidPageNumber
        ? (0, ramda_1.range)(1, Math.min(MAX_PAGES_DISPLAY + 1, pagesCount + 1))
        : pageNumber > (pagesCount - MAX_PAGES_DISPLAY)
            ? (0, ramda_1.range)(pagesCount - MAX_PAGES_DISPLAY + 1, pagesCount + 1)
            : (_b = (0, ramda_1.range)(pageNumber - (MAX_PAGES_DISPLAY / 2), Math.min(pageNumber + (MAX_PAGES_DISPLAY / 2), pagesCount + 1))) !== null && _b !== void 0 ? _b : null;
    var canClickPreviousPage = pageNumber - 1 >= 1;
    var handlePreviousPageClick = function () {
        if (!canClickPreviousPage)
            return;
        updatePage(pageNumber - 1);
    };
    var canClickNextPage = pageNumber + 1 <= pagesCount;
    var handleNextPageClick = function () {
        if (!canClickNextPage)
            return;
        updatePage(pageNumber + 1);
    };
    var handlePageSizeChange = function (e) { return onPageSizeChange(Number(e.target.value)); };
    var paginationCurrentRangeLabel = t({
        key: 'PAGINATION_CURRENT_RANGE_LABEL',
        values: {
            startRange: startRange,
            endRange: endRange,
            total: itemsCount,
            type: t({ key: 'PRODUCTS' }),
        },
    });
    var renderMenu = pageNumbers && (react_1.default.createElement(ui_components_1.MenuList, { getContentAnchorEl: null, anchorEl: anchor, anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
        }, transformOrigin: {
            vertical: 'top',
            horizontal: 'right',
        }, keepMounted: true, open: Boolean(anchor), onClose: handleCloseMenu }, pageNumbers.map(function (pageNum) { return (react_1.default.createElement(ui_components_1.MenuItem, { key: pageNum, value: pageNum, onClick: handleChange(pageNum) }, pageNum)); })));
    var options = (0, react_1.useMemo)(function () { return (client_constants_1.PAGE_SIZES.map(function (key) { return (react_1.default.createElement(ui_components_1.MenuItem, { key: key, value: key }, key)); })); }, []);
    return (react_1.default.createElement(pagination_styles_1.StyledPaginationWrapper, null,
        renderMenu,
        react_1.default.createElement(pagination_styles_1.StyledPageSelectorWrapper, null,
            react_1.default.createElement(ui_components_1.Typography, { color: "textSecondary" }, "".concat(t({ key: 'REF_APP_PAGINATION_PER_PAGE' }), ":")),
            react_1.default.createElement(pagination_styles_1.StyledPageSizeSelect, { variant: "outlined", value: pageSize, onChange: handlePageSizeChange, IconComponent: function (iconComponentProps) { return (react_1.default.createElement(pagination_styles_1.StyledIcon, __assign({ variant: "chevronDown", color: palette.primary.main }, iconComponentProps))); } }, options),
            react_1.default.createElement(pagination_styles_1.StyledDivider, null),
            react_1.default.createElement(ui_components_1.Typography, { variant: "body2", color: "textSecondary" }, isValidPageNumber && (paginationCurrentRangeLabel || "".concat(startRange, " - ").concat(endRange)))),
        react_1.default.createElement(pagination_styles_1.StyledFlexWrapper, null,
            react_1.default.createElement(pagination_styles_1.StyledPageSelectorWrapper, null,
                react_1.default.createElement(pagination_styles_1.StyledPaginationCount, { variant: "body2", color: "textSecondary" },
                    pageNumber,
                    "\u00A0",
                    t({ key: 'PAGINATION_COUNT_LABEL', values: { numPages: pagesCount } }))),
            react_1.default.createElement(pagination_styles_1.StyledDivider, null),
            react_1.default.createElement(pagination_styles_1.StyledFlexWrapper, null,
                react_1.default.createElement(ui_components_1.Icon, { variant: "arrowPaginationPrevious", showHover: canClickPreviousPage, color: getArrowColor(canClickPreviousPage), disabled: !canClickPreviousPage, onClick: handlePreviousPageClick }),
                react_1.default.createElement(ui_components_1.Icon, { variant: "arrowPaginationNext", showHover: canClickNextPage, color: getArrowColor(canClickNextPage), disabled: !canClickNextPage, onClick: handleNextPageClick })))));
};
exports.PreviewPanelPagination = PreviewPanelPagination;
