"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductDetailsDialog = void 0;
var react_1 = __importStar(require("react"));
var ui_components_1 = require("@groupby/ui-components");
var icons_material_1 = require("@mui/icons-material");
var material_1 = require("@mui/material");
var ramda_1 = require("ramda");
var product_details_attributes_component_1 = require("./product-details-attributes/product-details-attributes.component");
var product_details_dialog_styles_1 = require("./product-details-dialog.styles");
var product_details_dialog_utils_1 = require("./product-details-dialog.utils");
var product_details_info_component_1 = require("./product-details-info/product-details-info.component");
var attributes_1 = require("../../client/attributes");
var product_1 = require("../../client/product");
var product_tile_image_component_1 = require("../../components/product-tile/partials/product-tile-image.component");
var utils_1 = require("../../utils");
var feature_flags_1 = require("../../../feature-flags");
var hooks_1 = require("../../../hooks");
var localization_1 = require("../../../localization");
var store_1 = require("../../../store");
var ProductDetailsDialog = function (_a) {
    var _b;
    var selectedProductId = _a.selectedProductId, onClose = _a.onClose, onNext = _a.onNext, onPrevious = _a.onPrevious, onReclassify = _a.onReclassify;
    var t = (0, localization_1.useLocalization)().formatMessage;
    var featureFlagsService = (0, hooks_1.useFeatureFlagsService)().featureFlagsService;
    var isMeasureEnabled = featureFlagsService.isFeatureEnabled(feature_flags_1.FeatureFlag.Measures);
    var productTreeId = (0, store_1.useProductTreeId)();
    var selectedCatalogId = (0, store_1.useSelectedCatalogId)();
    var _c = (0, product_1.useProductTreeNodePresentation)({
        productTreeId: productTreeId,
        catalogId: selectedCatalogId,
        productId: selectedProductId,
        skipMeasures: !isMeasureEnabled,
    }), data = _c.data, loading = _c.loading;
    var _d = data !== null && data !== void 0 ? data : { product: {} }, _e = _d.product, id = _e.id, labels = _e.labels, measures = _e.measures, name = _e.name, product = __rest(_e, ["id", "labels", "measures", "name"]), productPresentationLabels = _d.labels, productPresentationMeasures = _d.measures, productTreeNode = _d.productTreeNode;
    var productPresentationAttributes = (0, react_1.useMemo)(function () { return (0, product_details_dialog_utils_1.mergeTextAndMeasureProductPresentationAttributes)(productPresentationLabels, productPresentationMeasures); }, [productPresentationLabels, productPresentationMeasures]);
    var productAttributeValuePairs = (0, react_1.useMemo)(function () { return (0, product_details_dialog_utils_1.mergeTextAndMeasureProductAttributeValuePairs)(labels, measures); }, [labels, measures]);
    var baseData = (0, react_1.useMemo)(function () { return (0, product_details_dialog_utils_1.getBaseDataFromProductAttributeValuePairs)(product, productAttributeValuePairs); }, [productAttributeValuePairs, product]);
    var images = (_b = productAttributeValuePairs
        .filter(function (label) { return label.label === attributes_1.PRODUCT_IMAGE_LABEL; })
        .map(function (_a) {
        var value = _a.value;
        return String(value);
    })) !== null && _b !== void 0 ? _b : [];
    var strategyAttributes = (0, react_1.useMemo)(function () { var _a, _b; return (_b = (_a = productTreeNode === null || productTreeNode === void 0 ? void 0 : productTreeNode.labelValidation.edges) === null || _a === void 0 ? void 0 : _a.map(function (_a) {
        var node = _a.node;
        return node.label;
    })) !== null && _b !== void 0 ? _b : []; }, [productTreeNode]);
    var mergedProductAttributes = (0, react_1.useMemo)(function () {
        var labelList = productPresentationAttributes.filter(function (_a) {
            var validValues = _a.validValues;
            return !!validValues.length;
        }).map(function (_a) {
            var label = _a.label;
            return label;
        });
        var filteredStrategyAttributes = (0, ramda_1.without)(labelList, strategyAttributes);
        var mergedList = __spreadArray(__spreadArray([], __read(productAttributeValuePairs), false), __read(filteredStrategyAttributes.map(function (label) { return ({
            label: label,
            value: attributes_1.SpecialAttributeValue.Unknown,
            origin: null,
        }); })), false);
        return (0, utils_1.sortByTextFieldCaseInsensitive)(utils_1.SortOrder.Ascend)(mergedList, 'label');
    }, [productAttributeValuePairs, productPresentationAttributes, strategyAttributes]);
    var imagesTiles = images.map(function (image) { return (react_1.default.createElement(product_details_dialog_styles_1.StyledProductImageWrapper, { key: image },
        react_1.default.createElement(product_tile_image_component_1.ProductTileImage, { image: image }))); });
    return (react_1.default.createElement(product_details_dialog_styles_1.StyledDialog, { maxWidth: 'md', fullWidth: true, onClose: onClose, title: t({ key: 'EDIT_PRODUCT_DETAILS' }) },
        react_1.default.createElement(product_details_dialog_styles_1.StyledDialogContentWrapper, null,
            react_1.default.createElement(product_details_dialog_styles_1.StyledProductDetailsWrapper, null,
                !!images.length && (react_1.default.createElement(ui_components_1.Carousel, { perView: 1, tiles: imagesTiles })),
                react_1.default.createElement(product_details_info_component_1.ProductDetailsInfo, { data: baseData, onReclassify: onReclassify })),
            react_1.default.createElement(product_details_dialog_styles_1.StyledProductDetailsAttributesWrapper, null,
                react_1.default.createElement(product_details_attributes_component_1.ProductDetailsAttributes, { productId: id, productAttributeValuePairs: mergedProductAttributes, productPresentationAttributes: productPresentationAttributes }))),
        react_1.default.createElement(material_1.DialogActions, { className: 'switch-product-buttons' },
            react_1.default.createElement(ui_components_1.Button, { disabled: loading, onClick: onPrevious, rounded: true },
                react_1.default.createElement(icons_material_1.ChevronLeft, null),
                t({ key: 'PREVIOUS' })),
            react_1.default.createElement(ui_components_1.Button, { disabled: loading, onClick: onNext, rounded: true },
                t({ key: 'NEXT' }),
                react_1.default.createElement(icons_material_1.ChevronRight, null)))));
};
exports.ProductDetailsDialog = ProductDetailsDialog;
