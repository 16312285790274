"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReclassifyProductsDialog = void 0;
var react_1 = __importStar(require("react"));
var ui_components_1 = require("@groupby/ui-components");
var product_tree_node_search_1 = require("./product-tree-node-search");
var reclassify_products_dialog_styles_1 = require("./reclassify-products-dialog.styles");
var use_reclassify_hook_1 = require("../hooks/use-reclassify.hook");
var components_1 = require("../../../components");
var generic_1 = require("../../../generic");
var localization_1 = require("../../../../localization");
var localization_utils_1 = require("../../../../localization/localization.utils");
var ReclassifyProductsDialog = function (_a) {
    var productFilter = _a.productFilter, selectedItemsCount = _a.selectedItemsCount, onSuccess = _a.onSuccess, onClose = _a.onClose;
    var t = (0, localization_1.useLocalization)().formatMessage;
    var _b = __read((0, react_1.useState)(null), 2), selectedNodeTitle = _b[0], setSelectedNodeTitle = _b[1];
    var _c = (0, use_reclassify_hook_1.useReclassify)({ onReclassificationComplete: onSuccess }), reclassify = _c.reclassify, isLoading = _c.isLoading, error = _c.error;
    var handleSubmit = function () {
        reclassify({
            filter: productFilter,
            targetCategory: selectedNodeTitle,
        });
    };
    return (react_1.default.createElement(reclassify_products_dialog_styles_1.StyledDialog, { maxWidth: 'md', fullWidth: true, proceedButtonProps: {
            title: t({ key: 'APPLY' }),
            disabled: !selectedNodeTitle || isLoading,
            onClick: handleSubmit,
            endIcon: isLoading && react_1.default.createElement(generic_1.LoadingIndicator, null),
        }, cancelButtonProps: {
            title: t({ key: 'CANCEL' }),
            color: 'default',
            onClick: function () {
                onClose();
            },
        }, title: t({ key: 'PRODUCT_RECLASSIFY_TITLE' }) },
        react_1.default.createElement("div", null, selectedItemsCount && (react_1.default.createElement(ui_components_1.Typography, { className: 'product-count', variant: 'body2', color: 'textSecondary', cl: true }, t({
            key: 'PRODUCT_RECLASSIFY_TOTAL',
            values: { total: selectedItemsCount, highlight: localization_utils_1.renderBoldText },
        })))),
        error && react_1.default.createElement(components_1.Alert, { severity: "error" }, error),
        react_1.default.createElement(product_tree_node_search_1.ProductTreeNodeSearch, { selectedNodeTitle: selectedNodeTitle, onSelect: setSelectedNodeTitle })));
};
exports.ReclassifyProductsDialog = ReclassifyProductsDialog;
