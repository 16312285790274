"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGetProducts = void 0;
var react_1 = require("react");
var client_1 = require("@apollo/client");
var graphql_1 = require("../../../../__generated__/graphql");
var client_utils_1 = require("../../client.utils");
var pagination_1 = require("../../hooks/pagination");
var get_products_query_1 = require("../gql/get-products.query");
var defaultSort = { product: { lastAttributeUpdatedAt: graphql_1.OrderBy.Desc } };
var useGetProducts = function (_a) {
    var _b, _c, _d, _e;
    var catalogId = _a.catalogId, productTreeNodeId = _a.productTreeNodeId, options = _a.options, onCompleted = _a.onCompleted, filter = _a.filter, productTextAttributesFilter = _a.productTextAttributesFilter, productMeasuresFilter = _a.productMeasuresFilter, skipMeasures = _a.skipMeasures;
    var _f = __read((0, pagination_1.usePageSize)(), 2), pageSize = _f[0], setPageSize = _f[1];
    var _g = (0, client_1.useQuery)(get_products_query_1.GET_PRODUCTS, {
        skip: !catalogId || !productTreeNodeId || (options === null || options === void 0 ? void 0 : options.skip),
        variables: {
            catalogId: catalogId,
            productTreeNodeId: productTreeNodeId,
            filter: filter,
            labelFilter: productTextAttributesFilter,
            measureFilter: productMeasuresFilter,
            first: pageSize,
            before: null,
            after: null,
            last: null,
            skipMeasures: skipMeasures,
            sort: defaultSort,
        },
        onCompleted: onCompleted,
    }), data = _g.data, variables = _g.variables, previousData = _g.previousData, loading = _g.loading, error = _g.error, networkStatus = _g.networkStatus, fetchMore = _g.fetchMore;
    var productPresentations = (0, react_1.useMemo)(function () { var _a; return (_a = data === null || data === void 0 ? void 0 : data.productTreeNode) === null || _a === void 0 ? void 0 : _a.productPresentationsNew; }, [data]);
    var _h = ((_c = (_b = previousData === null || previousData === void 0 ? void 0 : previousData.productTreeNode) === null || _b === void 0 ? void 0 : _b.productPresentationsNew) !== null && _c !== void 0 ? _c : {}).totalCount, prevTotalCount = _h === void 0 ? 0 : _h;
    var _j = productPresentations !== null && productPresentations !== void 0 ? productPresentations : {}, _k = _j.totalCount, totalCount = _k === void 0 ? 0 : _k, pageInfo = _j.pageInfo, _l = _j.edges, edges = _l === void 0 ? [] : _l;
    var products = (0, react_1.useMemo)(function () { return edges.map(function (_a) {
        var node = _a.node;
        return node.product;
    }); }, [edges]);
    var pagination = (0, pagination_1.usePagination)({
        pageInfo: pageInfo,
        totalCount: totalCount,
        networkStatus: networkStatus,
        pageSize: pageSize,
        fetchMore: fetchMore,
        lastVariables: variables,
    });
    return {
        pagination: __assign(__assign({}, pagination), { totalCount: loading ? totalCount || prevTotalCount : totalCount, pageSize: pageSize, onPageSizeChange: setPageSize }),
        isLoading: (0, client_utils_1.isQueryLoading)(networkStatus),
        error: error,
        nodeTitle: ((_e = (_d = (data !== null && data !== void 0 ? data : previousData)) === null || _d === void 0 ? void 0 : _d.productTreeNode) === null || _e === void 0 ? void 0 : _e.title) || '',
        products: products,
    };
};
exports.useGetProducts = useGetProducts;
