"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.getProductMeasuresFilter = exports.getProductTextAttributesFilter = exports.getProductListFilter = exports.productFieldsToFilterMap = exports.createProductAttributesMeasuresFilter = exports.createProductOriginalAttributesFilter = exports.createProductPresentationAttributesFilter = exports.createProductCategoryFilter = exports.createCollaboratorFilter = exports.createDateTimeFilter = exports.createStringFilter = void 0;
var graphql_1 = require("../../../../__generated__/graphql");
var ui_components_1 = require("@groupby/ui-components");
var ramda_1 = require("ramda");
var preview_products_widget_constants_1 = require("../../preview-products-widget.constants");
var product_table_dynamic_columns_1 = require("../../../hooks/product-table-dynamic-columns");
var createStringFilter = function (_a) {
    var search = _a.search, match = _a.match;
    return (__assign({ mode: graphql_1.StringFilterMode.Insensitive }, (match === ui_components_1.MatchOptions.Include ? {
        contains: search,
    } : {
        not: {
            contains: search,
        },
    })));
};
exports.createStringFilter = createStringFilter;
var createDateTimeFilter = function (_a) {
    var startAt = _a.startAt, endAt = _a.endAt;
    return (__assign(__assign({}, startAt && { gte: new Date(startAt).toISOString() }), endAt && { lte: new Date(endAt).toISOString() }));
};
exports.createDateTimeFilter = createDateTimeFilter;
var createCollaboratorFilter = function (filter) { return ({
    name: (0, exports.createStringFilter)(filter),
}); };
exports.createCollaboratorFilter = createCollaboratorFilter;
var createProductCategoryFilter = function (filter) { return ({
    categories: {
        some: {
            category: (0, exports.createStringFilter)(filter),
        },
    },
}); };
exports.createProductCategoryFilter = createProductCategoryFilter;
// enriched data - text attributes
var createProductPresentationAttributesFilter = function (attributeName, filter) { return ({
    labels: {
        some: {
            label: {
                equals: attributeName,
            },
            status: {
                in: [graphql_1.ProductAttributeStatus.InStrategy, graphql_1.ProductAttributeStatus.Valid],
            },
            value: {
                in: filter.list,
            },
        },
    },
}); };
exports.createProductPresentationAttributesFilter = createProductPresentationAttributesFilter;
// original data - attributes from original catalog
var createProductOriginalAttributesFilter = function (attributeName, filter) { return ({
    labels: {
        some: {
            label: {
                equals: attributeName,
            },
            origin: {
                in: [graphql_1.ProductAttributeOrigin.Original],
            },
            value: (0, exports.createStringFilter)(filter),
        },
    },
}); };
exports.createProductOriginalAttributesFilter = createProductOriginalAttributesFilter;
// measures
var createProductAttributesMeasuresFilter = function (attributeName, _a) {
    var low = _a.low, high = _a.high;
    return ({
        measures: {
            some: {
                dimension: {
                    equals: attributeName,
                },
                value: __assign(__assign({}, low && { gte: Number(low) }), high && { lte: Number(high) }),
            },
        },
    });
};
exports.createProductAttributesMeasuresFilter = createProductAttributesMeasuresFilter;
exports.productFieldsToFilterMap = (_a = {},
    _a[preview_products_widget_constants_1.CustomProductFields.ExternalId] = exports.createStringFilter,
    _a[preview_products_widget_constants_1.CustomProductFields.Name] = exports.createStringFilter,
    _a[preview_products_widget_constants_1.CustomProductFields.CreatedAt] = exports.createDateTimeFilter,
    _a[preview_products_widget_constants_1.CustomProductFields.LastAttributeUpdatedAt] = exports.createDateTimeFilter,
    _a[preview_products_widget_constants_1.CustomProductFields.LastEditedBy] = exports.createCollaboratorFilter,
    _a[preview_products_widget_constants_1.CustomProductFields.ClassificationProductType] = exports.createProductCategoryFilter,
    _a);
var getProductListFilter = function (tableFilter) {
    var mergedFilter = {};
    var productFilter = {};
    var productAttributesFilter = [];
    Object.keys(tableFilter).forEach(function (columnId) {
        var _a;
        var _b = tableFilter[columnId], type = _b.type, columnFilter = __rest(_b, ["type"]);
        var _c = (0, product_table_dynamic_columns_1.parseColumnId)(columnId), fieldKey = _c.label, _d = _c.dataMeta, isMeasure = _d.isMeasure, isAIEnriched = _d.isAIEnriched;
        if (Object.values(preview_products_widget_constants_1.CustomProductFields).includes(fieldKey)) {
            if (fieldKey === preview_products_widget_constants_1.CustomProductFields.ClassificationProductType) {
                productAttributesFilter.push((0, exports.createProductCategoryFilter)(columnFilter));
            }
            else if ([
                preview_products_widget_constants_1.CustomProductFields.LastAttributeUpdatedAt,
                preview_products_widget_constants_1.CustomProductFields.LastEditedBy,
            ].includes(fieldKey)) {
                var filterFunc = exports.productFieldsToFilterMap[fieldKey];
                productFilter.productMetadata = __assign(__assign({}, productFilter.productMetadata), (_a = {}, _a[fieldKey] = filterFunc(columnFilter), _a));
            }
            else {
                var filterFunc = exports.productFieldsToFilterMap[fieldKey];
                productFilter[fieldKey] = filterFunc(columnFilter);
            }
        }
        else if (isMeasure) {
            productAttributesFilter.push((0, exports.createProductAttributesMeasuresFilter)(fieldKey, columnFilter));
        }
        else if (isAIEnriched) {
            productAttributesFilter.push((0, exports.createProductPresentationAttributesFilter)(fieldKey, columnFilter));
        }
        else {
            productAttributesFilter.push((0, exports.createProductOriginalAttributesFilter)(fieldKey, columnFilter));
        }
    });
    if (!(0, ramda_1.isEmpty)(productAttributesFilter)) {
        productFilter.attributes = productAttributesFilter;
    }
    if (!(0, ramda_1.isEmpty)(productFilter)) {
        mergedFilter.product = productFilter;
    }
    if ((0, ramda_1.isEmpty)(mergedFilter)) {
        return undefined;
    }
    return mergedFilter;
};
exports.getProductListFilter = getProductListFilter;
var getProductTextAttributesFilter = function (excludedColumns, selectAllMode) {
    var excludedLabelColumnsWithoutBaseFields = (0, ramda_1.without)(Object.values(preview_products_widget_constants_1.CustomProductFields), excludedColumns);
    if (!excludedLabelColumnsWithoutBaseFields.length) {
        return null;
    }
    var filters = [];
    filters[0] = {
        label: (!selectAllMode ? { in: excludedLabelColumnsWithoutBaseFields } : { notIn: excludedLabelColumnsWithoutBaseFields }),
    };
    return filters;
};
exports.getProductTextAttributesFilter = getProductTextAttributesFilter;
var getProductMeasuresFilter = function (excludedColumns, selectAllMode) {
    if (!excludedColumns.length) {
        return undefined;
    }
    return { dimension: (!selectAllMode ? { in: excludedColumns } : { notIn: excludedColumns }) };
};
exports.getProductMeasuresFilter = getProductMeasuresFilter;
