"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductsActionPanel = void 0;
var react_1 = __importStar(require("react"));
var ui_components_1 = require("@groupby/ui-components");
var material_1 = require("@mui/material");
var react_redux_1 = require("react-redux");
var products_bulk_actions_panel_1 = require("./partials/products-bulk-actions-panel");
var products_action_panel_styles_1 = require("./products-action-panel.styles");
var search_bar_1 = require("../../../search-bar");
var products_table_1 = require("../../../../store/catalogs/products-table");
var icons_1 = require("../../../../../icons");
var localization_1 = require("../../../../../localization");
var store_1 = require("../../../../../store");
var ProductsActionPanel = function (_a) {
    var isAttributesSectionVisible = _a.isAttributesSectionVisible, isListViewMode = _a.isListViewMode, _b = _a.totalCount, totalCount = _b === void 0 ? 0 : _b, isAtLeastOneProductSelected = _a.isAtLeastOneProductSelected, _c = _a.selectedItemsCount, selectedItemsCount = _c === void 0 ? 0 : _c, disableReclassification = _a.disableReclassification, toggleAttributeSectionVisibility = _a.toggleAttributeSectionVisibility, toggleViewMode = _a.toggleViewMode, onSelectCurrentPage = _a.onSelectCurrentPage, onSelectAllProducts = _a.onSelectAllProducts, areAllProductsSelected = _a.areAllProductsSelected, onEdit = _a.onEdit, onSettingsClick = _a.onSettingsClick, onReclassify = _a.onReclassify, onResetSelectedProducts = _a.onResetSelectedProducts, isCurrentPageSelected = _a.isCurrentPageSelected;
    var t = (0, localization_1.useLocalization)().formatMessage;
    var handleGridViewButtonClick = function () { return isListViewMode && toggleViewMode(); };
    var handleListViewButtonClick = function () { return !isListViewMode && toggleViewMode(); };
    var searchTerm = (0, products_table_1.useProductsTableSearch)();
    var dispatch = (0, react_redux_1.useDispatch)();
    var selectedCatalogId = (0, store_1.useSelectedCatalogId)();
    var setSearchTerm = (0, react_1.useCallback)(function (search) { return dispatch((0, products_table_1.setTableSearch)(selectedCatalogId, search)); }, [dispatch, selectedCatalogId]);
    return (react_1.default.createElement(products_action_panel_styles_1.StyledProductsActionPanelWrapper, null,
        react_1.default.createElement("div", { className: "products-action-panel" },
            react_1.default.createElement(material_1.IconButton, { size: "small", onClick: toggleAttributeSectionVisibility }, isAttributesSectionVisible
                ? react_1.default.createElement(icons_1.ResizeIcon, { stroke: ui_components_1.baseColors.primaryGreen300 })
                : react_1.default.createElement(icons_1.ReduceSizeIcon, { stroke: ui_components_1.baseColors.primaryGreen300 })),
            react_1.default.createElement(search_bar_1.SearchBar, { searchTerm: searchTerm, className: "product-preview-search-bar", onSearch: setSearchTerm }),
            react_1.default.createElement(material_1.IconButton, { size: "small", onClick: handleGridViewButtonClick },
                react_1.default.createElement(icons_1.GridViewIcon, { fill: !isListViewMode ? ui_components_1.baseColors.primaryGreen300 : 'transparent', stroke: !isListViewMode ? ui_components_1.baseColors.primaryGreen300 : 'currentColor' })),
            react_1.default.createElement(material_1.IconButton, { size: "small", onClick: handleListViewButtonClick },
                react_1.default.createElement(icons_1.ListViewIcon, { fill: isListViewMode ? ui_components_1.baseColors.primaryGreen300 : 'transparent', stroke: isListViewMode ? ui_components_1.baseColors.primaryGreen300 : 'currentColor' })),
            react_1.default.createElement(material_1.Divider, { orientation: "vertical", className: "product-preview-divider" }),
            react_1.default.createElement(material_1.IconButton, { size: "small", disabled: !isListViewMode, onClick: onSettingsClick },
                react_1.default.createElement(icons_1.SettingsIcon, null))),
        react_1.default.createElement("div", { className: 'products-bulk-action-panel' },
            react_1.default.createElement(ui_components_1.Typography, { variant: "body2", color: "textSecondary" }, t({ key: 'TOTAL_RESULTS', values: { totalCount: totalCount } })),
            !isListViewMode && (react_1.default.createElement(ui_components_1.Button, { rounded: true, color: "primary", variant: "text", onClick: onSelectCurrentPage }, t({ key: 'SELECT_ALL_ON_PAGE' })))),
        isAtLeastOneProductSelected && !isListViewMode && (react_1.default.createElement(products_bulk_actions_panel_1.ProductsBulkActionsPanel, { areAllProductsSelected: areAllProductsSelected, totalCount: totalCount, selectedItemsCount: selectedItemsCount, onResetSelectedProducts: onResetSelectedProducts, onEdit: onEdit, onSelectAllProducts: onSelectAllProducts, onSelectCurrentPage: onSelectCurrentPage, onReclassify: onReclassify, isCurrentPageSelected: isCurrentPageSelected, disableReclassification: disableReclassification }))));
};
exports.ProductsActionPanel = ProductsActionPanel;
exports.default = exports.ProductsActionPanel;
