"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStrategizeValue = void 0;
var react_1 = require("react");
var hooks_1 = require("../../../../../client/hooks");
var values_1 = require("../../../../../client/values");
var hooks_2 = require("../../../../../hooks");
var use_history_changes_hook_1 = require("../../../../../hooks/use-history-changes.hook");
var hooks_3 = require("../../../../../../hooks");
var localization_1 = require("../../../../../../localization");
var store_1 = require("../../../../../../store");
var useStrategizeValue = function (_a) {
    var attributeName = _a.attributeName;
    var _b = (0, hooks_3.useNotification)(), addSuccess = _b.addSuccess, addError = _b.addError;
    var t = (0, localization_1.useLocalization)().formatMessage;
    var addValuesToSeveralNodes = (0, values_1.useAddValuesToSeveralNodes)().addValuesToSeveralNodes;
    var selectedStrategyNodePath = (0, store_1.useSelectedTreeNodePath)();
    var handleAddNewItemsToHistory = (0, use_history_changes_hook_1.useHistoryChanges)().handleAddNewItemsToHistory;
    var refetchQueriesOnStrategyValueChange = (0, hooks_1.useRefetchQueries)().refetchQueriesOnStrategyValueChange;
    var selectedTreeNode = (0, hooks_2.useSelectedTreeNode)();
    var _c = selectedTreeNode !== null && selectedTreeNode !== void 0 ? selectedTreeNode : {}, selectedTreeNodeId = _c.id, selectedTreeNodeTitle = _c.title;
    var onStrategizeValue = (0, react_1.useCallback)(function (newValue) { return __awaiter(void 0, void 0, void 0, function () {
        var newAttributeData, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, , 4]);
                    newAttributeData = {
                        name: attributeName,
                        values: [{ name: newValue }],
                    };
                    // TODO: value will be also added to all child nodes -> find a way to update cache
                    return [4 /*yield*/, addValuesToSeveralNodes([newAttributeData], [selectedTreeNodeId], { includeDescendants: true })];
                case 1:
                    // TODO: value will be also added to all child nodes -> find a way to update cache
                    _a.sent();
                    return [4 /*yield*/, refetchQueriesOnStrategyValueChange({ selectedTreeNodeId: selectedTreeNodeId, attributeName: attributeName })];
                case 2:
                    _a.sent();
                    handleAddNewItemsToHistory(newAttributeData, [selectedStrategyNodePath]);
                    addSuccess(t({
                        key: 'VALUE_STRATEGIZED',
                        values: {
                            value: newValue,
                            attribute: attributeName,
                            category: selectedTreeNodeTitle,
                        },
                    }));
                    return [3 /*break*/, 4];
                case 3:
                    err_1 = _a.sent();
                    addError(t({
                        key: 'ADD_ENTITY_ERROR',
                        values: {
                            count: 1,
                            entity: String(t({ key: 'VALUE' })).toLowerCase(),
                        },
                    }));
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); }, [
        attributeName,
        selectedTreeNodeId,
        addValuesToSeveralNodes,
        addSuccess,
        t,
        handleAddNewItemsToHistory,
        selectedStrategyNodePath,
        addError,
        refetchQueriesOnStrategyValueChange,
        selectedTreeNodeTitle,
    ]);
    return {
        onStrategizeValue: onStrategizeValue,
    };
};
exports.useStrategizeValue = useStrategizeValue;
