"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ValueInputDisplay = void 0;
var react_1 = __importStar(require("react"));
var filter_constants_1 = require("../../../filter.constants");
var filter_model_1 = require("../../../filter.model");
var common_1 = require("../common");
var fields_1 = require("../fields");
var ValueInputDisplay = function (_a) {
    var selected = _a.selected, onUpdateValue = _a.onUpdateValue, field = _a.field, filter = _a.filter, fieldSpecifier = _a.fieldSpecifier;
    var handleValueChange = (0, react_1.useCallback)(function (value) { return onUpdateValue(value); }, [onUpdateValue]);
    var booleanField = filter_constants_1.BOOLEAN_FIELDS.includes(fieldSpecifier);
    var renderInput = function () {
        var sharedProps = {
            onOptionChange: handleValueChange,
            selected: selected,
        };
        if (field === filter_model_1.FilterField.Category)
            return react_1.default.createElement(fields_1.CategorySelector, __assign({}, sharedProps));
        if (field === filter_model_1.FilterField.Attribute)
            return react_1.default.createElement(fields_1.AttributeValuesSelector, __assign({}, sharedProps, { filter: filter }));
        if (field === filter_model_1.FilterField.TaxonomyClass)
            return react_1.default.createElement(fields_1.TaxonomySelector, __assign({}, sharedProps));
        if (field === filter_model_1.FilterField.Value)
            return react_1.default.createElement(fields_1.ProductValuesContainer, { onOptionChange: handleValueChange, value: selected });
        if (booleanField)
            return react_1.default.createElement(fields_1.ActiveStatusSelector, { onOptionChange: handleValueChange, selected: selected });
        return react_1.default.createElement(common_1.TextInput, { onOptionChange: handleValueChange, value: selected });
    };
    return renderInput();
};
exports.ValueInputDisplay = ValueInputDisplay;
exports.default = exports.ValueInputDisplay;
