"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GroupedMultiSelectMenu = void 0;
var react_1 = __importStar(require("react"));
var ui_components_1 = require("@groupby/ui-components");
var material_1 = require("@mui/material");
var debounce_1 = require("debounce");
var grouped_menu_content_1 = require("./grouped-menu-content");
var grouped_multi_select_menu_styles_1 = require("./grouped-multi-select-menu.styles");
var client_constants_1 = require("../../client/client.constants");
var loading_indicator_1 = require("../loading-indicator");
var utils_1 = require("../../utils");
var localization_1 = require("../../../localization");
function flattenSelectedOptions(groups) {
    return groups.flatMap(function (parent) { return parent.children.map(function (child) { return child.value; }); });
}
var GroupedMultiSelectMenu = function (_a) {
    var anchorEl = _a.anchorEl, anchorPosition = _a.anchorPosition, anchorReference = _a.anchorReference, groupedOptions = _a.groupedOptions, selected = _a.selected, allowEmptySelection = _a.allowEmptySelection, hasNextPage = _a.hasNextPage, moreItemsLoading = _a.moreItemsLoading, isLoading = _a.isLoading, isDisabled = _a.isDisabled, isSubmitting = _a.isSubmitting, isSearching = _a.isSearching, onClose = _a.onClose, onApply = _a.onApply, onCreate = _a.onCreate, onSearch = _a.onSearch, onLoadMore = _a.onLoadMore;
    var t = (0, localization_1.useLocalization)().formatMessage;
    var _b = __read((0, react_1.useState)(selected ? flattenSelectedOptions(selected) : []), 2), selectedIds = _b[0], setSelectedIds = _b[1];
    var _c = __read((0, react_1.useState)(''), 2), searchText = _c[0], setSearchText = _c[1];
    var _d = __read((0, react_1.useState)(null), 2), expandAllFn = _d[0], setExpandAllFn = _d[1];
    var _e = __read((0, react_1.useState)(null), 2), collapseAllFn = _e[0], setCollapseAllFn = _e[1];
    var _f = __read((0, react_1.useState)(0), 2), expandedCount = _f[0], setExpandedCount = _f[1];
    var onSearchDebounced = (0, react_1.useMemo)(function () { return (0, debounce_1.debounce)(function (value) {
        if (onSearch) {
            onSearch(value);
        }
    }, 250); }, [onSearch]);
    (0, react_1.useEffect)(function () {
        if (anchorEl || anchorPosition) {
            setSelectedIds(selected ? flattenSelectedOptions(selected) : []);
        }
        else {
            setSearchText('');
        }
    }, [anchorEl, anchorPosition, selected]);
    var handleSelectChild = (0, react_1.useCallback)(function (childId, isChecked) {
        setSelectedIds(function (prev) { return (0, utils_1.updateUniqueItemListBasedOnValue)(childId, isChecked)(prev); });
    }, []);
    var allChildIds = (0, react_1.useMemo)(function () { return groupedOptions.flatMap(function (grp) { return grp.children.map(function (c) { return c.value; }); }); }, [groupedOptions]);
    var areAllSelected = selectedIds.length > 0 && selectedIds.length === allChildIds.length;
    var handleSelectAll = function () { return setSelectedIds(allChildIds); };
    var handleClearAll = function () { return setSelectedIds([]); };
    var toggleSelectAll = function () {
        if (areAllSelected) {
            handleClearAll();
        }
        else {
            handleSelectAll();
        }
    };
    var handleToggleExpandAll = function () {
        if (!expandAllFn || !collapseAllFn)
            return;
        // If at least one parent is expanded, we collapse all. If none expanded, we expand.
        if (expandedCount > 0) {
            collapseAllFn();
        }
        else {
            expandAllFn();
        }
    };
    var handleApply = function () {
        var selectedStructure = groupedOptions
            .map(function (parent) {
            var selectedChildren = parent.children.filter(function (child) { return selectedIds.includes(child.value); });
            return __assign(__assign({}, parent), { children: selectedChildren });
        })
            .filter(function (p) { return p.children.length > 0; });
        onApply(selectedStructure);
        onClose();
    };
    var handleSearchTextChange = function (e) {
        var value = e.target.value;
        setSearchText(value);
        if (onSearch) {
            if (value.length < client_constants_1.MIN_SEARCH_CHARACTERS_ALLOWED) {
                onSearchDebounced('');
            }
            else {
                onSearchDebounced(value);
            }
        }
    };
    var displayedGroups = groupedOptions;
    var isNoneSelected = selectedIds.length === 0;
    var isCompletelyEmpty = displayedGroups.length === 0;
    var areActionsDisabled = isDisabled
        || isLoading
        || (isCompletelyEmpty && !onCreate)
        || (!allowEmptySelection && isNoneSelected);
    var isAtLeastOneExpanded = expandedCount > 0;
    var expandCollapseLabel = isAtLeastOneExpanded
        ? t({ key: 'COLLAPSE_ALL' })
        : t({ key: 'EXPAND_ALL' });
    var selectAllLabel = areAllSelected
        ? t({ key: 'CLEAR_SELECTION' })
        : t({ key: 'SELECT_ALL' });
    return (react_1.default.createElement(material_1.Popover, { open: !!anchorEl || !!anchorPosition, anchorReference: anchorReference, anchorEl: anchorEl, anchorPosition: anchorPosition, onClose: onClose, TransitionProps: { exit: false, unmountOnExit: true }, anchorOrigin: { vertical: 'bottom', horizontal: 'left' } },
        react_1.default.createElement(grouped_multi_select_menu_styles_1.StyledContainer, null,
            react_1.default.createElement(ui_components_1.SearchField, { value: searchText, onChange: handleSearchTextChange, placeholder: t('SEARCH'), startAdornment: react_1.default.createElement(material_1.InputAdornment, { position: "start" },
                    react_1.default.createElement(ui_components_1.Icon, { variant: "search", color: ui_components_1.baseColors.primaryGreen400, className: "search-icon" })) }),
            !isDisabled && (react_1.default.createElement("div", { className: "actions" },
                react_1.default.createElement(ui_components_1.Button, { rounded: true, size: "small", title: expandCollapseLabel, onClick: handleToggleExpandAll }),
                react_1.default.createElement(ui_components_1.Button, { rounded: true, size: "small", title: selectAllLabel, onClick: toggleSelectAll }))),
            react_1.default.createElement(grouped_menu_content_1.GroupedMenuContent, { options: displayedGroups, selectedIds: selectedIds, isEmpty: isCompletelyEmpty, isDisabled: isDisabled, isLoading: isLoading, isSearching: isSearching, moreItemsLoading: moreItemsLoading, hasNextPage: hasNextPage, onLoadMore: onLoadMore, onSelect: handleSelectChild, registerExpandMethods: function (expandAll, collapseAll) {
                    setExpandAllFn(function () { return expandAll; });
                    setCollapseAllFn(function () { return collapseAll; });
                }, onExpandCountChange: function (count) { return setExpandedCount(count); } }),
            react_1.default.createElement(material_1.Divider, null),
            react_1.default.createElement("div", { className: "footer" },
                react_1.default.createElement(ui_components_1.Button, { rounded: true, color: "primary", title: t({ key: 'CANCEL' }), onClick: onClose }),
                react_1.default.createElement(ui_components_1.Button, { rounded: true, color: "primary", title: t({ key: 'APPLY' }), variant: "contained", disabled: areActionsDisabled || isSubmitting, onClick: handleApply, endIcon: isSubmitting && react_1.default.createElement(loading_indicator_1.LoadingIndicator, null) })))));
};
exports.GroupedMultiSelectMenu = GroupedMultiSelectMenu;
exports.default = exports.GroupedMultiSelectMenu;
