"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RefineModal = void 0;
var react_1 = __importStar(require("react"));
var ui_components_1 = require("@groupby/ui-components");
var horizontal_picker_1 = require("./horizontal-picker");
var refine_modal_style_1 = require("./refine-modal.style");
var localization_1 = require("../../../localization");
var RefineModal = function (_a) {
    var columns = _a.columns, defaultColumns = _a.defaultColumns, isOpen = _a.isOpen, setOpen = _a.setOpen, activeColumns = _a.activeColumns, setActiveColumns = _a.setActiveColumns;
    var t = (0, localization_1.useLocalization)().formatMessage;
    var initialColumns = (0, react_1.useRef)(null);
    var _b = __read((0, react_1.useState)(true), 2), isFieldsOpen = _b[0], setFieldsOpen = _b[1];
    (0, react_1.useEffect)(function () {
        if (isOpen) {
            initialColumns.current = activeColumns;
        }
    }, [isOpen]);
    var handleApply = function () {
        setOpen(false);
    };
    var handleReset = function () {
        setActiveColumns(defaultColumns);
    };
    var handleClose = function () {
        setActiveColumns(initialColumns.current);
        setOpen(false);
    };
    var handleDataChange = function (selectedItems) {
        setActiveColumns(selectedItems);
    };
    return (react_1.default.createElement(ui_components_1.Modal, { open: isOpen, title: t({ key: 'REFINE' }), handleClose: handleClose, primaryButtonLabel: t({ key: 'DONE' }), primaryButtonDisabled: false, handlePrimaryClick: handleApply },
        react_1.default.createElement(refine_modal_style_1.StyledRefineModalRoot, null,
            react_1.default.createElement(refine_modal_style_1.StyledRefineModalBody, null,
                react_1.default.createElement("div", { className: "RefineModal-Accordion-Wrap" },
                    react_1.default.createElement(ui_components_1.Accordion, { isOpened: isFieldsOpen, onToggle: setFieldsOpen, heading: t({ key: 'FIELDS' }) },
                        react_1.default.createElement(horizontal_picker_1.HorizontalPicker, { resetButtonTitle: t({ key: 'RESET' }), activeTitle: t({ key: 'ACTIVE' }), searchPlaceHolder: "".concat(t({ key: 'SEARCH' }), "..."), height: 250, data: columns, selectedIds: activeColumns, onDataChange: handleDataChange, onReset: handleReset })))))));
};
exports.RefineModal = RefineModal;
exports.default = exports.RefineModal;
