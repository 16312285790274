"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductEditorTable = void 0;
var react_1 = __importStar(require("react"));
var ui_components_1 = require("@groupby/ui-components");
var material_1 = require("@mui/material");
var row_component_1 = require("./row/row.component");
var generic_1 = require("../../../generic");
var localization_1 = require("../../../localization");
var components_1 = require("..");
var ProductEditorTable = function (_a) {
    var columns = _a.columns, currentPage = _a.currentPage, getTableRows = _a.getTableRows, isLoading = _a.isLoading, onPageChange = _a.onPageChange, onRowsPerPageChange = _a.onRowsPerPageChange, onSort = _a.onSort, products = _a.products, rowsPerPage = _a.rowsPerPage, sortParams = _a.sortParams, _b = _a.total, total = _b === void 0 ? 0 : _b, _c = _a.selectedRows, selectedRows = _c === void 0 ? [] : _c, checkboxColumnOrder = _a.checkboxColumnOrder, isExpandable = _a.isExpandable, onSelect = _a.onSelect, onSelectAll = _a.onSelectAll, shouldRowBeHighlighted = _a.shouldRowBeHighlighted, onFilterClick = _a.onFilterClick, isFilterActive = _a.isFilterActive;
    var t = (0, localization_1.useLocalization)().formatMessage;
    var isProductSelected = (0, react_1.useCallback)(function (row) { return !!selectedRows.find(function (_a) {
        var id = _a.id;
        return id === row.id;
    }); }, [
        selectedRows,
    ]);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(ui_components_1.Table, { columns: columns, handleSelectAllClick: onSelectAll, hasEndButtonsCell: false, rowCount: products.length, selectedRowCount: selectedRows.length, checkboxColumnOrder: checkboxColumnOrder, handleSort: onSort, sortParam: sortParams[0], isFilterActive: isFilterActive, onFilterClick: onFilterClick }, isLoading ? (react_1.default.createElement(material_1.TableRow, null,
            react_1.default.createElement(material_1.TableCell, { colSpan: columns.length + 1 },
                react_1.default.createElement(generic_1.Loading, { isLoading: true })))) : !(products === null || products === void 0 ? void 0 : products.length) ? (react_1.default.createElement(material_1.TableRow, null,
            react_1.default.createElement(material_1.TableCell, { colSpan: columns.length + 2 },
                react_1.default.createElement(components_1.NoResults, null)))) : (products.map(function (product) { return (react_1.default.createElement(row_component_1.ProductEditorTableRow, { key: product.id, getTableRows: getTableRows, shouldRowBeHighlighted: shouldRowBeHighlighted, product: product, isRowChecked: isProductSelected(product), onRowSelect: function (event) { return onSelect(event, product); }, isExpandable: isExpandable })); }))),
        react_1.default.createElement(generic_1.TablePagination, { itemsCount: total, pageNumber: currentPage, rowsPerPage: rowsPerPage, onPageChange: onPageChange, onRowsPerPageChange: onRowsPerPageChange, modelType: t({ key: 'PRODUCT_EDITOR_TABLE_ITEMS_NAME' }) })));
};
exports.ProductEditorTable = ProductEditorTable;
exports.default = react_1.default.memo(exports.ProductEditorTable);
