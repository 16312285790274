"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useShowProductUpdatedLabel = void 0;
var date_fns_1 = require("date-fns");
var SHOW_LABEL_THRESHOLD_SECONDS = 60;
var useShowProductUpdatedLabel = function (updatedAt) {
    var secondsSinceUpdate = (0, date_fns_1.differenceInSeconds)(Date.now(), new Date(updatedAt));
    return secondsSinceUpdate < SHOW_LABEL_THRESHOLD_SECONDS;
};
exports.useShowProductUpdatedLabel = useShowProductUpdatedLabel;
