"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductViewerTableContainer = void 0;
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var column_filter_1 = require("./column-filter");
var commenting_1 = require("./commenting");
var table_styles_1 = require("./table.styles");
var table_utils_1 = require("./table.utils");
var bulk_actions_toolbar_1 = require("../../components/bulk-actions-toolbar");
var table_1 = require("../../components/table");
var filter_1 = require("../filter");
var hooks_1 = require("../../../hooks");
var localization_1 = require("../../../localization");
var chips_1 = require("../../chips");
var components_1 = require("../../components");
var hooks_2 = require("../../hooks");
var store_1 = require("../../../store");
var State = __importStar(require("../../../store/state"));
var LS_ACTIVE_COLUMNS = 'ProductViewer_Table_ActiveColumns';
var LS_COLUMN_FILTER = 'ProductViewer_Table_Column_Filter';
var SEARCHABLE_FIELDS = ['title', 'description', 'proprietaryId'];
var leftEmptyColumn = { label: '' };
var ProductViewerTableContainer = function (_a) {
    var lastSelectedRowId = _a.lastSelectedRowId, setLastSelectedRowId = _a.setLastSelectedRowId;
    var t = (0, localization_1.useLocalization)().formatMessage;
    var dispatch = (0, react_redux_1.useDispatch)();
    var addSuccess = (0, hooks_1.useNotification)().addSuccess;
    var _b = __read((0, react_1.useState)(false), 2), isSingleCommentingDialogOpen = _b[0], setIsSingleCommentingDialogOpen = _b[1];
    var _c = __read((0, react_1.useState)(false), 2), isBulkCommentingDialogOpen = _c[0], setIsBulkCommentingDialogOpen = _c[1];
    var _d = __read((0, react_1.useState)(null), 2), selectedProduct = _d[0], setSelectedProduct = _d[1];
    var _e = (0, hooks_2.useAttributes)(), attributeNameOptions = _e.attributeNameOptions, areAttributesLoading = _e.isLoading;
    var taxonomyClasses = (0, store_1.useClassesSelector)();
    var _f = (0, hooks_2.useTableSearch)(SEARCHABLE_FIELDS, setLastSelectedRowId), handleSearch = _f.handleSearch, searchFilter = _f.searchFilter, searchQuery = _f.searchQuery;
    var _g = (0, hooks_2.useTableSort)(), sortParams = _g.sortParams, handleSort = _g.handleSort;
    var _h = (0, hooks_2.useColumnFilter)(LS_COLUMN_FILTER), anchorEl = _h.anchorEl, openColumnFilter = _h.openColumnFilter, closeColumnFilter = _h.closeColumnFilter, getColumnFilterValue = _h.getColumnFilterValue, isColumnFilterActive = _h.isColumnFilterActive, updateColumnFilter = _h.updateColumnFilter;
    var _j = (0, hooks_2.useFilter)({ searchFilter: searchFilter }), getFilters = _j.getFilters, activeFilter = _j.activeFilter;
    var filter = (0, react_1.useMemo)(function () { return getFilters().filter; }, [getFilters]);
    var _k = (0, hooks_2.useTablePagination)(filter, setLastSelectedRowId), currentPage = _k.currentPage, rowsPerPage = _k.rowsPerPage, handlePageChange = _k.handlePageChange, handleRowsPerPageChange = _k.handleRowsPerPageChange;
    var _l = (0, hooks_2.useLoadProducts)({
        filter: filter,
        currentPage: currentPage,
        rowsPerPage: rowsPerPage,
        sortParams: sortParams,
    }), isLoading = _l.isLoading, products = _l.products, updateProducts = _l.updateProducts;
    var additionalColumns = (0, react_1.useMemo)(function () { return __spreadArray([
        {
            label: t({ key: 'PRODUCT_EDITOR_TABLE_COLUMNS_COMMENTS' }),
            id: State.PRODUCT_EDITOR_TABLE_COLUMNS.COMMENTS,
            description: t({ key: 'DEFAULT' }),
        },
        {
            label: t({ key: 'PRODUCT_EDITOR_TABLE_COLUMNS_CURATED_ATTRIBUTES' }),
            id: State.PRODUCT_EDITOR_TABLE_COLUMNS.CURATED_ATTRIBUTES,
        },
        {
            label: t({ key: 'PRODUCT_EDITOR_TABLE_COLUMNS_STANDARD_FIELDS' }),
            id: State.PRODUCT_EDITOR_TABLE_COLUMNS.STANDARD_FIELDS,
        }
    ], __read(attributeNameOptions), false); }, [attributeNameOptions]);
    var _m = (0, hooks_2.useRefineModal)({
        lsKey: LS_ACTIVE_COLUMNS,
        additionalColumns: additionalColumns,
        additionalDefaultColumnNames: [State.PRODUCT_EDITOR_TABLE_COLUMNS.COMMENTS],
    }), activeColumns = _m.activeColumns, columns = _m.columns, defaultColumns = _m.defaultColumns, handleSetActiveColumns = _m.handleSetActiveColumns;
    var additionalTemplate = (0, react_1.useMemo)(function () { return attributeNameOptions.reduce(function (attributeTemplates, attributeOption) {
        attributeTemplates[attributeOption.id] = { align: 'left', label: attributeOption.label };
        return attributeTemplates;
    }, {}); }, [attributeNameOptions]);
    var tableColumns = (0, hooks_2.useTableColumns)({ activeColumns: activeColumns, additionalTemplate: additionalTemplate });
    var productViewerTableColumns = __spreadArray([leftEmptyColumn], __read(tableColumns), false);
    var total = (0, store_1.useProductsTotal)();
    var _o = (0, hooks_2.useSelectedProducts)({
        lastSelectedRowId: lastSelectedRowId,
        setLastSelectedRowId: setLastSelectedRowId,
        products: products,
    }), handleAllProductsSelect = _o.handleAllProductsSelect, handleProductSelect = _o.handleProductSelect, selectedProductsCount = _o.selectedProductsCount, selectedProductsForPage = _o.selectedProductsForPage;
    var handleCommentClick = function (product) { return function () {
        setSelectedProduct(product);
        setIsSingleCommentingDialogOpen(true);
    }; };
    var handleSingleCommentingDialogClose = function (isAtLeastOneCommentAdded) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsSingleCommentingDialogOpen(false);
                    if (!isAtLeastOneCommentAdded) return [3 /*break*/, 2];
                    return [4 /*yield*/, updateProducts([selectedProduct.id])];
                case 1:
                    _a.sent();
                    addSuccess(t({ key: 'SINGLE_COMMENTING_SUCCESS_MESSAGE' }));
                    _a.label = 2;
                case 2:
                    setSelectedProduct(null);
                    return [2 /*return*/];
            }
        });
    }); };
    var handleBulkCommentingDialogClose = function (isAtLeastOneCommentAdded) { return __awaiter(void 0, void 0, void 0, function () {
        var selectedIds;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!isAtLeastOneCommentAdded) return [3 /*break*/, 2];
                    selectedIds = selectedProductsForPage.map(function (_a) {
                        var id = _a.id;
                        return id;
                    });
                    setLastSelectedRowId(null);
                    dispatch((0, store_1.setIsExclusionModeOn)(false));
                    dispatch((0, store_1.clearAllSelectedProducts)());
                    setIsBulkCommentingDialogOpen(false);
                    return [4 /*yield*/, updateProducts(selectedIds)];
                case 1:
                    _a.sent();
                    addSuccess(t({ key: 'BULK_COMMENTING_SUCCESS_MESSAGE', values: { count: selectedIds.length } }));
                    return [3 /*break*/, 3];
                case 2:
                    setIsBulkCommentingDialogOpen(false);
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var getAdditionalRows = (0, react_1.useCallback)(function (product, options) {
        var _a;
        var _b;
        var classId = product.classId, totalComments = product.totalComments, attributes = product.attributes;
        var attributeRows = attributes.reduce(function (attributesMap, attribute) {
            attributesMap[attribute.id] = (react_1.default.createElement(table_1.AttributeValuesCell, { key: attribute.id, attributeValues: attribute.values, expanded: options === null || options === void 0 ? void 0 : options.isExpanded }));
            return attributesMap;
        }, {});
        return __assign((_a = {}, _a[State.PRODUCT_EDITOR_TABLE_COLUMNS.CLASS] = react_1.default.createElement(table_1.TextCell, { key: "class" }, (_b = taxonomyClasses === null || taxonomyClasses === void 0 ? void 0 : taxonomyClasses.byId[classId]) === null || _b === void 0 ? void 0 : _b.title), _a[State.PRODUCT_EDITOR_TABLE_COLUMNS.COMMENTS] = react_1.default.createElement(table_1.CommentCell, { key: "comments", total: totalComments, onCommentClick: handleCommentClick(product) }), _a), attributeRows);
    }, [taxonomyClasses === null || taxonomyClasses === void 0 ? void 0 : taxonomyClasses.byId]);
    var getTableRows = (0, hooks_2.useTableRows)({ activeColumns: activeColumns, getAdditionalRows: getAdditionalRows, getColumnFilterValue: getColumnFilterValue });
    var handleChipsClear = function () {
        setLastSelectedRowId(null);
    };
    (0, react_1.useEffect)(function () {
        setLastSelectedRowId(null);
        dispatch((0, store_1.setIsExclusionModeOn)(false));
        dispatch((0, store_1.clearAllSelectedProducts)());
    }, [filter]);
    var handleFilterClick = (0, react_1.useCallback)(function (_a, anchor) {
        var id = _a.id;
        return openColumnFilter(id, anchor);
    }, [openColumnFilter]);
    var isFilterActive = (0, react_1.useCallback)(function (_a) {
        var id = _a.id;
        return isColumnFilterActive(id);
    }, [isColumnFilterActive]);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(table_styles_1.StyledTableToolbar, null,
            react_1.default.createElement(filter_1.ProductViewerFilter, { searchQuery: searchQuery, onSearch: handleSearch, activeColumns: activeColumns, columns: columns, defaultColumns: defaultColumns, setActiveColumns: handleSetActiveColumns, disabled: isLoading })),
        react_1.default.createElement(chips_1.ChipsContainer, { onClear: handleChipsClear }),
        react_1.default.createElement(components_1.TableSelectionButtons, { setLastSelectedRowId: setLastSelectedRowId }),
        selectedProductsCount > 0 && (react_1.default.createElement(bulk_actions_toolbar_1.BulkActionsToolbar, { onBulkCommentActionClick: function () { return setIsBulkCommentingDialogOpen(true); }, total: total, selectedCount: selectedProductsCount })),
        react_1.default.createElement(components_1.ProductEditorTable, { isExpandable: true, columns: productViewerTableColumns, currentPage: currentPage, isLoading: isLoading, products: products, rowsPerPage: rowsPerPage, sortParams: sortParams, getTableRows: getTableRows, onSort: handleSort, onPageChange: handlePageChange, onRowsPerPageChange: handleRowsPerPageChange, total: total, onSelect: handleProductSelect, onSelectAll: handleAllProductsSelect, selectedRows: selectedProductsForPage, shouldRowBeHighlighted: table_utils_1.shouldRowBeHighlighted, onFilterClick: handleFilterClick, isFilterActive: isFilterActive, checkboxColumnOrder: 1 }),
        isSingleCommentingDialogOpen && selectedProduct && (react_1.default.createElement(commenting_1.SingleCommentingDialog, { product: selectedProduct, onClose: handleSingleCommentingDialogClose })),
        isBulkCommentingDialogOpen && (react_1.default.createElement(commenting_1.BulkCommentDialog, { selectedCount: selectedProductsCount, activeFilter: activeFilter, onClose: handleBulkCommentingDialogClose })),
        anchorEl && (react_1.default.createElement(column_filter_1.ColumnFilter, { anchorEl: anchorEl, isLoading: areAttributesLoading, onClose: closeColumnFilter, filterOptions: attributeNameOptions, selected: getColumnFilterValue(), onApply: updateColumnFilter }))));
};
exports.ProductViewerTableContainer = ProductViewerTableContainer;
exports.default = exports.ProductViewerTableContainer;
