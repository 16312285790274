"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ValueFormList = void 0;
var react_1 = __importStar(require("react"));
var ui_components_1 = require("@groupby/ui-components");
var create_attribute_form_styles_1 = require("./create-attribute-form.styles");
var add_some_values_button_1 = require("./partials/add-some-values-button");
var attribute_form_list_item_component_1 = require("./partials/attribute-form-list-item.component");
var loading_indicator_1 = require("../../loading-indicator");
var values_1 = require("../../../client/values");
var models_1 = require("../../../models");
var utils_1 = require("../../../utils");
var localization_1 = require("../../../../localization");
var store_1 = require("../../../../store");
var ValueFormList = function (_a) {
    var attributeLabel = _a.attributeLabel, valueOptionListStrategyNodeId = _a.valueOptionListStrategyNodeId, name = _a.name, values = _a.values, hideDescription = _a.hideDescription, isNewLabel = _a.isNewLabel, allowCreation = _a.allowCreation, onAddSome = _a.onAddSome, onRemoveAll = _a.onRemoveAll, onRemove = _a.onRemove;
    var t = (0, localization_1.useLocalization)().formatMessage;
    var selectedCatalogId = (0, store_1.useSelectedCatalogId)();
    var _b = (0, values_1.useGetStrategyValueOptionList)({
        catalogId: selectedCatalogId,
        productTreeNodeId: valueOptionListStrategyNodeId,
        attributeName: attributeLabel,
        sortingItem: models_1.SortingItem.SortByTextAsc,
        options: { skip: isNewLabel },
    }), data = _b.data, _c = _b.pagination, isLoadingMore = _c.isLoadingMore, hasNextPage = _c.hasNextPage, fetchMore = _c.onNextPage, loading = _b.isLoading, searchText = _b.searchText, error = _b.error, refetch = _b.refetch;
    var filteredOptionList = (0, react_1.useMemo)(function () {
        var existingValues = values.map(function (_a) {
            var name = _a.name;
            return name;
        });
        if (!data) {
            return [];
        }
        return data
            .filter(function (_a) {
            var value = _a.node.value;
            return value && !existingValues.includes(value);
        })
            .map(function (_a) {
            var _b = _a.node, value = _b.value, description = _b.description;
            return ({ label: value, value: value, description: description !== null && description !== void 0 ? description : '' });
        });
    }, [values, data]);
    var handleSelect = function (valueIds) {
        if (!valueIds) {
            var list_1 = filteredOptionList.map(function (_a) {
                var value = _a.value, description = _a.description;
                return ({ name: value, description: description !== null && description !== void 0 ? description : '' });
            });
            setTimeout(function () { return onAddSome(list_1); }, 0);
        }
        else {
            var newValues = filteredOptionList.filter(function (_a) {
                var value = _a.value;
                return valueIds.includes(value);
            });
            onAddSome(newValues.map(function (_a) {
                var value = _a.value, description = _a.description;
                return ({ name: value, description: description });
            }));
        }
    };
    var handleCreate = function (valueIds) {
        onAddSome(valueIds.map(function (id) { return ({ name: id, description: '' }); }));
    };
    var isAtLeastOneValueAdded = (0, react_1.useMemo)(function () { return values.some(function (_a) {
        var readonly = _a.readonly;
        return !readonly;
    }); }, [values]);
    var isDuplicationError = (0, react_1.useMemo)(function () { return (0, utils_1.areDuplicatesExist)({
        list: values,
        propName: 'name',
        useLowerCase: false,
    }); }, [values]);
    if (!attributeLabel) {
        return null;
    }
    var isLoading = !data && loading;
    return (react_1.default.createElement(create_attribute_form_styles_1.StyledFormSection, null,
        react_1.default.createElement(ui_components_1.Typography, { strong: true, accent: true, variant: "body2", color: "textPrimary" }, t({ key: 'VALUES_FORM_SECTION_TITLE' })),
        isLoading && react_1.default.createElement(loading_indicator_1.LoadingIndicator, null),
        !isLoading && (values === null || values === void 0 ? void 0 : values.map(function (_item, index) { return (react_1.default.createElement(attribute_form_list_item_component_1.AttributeFormListItem, { key: index, name: "".concat(name, ".").concat(index), nameFieldTitle: t({ key: 'VALUE' }), descriptionFieldTitle: t({ key: 'ENTITY_GUIDELINES', values: { entity: t({ key: 'VALUE' }) } }), isLoading: loading, options: filteredOptionList, hasNextPage: hasNextPage, error: error === null || error === void 0 ? void 0 : error.message, hideDescription: hideDescription, onRemoveClick: function () { return onRemove(index); }, onSearch: refetch, moreItemsLoading: isLoadingMore, fetchMore: fetchMore })); })),
        isDuplicationError && (react_1.default.createElement(ui_components_1.Typography, { color: "error", variant: "caption" }, t({ key: 'VALIDATION_MUST_BE_UNIQUE', values: { name: t({ key: 'VALUE' }) } }))),
        react_1.default.createElement("div", { className: "form-section-actions" },
            allowCreation && (react_1.default.createElement(add_some_values_button_1.AddSomeValuesButton, { options: filteredOptionList, isLoading: isLoading, moreItemsLoading: isLoadingMore, 
                // TODO: networkStatus is wrong when refetching a lazy query (setVariables instead of refetch)
                isSearching: loading, searchText: searchText, hasNextPage: hasNextPage, onSelect: handleSelect, onCreate: handleCreate, onLoadMore: fetchMore, onSearch: refetch })),
            isAtLeastOneValueAdded && (react_1.default.createElement(ui_components_1.Button, { rounded: true, error: true, size: "small", title: t({ key: 'CLEAR_ALL' }), onClick: onRemoveAll })))));
};
exports.ValueFormList = ValueFormList;
exports.default = exports.ValueFormList;
