"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AttributesFilterContainer = exports.default = void 0;
var react_1 = __importStar(require("react"));
var attributes_sidebar_section_component_1 = require("./attributes-sidebar-section.component");
var formal_attributes_1 = require("../../../formal-attributes");
var store_1 = require("../../../../store");
var AttributesFilterContainer = function (_a) {
    var searchTerm = _a.searchTerm;
    var actualAttributes = (0, store_1.useRefinementsDataSelector)().actualAttributes;
    var refinementsLoading = (0, store_1.useRefinementsLoadingSelector)();
    var _b = (0, formal_attributes_1.useFormalAttributes)(), loadFormalAttributes = _b.loadFormalAttributes, isFormalAttributesLoading = _b.isLoading;
    var isAttributesLoading = refinementsLoading || isFormalAttributesLoading;
    (0, react_1.useEffect)(function () {
        if (actualAttributes === null || actualAttributes === void 0 ? void 0 : actualAttributes.length) {
            void loadFormalAttributes(actualAttributes);
        }
    }, [actualAttributes, loadFormalAttributes]);
    return (react_1.default.createElement(attributes_sidebar_section_component_1.AttributesSideBarSection, { searchTerm: searchTerm, isAttributesLoading: isAttributesLoading }));
};
var MemoizedAttributesFilterContainer = react_1.default.memo(AttributesFilterContainer);
exports.default = MemoizedAttributesFilterContainer;
exports.AttributesFilterContainer = MemoizedAttributesFilterContainer;
