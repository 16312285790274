"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useProductsTableColumnsConfig = void 0;
var react_1 = __importStar(require("react"));
var ui_components_1 = require("@groupby/ui-components");
var constants_1 = require("./constants");
var cells_1 = require("../cells");
var product_name_cell_1 = require("../cells/product-name-cell");
var AttributeTitle_1 = require("../partials/AttributeTitle");
var attributes_1 = require("../../../../../client/attributes");
var table_filter_1 = require("../../../../../common/table-filter");
var product_table_dynamic_columns_1 = require("../../../../../hooks/product-table-dynamic-columns");
var preview_products_widget_constants_1 = require("../../../../preview-products-widget.constants");
var localization_1 = require("../../../../../../localization");
var useProductsTableColumnsConfig = function (_a) {
    var onEdit = _a.onEdit, isSavingEdits = _a.isSavingEdits, onActiveProductIdChange = _a.onActiveProductIdChange;
    var t = (0, localization_1.useLocalization)().formatMessage;
    var getTextFilterConfig = (0, table_filter_1.useColumnTextFilterConfig)();
    var getDateFilterConfig = (0, table_filter_1.useColumnDateFilterConfig)();
    var pageUrlId = (0, product_table_dynamic_columns_1.createColumnId)(attributes_1.PRODUCT_PAGE_URL_LABEL, { isAIEnriched: true });
    var imageId = (0, product_table_dynamic_columns_1.createColumnId)(attributes_1.PRODUCT_IMAGE_LABEL, { isAIEnriched: true });
    var classificationTypeId = (0, product_table_dynamic_columns_1.createColumnId)(preview_products_widget_constants_1.CustomProductFields.ClassificationProductType, { isAIEnriched: true });
    var columnsConfig = (0, react_1.useMemo)(function () { return [
        {
            align: 'left',
            id: preview_products_widget_constants_1.CustomProductFields.ExternalId,
            type: ui_components_1.DataGridColumnType.Text,
            label: t({ key: 'PRODUCT_EDITOR_TABLE_COLUMNS_PRODUCT_ID' }),
            path: preview_products_widget_constants_1.CustomProductFields.ExternalId,
            isEditable: false,
            width: '7rem',
            minWidth: '7rem',
            maxWidth: '7rem',
            static: true,
            render: function (row) { return (react_1.default.createElement(cells_1.ProductSkuCell, { sku: row[preview_products_widget_constants_1.CustomProductFields.ExternalId], updatedAt: row[attributes_1.LAST_ATTRIBUTE_UPDATED_AT_LABEL] })); },
            filterConfig: getTextFilterConfig({ id: preview_products_widget_constants_1.CustomProductFields.ExternalId }),
        },
        {
            id: preview_products_widget_constants_1.CustomProductFields.Name,
            label: t({ key: 'PRODUCT_EDITOR_TABLE_COLUMNS_NAME' }),
            path: preview_products_widget_constants_1.CustomProductFields.Name,
            width: '15rem',
            minWidth: '15rem',
            maxWidth: '15rem',
            isEditable: false,
            static: true,
            render: function (row) { return (react_1.default.createElement(product_name_cell_1.ProductNameCell, { productName: row[preview_products_widget_constants_1.CustomProductFields.Name], productId: row.id, onActiveProductIdChange: onActiveProductIdChange })); },
            filterConfig: getTextFilterConfig({ id: preview_products_widget_constants_1.CustomProductFields.Name }),
        },
        {
            align: 'center',
            id: imageId,
            type: ui_components_1.DataGridColumnType.Image,
            label: t({ key: 'PRODUCT_EDITOR_TABLE_COLUMNS_IMAGE' }),
            path: "".concat(constants_1.LABEL_PATH, ".").concat(attributes_1.PRODUCT_IMAGE_LABEL),
            width: '4.5rem',
            minWidth: '4.5rem',
            maxWidth: '4.5rem',
            isEditable: false,
            static: true,
        },
        {
            align: 'center',
            id: pageUrlId,
            type: ui_components_1.DataGridColumnType.Link,
            label: t({ key: 'PRODUCT_EDITOR_TABLE_COLUMNS_URL' }),
            path: "".concat(constants_1.LABEL_PATH, ".").concat(attributes_1.PRODUCT_PAGE_URL_LABEL),
            width: '4rem',
            minWidth: '4rem',
            maxWidth: '4rem',
            isEditable: false,
            static: true,
        },
        {
            id: preview_products_widget_constants_1.OriginalProductFields.Description,
            label: t({ key: 'PRODUCT_EDITOR_TABLE_COLUMNS_DESCRIPTION' }),
            path: preview_products_widget_constants_1.OriginalProductFields.Description,
            width: '20rem',
            minWidth: '20rem',
            maxWidth: '20rem',
            isEditable: false,
            filterConfig: getTextFilterConfig({ id: preview_products_widget_constants_1.OriginalProductFields.Description }),
        },
        {
            align: 'left',
            id: preview_products_widget_constants_1.CustomProductFields.LastAttributeUpdatedAt,
            type: ui_components_1.DataGridColumnType.Date,
            label: t({ key: 'DATE_UPDATED' }),
            path: preview_products_widget_constants_1.CustomProductFields.LastAttributeUpdatedAt,
            width: '7rem',
            minWidth: '7rem',
            maxWidth: '7rem',
            isEditable: false,
            filterConfig: getDateFilterConfig({ id: preview_products_widget_constants_1.CustomProductFields.LastAttributeUpdatedAt }),
        },
        {
            id: classificationTypeId,
            label: t({ key: 'PRODUCT_EDITOR_TABLE_COLUMNS_CLASSIFICATION_PRODUCT_TYPE' }),
            path: preview_products_widget_constants_1.CustomProductFields.ClassificationProductType,
            width: '15rem',
            minWidth: '15rem',
            maxWidth: '15rem',
            isEditable: false,
            render: function (row) { return react_1.default.createElement(cells_1.ClassificationProductTypeCell, { value: row[preview_products_widget_constants_1.CustomProductFields.ClassificationProductType], productId: row.id, onReclassify: onEdit, isReclassificationInProgress: isSavingEdits }); },
            renderTitle: function () { return react_1.default.createElement(AttributeTitle_1.AttributeTitle, { label: t({ key: 'PRODUCT_EDITOR_TABLE_COLUMNS_CLASSIFICATION_PRODUCT_TYPE' }), isAIEnriched: true }); },
            filterConfig: getTextFilterConfig({ id: classificationTypeId }),
        },
    ]; }, [
        classificationTypeId,
        imageId,
        isSavingEdits,
        pageUrlId,
        onActiveProductIdChange,
        onEdit,
        getDateFilterConfig,
        getTextFilterConfig,
        t,
    ]);
    return columnsConfig;
};
exports.useProductsTableColumnsConfig = useProductsTableColumnsConfig;
