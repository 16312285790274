"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertFilter = exports.parseFilter = void 0;
var products_table_1 = require("../../../../store/catalogs/products-table");
var parseFilter = function (tableFilter) {
    var filter = {
        search: '',
        includes: {},
        range: {},
        numbers: {},
        text: {},
    };
    Object.keys(tableFilter).forEach(function (fieldKey) {
        var _a = tableFilter[fieldKey], type = _a.type, columnFilter = __rest(_a, ["type"]);
        switch (type) {
            case products_table_1.ProductTableColumnFilterTypes.Text:
                {
                    var textFilter = __assign({ field: fieldKey }, columnFilter);
                    filter.text[fieldKey] = textFilter;
                }
                break;
            case products_table_1.ProductTableColumnFilterTypes.MultiSelect:
                {
                    var _b = columnFilter.list, list = _b === void 0 ? [] : _b, restIncludesFilter = __rest(columnFilter, ["list"]);
                    var includesFilter = __assign({ field: fieldKey, list: list }, restIncludesFilter);
                    filter.includes[fieldKey] = includesFilter;
                }
                break;
            case products_table_1.ProductTableColumnFilterTypes.Date:
                {
                    var rangeFilter = __assign({ field: fieldKey }, columnFilter);
                    filter.range[fieldKey] = rangeFilter;
                }
                break;
            case products_table_1.ProductTableColumnFilterTypes.Number:
                {
                    var rangeFilter = __assign({ field: fieldKey }, columnFilter);
                    filter.numbers[fieldKey] = rangeFilter;
                }
                break;
        }
    });
    return filter;
};
exports.parseFilter = parseFilter;
var convertFilter = function (filter) {
    var includes = filter.includes, text = filter.text, range = filter.range, numbers = filter.numbers;
    var tableFilter = {};
    Object.keys(includes).forEach(function (columnId) {
        var list = includes[columnId].list;
        if (list.length > 0) {
            var filter_1 = { list: list };
            tableFilter[columnId] = __assign({ type: products_table_1.ProductTableColumnFilterTypes.MultiSelect }, filter_1);
        }
    });
    Object.keys(text).forEach(function (columnId) {
        var _a = text[columnId], search = _a.search, match = _a.match;
        if (search) {
            var filter_2 = { search: search, match: match };
            tableFilter[columnId] = __assign({ type: products_table_1.ProductTableColumnFilterTypes.Text }, filter_2);
        }
    });
    Object.keys(range).forEach(function (columnId) {
        var _a = range[columnId], startAt = _a.startAt, endAt = _a.endAt;
        if (startAt || endAt) {
            var filter_3 = { startAt: startAt, endAt: endAt };
            tableFilter[columnId] = __assign({ type: products_table_1.ProductTableColumnFilterTypes.Date }, filter_3);
        }
    });
    Object.keys(numbers).forEach(function (columnId) {
        var _a = numbers[columnId], low = _a.low, high = _a.high;
        if (low || high) {
            var filter_4 = { low: low, high: high };
            tableFilter[columnId] = __assign({ type: products_table_1.ProductTableColumnFilterTypes.Number }, filter_4);
        }
    });
    return tableFilter;
};
exports.convertFilter = convertFilter;
