"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGetTotalClassSkuCountForStrategyMatrix = void 0;
var client_1 = require("@apollo/client");
var gql_1 = require("../gql");
var client_utils_1 = require("../../../enrich-ai/client/client.utils");
var useGetTotalClassSkuCountForStrategyMatrix = function (_a) {
    var _b;
    var productTreeId = _a.productTreeId, catalog = _a.catalog, selectedAttribute = _a.selectedAttribute;
    var _c = (0, client_1.useQuery)(gql_1.GET_TOTAL_CLASS_SKU_COUNT_FOR_STRATEGY_MATRIX, {
        variables: {
            productTreeId: productTreeId,
            ids: [catalog],
            attribute: selectedAttribute,
        },
        fetchPolicy: 'no-cache',
        skip: !productTreeId || !selectedAttribute || !catalog,
    }), data = _c.data, error = _c.error, networkStatus = _c.networkStatus;
    var terminalNodesTotal = (_b = data === null || data === void 0 ? void 0 : data.catalogs) === null || _b === void 0 ? void 0 : _b.edges;
    return {
        data: terminalNodesTotal,
        IsLoading: (0, client_utils_1.isQueryLoadingOrRefetching)(networkStatus),
        error: error === null || error === void 0 ? void 0 : error.message,
    };
};
exports.useGetTotalClassSkuCountForStrategyMatrix = useGetTotalClassSkuCountForStrategyMatrix;
