"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.TICKETS_FOR_REPLACE_CATALOG = exports.TICKETS_FOR_RE_ENRICH_TREE_NODE = exports.TICKETS_FOR_RE_ENRICH_CATALOG = exports.TICKETS_FOR_THE_NEW_CATALOG = exports.CATALOG_IMPORT_FROM_URL_TICKET_DESCRIPTION = exports.ticketTypeToContentMap = exports.TicketType = void 0;
var TicketType;
(function (TicketType) {
    TicketType[TicketType["CatalogImport"] = 0] = "CatalogImport";
    TicketType[TicketType["CatalogExport"] = 1] = "CatalogExport";
    TicketType[TicketType["Categorize"] = 2] = "Categorize";
    TicketType[TicketType["Extract"] = 3] = "Extract";
    TicketType[TicketType["Cluster"] = 4] = "Cluster";
})(TicketType = exports.TicketType || (exports.TicketType = {}));
// TODO: generate tickets descriptions on BE side
// no localization is needed as this text is only a prompt for the AI
exports.ticketTypeToContentMap = (_a = {},
    _a[TicketType.CatalogImport] = {
        title: 'CatalogImport Ticket',
        description: 'Please import products into this catalog using its source.',
    },
    _a[TicketType.CatalogExport] = {
        title: 'CatalogExport Ticket',
        description: 'Please export the catalog to a file and upload to a URL.',
    },
    _a[TicketType.Categorize] = {
        title: 'Categorize Ticket',
        description: 'Please categorize the products in this catalog.',
    },
    _a[TicketType.Extract] = {
        title: 'Extract Ticket',
        description: 'Please extract attributes for products in this catalog.',
    },
    _a[TicketType.Cluster] = {
        title: 'Cluster Ticket',
        description: 'Please create reasoning clusters for this catalog.',
    },
    _a);
exports.CATALOG_IMPORT_FROM_URL_TICKET_DESCRIPTION = 'Please import products from the URL ';
exports.TICKETS_FOR_THE_NEW_CATALOG = [
    TicketType.CatalogImport,
    TicketType.Categorize,
    TicketType.Extract,
    TicketType.Cluster,
    TicketType.CatalogExport,
];
exports.TICKETS_FOR_RE_ENRICH_CATALOG = [
    TicketType.Categorize,
    TicketType.Extract,
    TicketType.Cluster,
    TicketType.CatalogExport,
];
exports.TICKETS_FOR_RE_ENRICH_TREE_NODE = [
    TicketType.Categorize,
    TicketType.Extract,
    TicketType.Cluster,
    TicketType.CatalogExport,
];
exports.TICKETS_FOR_REPLACE_CATALOG = [
    TicketType.CatalogImport,
    TicketType.Categorize,
    TicketType.Extract,
    TicketType.Cluster,
    TicketType.CatalogExport,
];
