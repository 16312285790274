"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGetProductAttributesLabelsForStrategyMatrix = void 0;
var client_1 = require("@apollo/client");
var hooks_1 = require("../../../enrich-ai/client/hooks");
var gql_1 = require("../gql");
var client_constants_1 = require("../../../enrich-ai/client/client.constants");
var client_utils_1 = require("../../../enrich-ai/client/client.utils");
// merge the new part with an already loaded
var updateQuery = function (previousResult, options) {
    var _a, _b;
    var _c = ((_a = previousResult.productTreeNode.productAggregatedLabelsNew) !== null && _a !== void 0 ? _a : {}).edges, prevAttributes = _c === void 0 ? [] : _c;
    var productTreeNode = ((_b = options === null || options === void 0 ? void 0 : options.fetchMoreResult) !== null && _b !== void 0 ? _b : {}).productTreeNode;
    var _d = productTreeNode.productAggregatedLabelsNew, _e = _d.edges, edges = _e === void 0 ? [] : _e, productAggregatedLabelsNew = __rest(_d, ["edges"]);
    return {
        productTreeNode: __assign(__assign(__assign({}, previousResult.productTreeNode), productTreeNode), { productAggregatedLabelsNew: __assign(__assign({}, productAggregatedLabelsNew), { edges: __spreadArray(__spreadArray([], __read(prevAttributes), false), __read(edges), false) }) }),
    };
};
var fetchMoreOptions = { updateQuery: updateQuery };
var useGetProductAttributesLabelsForStrategyMatrix = function (_a) {
    var _b, _c;
    var // not using anymore
    productTreeNodeId = _a.productTreeNodeId, _d = _a.pageSize, pageSize = _d === void 0 ? client_constants_1.PAGE_SIZE_LARGE : _d, options = _a.options;
    var _e = (0, client_1.useQuery)(gql_1.GET_ALL_ATTRIBUTES_FOR_STRATEGY_MATRIX, {
        variables: {
            productTreeNodeId: productTreeNodeId,
            first: client_constants_1.PAGE_SIZE_LARGE,
        },
        skip: !productTreeNodeId || (options === null || options === void 0 ? void 0 : options.skip),
    }), data = _e.data, error = _e.error, networkStatus = _e.networkStatus, variables = _e.variables, fetchMore = _e.fetchMore;
    var _f = (_c = (_b = data === null || data === void 0 ? void 0 : data.productTreeNode) === null || _b === void 0 ? void 0 : _b.labelValidation) !== null && _c !== void 0 ? _c : {}, edges = _f.edges, pageInfo = _f.pageInfo;
    var pagination = (0, hooks_1.usePagination)({
        pageInfo: pageInfo,
        networkStatus: networkStatus,
        pageSize: pageSize,
        fetchMore: fetchMore,
        fetchMoreOptions: fetchMoreOptions,
        lastVariables: variables,
    });
    return {
        data: edges,
        isLoading: (0, client_utils_1.isQueryLoadingOrRefetching)(networkStatus),
        isRefetching: (0, client_utils_1.isQueryRefetching)(networkStatus),
        error: error,
        pagination: pagination,
        fetchMore: fetchMore,
    };
};
exports.useGetProductAttributesLabelsForStrategyMatrix = useGetProductAttributesLabelsForStrategyMatrix;
