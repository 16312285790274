"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddAttributeContainer = void 0;
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var add_to_other_nodes_dialog_component_1 = require("./add-to-other-nodes-dialog.component");
var validation_schema_1 = require("./validation-schema");
var attributes_1 = require("../../../client/attributes");
var hooks_1 = require("../../../client/hooks");
var generic_1 = require("../../../generic");
var hooks_2 = require("../../../../hooks");
var localization_1 = require("../../../../localization");
var localization_utils_1 = require("../../../../localization/localization.utils");
var store_1 = require("../../../../store");
var CREATE_ATTRIBUTE_FORM_ID = 'create-attribute-form';
var AddAttributeContainer = function (_a) {
    var open = _a.open, onClose = _a.onClose;
    var t = (0, localization_1.useLocalization)().formatMessage;
    var dispatch = (0, react_redux_1.useDispatch)();
    var _b = (0, hooks_2.useNotification)(), addSuccess = _b.addSuccess, addError = _b.addError;
    var refetchQueriesOnStrategyAttributeChange = (0, hooks_1.useRefetchQueries)().refetchQueriesOnStrategyAttributeChange;
    var selectedCatalogId = (0, store_1.useSelectedCatalogId)();
    var productTreeRootNodeId = (0, store_1.useProductTreeRootNodeId)();
    var selectedStrategyNodeId = (0, store_1.useSelectedTreeNodeId)();
    var validationSchema = (0, validation_schema_1.useAttributeValidationSchema)();
    var addAttributesToSeveralNodes = (0, attributes_1.useAddAttributesToNode)().addAttributesToSeveralNodes;
    var _c = __read((0, react_1.useState)(null), 2), addToOtherNodesDialogConfig = _c[0], setAddToOtherNodesDialogConfig = _c[1];
    var _d = __read((0, react_1.useState)(false), 2), isLoading = _d[0], setIsLoading = _d[1];
    var _e = (0, attributes_1.useGetMissingAttributes)({
        productTreeRootNodeId: productTreeRootNodeId,
        productTreeNodeId: selectedStrategyNodeId,
        options: { skip: !open },
    }), attributeOptionList = _e.attributeOptionList, loading = _e.loading, isSearching = _e.isSearching, error = _e.error, moreItemsLoading = _e.moreItemsLoading, hasNextPage = _e.hasNextPage, fetchMore = _e.fetchMore, refetch = _e.refetch;
    var dispatchError = function (attributes, error) {
        addError(t({
            key: 'ADD_ENTITY_ERROR',
            values: {
                count: attributes.length,
                entity: String(t({ key: 'ATTRIBUTE' })).toLowerCase(),
                b: localization_utils_1.renderBoldText,
            },
        }), error);
    };
    var handleAddItemsToHistory = function (attributes, treeNodePaths) {
        var e_1, _a, e_2, _b, e_3, _c;
        var historyItems = [];
        try {
            for (var treeNodePaths_1 = __values(treeNodePaths), treeNodePaths_1_1 = treeNodePaths_1.next(); !treeNodePaths_1_1.done; treeNodePaths_1_1 = treeNodePaths_1.next()) {
                var treeNodePath = treeNodePaths_1_1.value;
                try {
                    for (var attributes_2 = (e_2 = void 0, __values(attributes)), attributes_2_1 = attributes_2.next(); !attributes_2_1.done; attributes_2_1 = attributes_2.next()) {
                        var attribute = attributes_2_1.value;
                        var values = attribute.values, name_1 = attribute.name;
                        historyItems.push({
                            id: name_1,
                            entityType: store_1.ChangeEntityType.Attribute,
                            actionType: store_1.ChangeActionType.Add,
                            path: treeNodePath,
                        });
                        try {
                            for (var values_1 = (e_3 = void 0, __values(values)), values_1_1 = values_1.next(); !values_1_1.done; values_1_1 = values_1.next()) {
                                var valueItem = values_1_1.value;
                                var historyItem = {
                                    id: "".concat(name_1).concat(store_1.LABEL_VALUE_PAIR_SEPARATOR).concat(valueItem.name),
                                    entityType: store_1.ChangeEntityType.Value,
                                    actionType: store_1.ChangeActionType.Add,
                                    path: treeNodePath,
                                };
                                historyItems.push(historyItem);
                            }
                        }
                        catch (e_3_1) { e_3 = { error: e_3_1 }; }
                        finally {
                            try {
                                if (values_1_1 && !values_1_1.done && (_c = values_1.return)) _c.call(values_1);
                            }
                            finally { if (e_3) throw e_3.error; }
                        }
                    }
                }
                catch (e_2_1) { e_2 = { error: e_2_1 }; }
                finally {
                    try {
                        if (attributes_2_1 && !attributes_2_1.done && (_b = attributes_2.return)) _b.call(attributes_2);
                    }
                    finally { if (e_2) throw e_2.error; }
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (treeNodePaths_1_1 && !treeNodePaths_1_1.done && (_a = treeNodePaths_1.return)) _a.call(treeNodePaths_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        dispatch((0, store_1.enrichAIAddChanges)(selectedCatalogId, historyItems));
    };
    var handleUpdateProductPresentation = function (relatedTreeNodeIds) { return refetchQueriesOnStrategyAttributeChange({
        selectedTreeNodeId: selectedStrategyNodeId,
        relatedTreeNodeIds: relatedTreeNodeIds,
    }); };
    var handleAddAttributesToSelectedNode = function (_a) {
        var attributes = _a.attributes;
        return __awaiter(void 0, void 0, void 0, function () {
            var updatedTreeNodePaths, error_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, 3, 4]);
                        return [4 /*yield*/, addAttributesToSeveralNodes(attributes, [selectedStrategyNodeId])];
                    case 1:
                        updatedTreeNodePaths = _b.sent();
                        handleAddItemsToHistory(attributes, updatedTreeNodePaths);
                        setAddToOtherNodesDialogConfig(attributes);
                        return [3 /*break*/, 4];
                    case 2:
                        error_1 = _b.sent();
                        dispatchError(attributes, error_1.message);
                        return [3 /*break*/, 4];
                    case 3:
                        onClose();
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    var handleAddAttributesToOtherNodes = function (selectedNodeIds) { return __awaiter(void 0, void 0, void 0, function () {
        var updatedTreeNodePaths, entities, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsLoading(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    return [4 /*yield*/, addAttributesToSeveralNodes(addToOtherNodesDialogConfig, selectedNodeIds)];
                case 2:
                    updatedTreeNodePaths = _a.sent();
                    handleAddItemsToHistory(addToOtherNodesDialogConfig, updatedTreeNodePaths);
                    entities = addToOtherNodesDialogConfig.map(function (_a) {
                        var name = _a.name;
                        return name;
                    }).join(', ');
                    addSuccess(t({
                        key: 'ADD_ENTITY_TO_OTHER_NODES_SUCCESS',
                        values: {
                            count: addToOtherNodesDialogConfig.length,
                            entity: String(t({ key: 'ATTRIBUTE' })).toLowerCase(),
                            entityName: entities,
                        },
                    }));
                    void handleUpdateProductPresentation(selectedNodeIds);
                    return [3 /*break*/, 5];
                case 3:
                    error_2 = _a.sent();
                    dispatchError(addToOtherNodesDialogConfig, error_2.message);
                    void handleUpdateProductPresentation();
                    return [3 /*break*/, 5];
                case 4:
                    setIsLoading(false);
                    setAddToOtherNodesDialogConfig(null);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var handleAddToOtherNodesDialogClose = function () {
        setAddToOtherNodesDialogConfig(null);
        void handleUpdateProductPresentation();
    };
    return (react_1.default.createElement(react_1.default.Fragment, null,
        open && (react_1.default.createElement(generic_1.CreateEntityDialog, { allowCreation: true, title: t({ key: 'ADD_ENTITY_TO_NODE', values: { entity: String(t({ key: 'ATTRIBUTE' })).toLowerCase() } }), formId: CREATE_ATTRIBUTE_FORM_ID, validationSchema: validationSchema, valueOptionListStrategyNodeId: productTreeRootNodeId, attributesOptionList: attributeOptionList, isLoading: loading, isSearching: isSearching, moreItemsLoading: moreItemsLoading, hasNextPage: hasNextPage, onSearch: refetch, fetchMore: fetchMore, error: error === null || error === void 0 ? void 0 : error.message, onSubmit: handleAddAttributesToSelectedNode, onClose: onClose })),
        !!addToOtherNodesDialogConfig && (react_1.default.createElement(add_to_other_nodes_dialog_component_1.AddToOtherNodesDialog, { onClose: handleAddToOtherNodesDialogClose, onSubmit: handleAddAttributesToOtherNodes, attributes: addToOtherNodesDialogConfig, isLoading: isLoading }))));
};
exports.AddAttributeContainer = AddAttributeContainer;
exports.default = exports.AddAttributeContainer;
