"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAttributes = void 0;
var react_1 = require("react");
var types_1 = require("@groupby/enrich-sdk/resource/_jsonapi/types");
var predicate_language_1 = require("@groupby/predicate-language");
var react_redux_1 = require("react-redux");
var formal_attributes_1 = require("../formal-attributes");
var feature_flags_1 = require("../../feature-flags");
var hooks_1 = require("../../hooks");
var localization_1 = require("../../localization");
var store_1 = require("../../store");
var useAttributes = function () {
    var t = (0, localization_1.useLocalization)().formatMessage;
    var featureFlagsService = (0, hooks_1.useFeatureFlagsService)().featureFlagsService;
    var isMultiFacetNavEnabled = featureFlagsService.isFeatureEnabled(feature_flags_1.FeatureFlag.MultiFacetNav);
    var client = (0, hooks_1.useEnrichClient)();
    var dispatch = (0, react_redux_1.useDispatch)();
    var addError = (0, hooks_1.useNotification)().addError;
    var token = (0, hooks_1.useToken)();
    var selectedOrganizations = (0, store_1.useSelectedOrganizationsSelector)();
    var attributes = (0, store_1.useAttributesSelector)();
    var _a = __read((0, react_1.useState)(false), 2), isLoading = _a[0], setIsLoading = _a[1];
    var _b = (0, formal_attributes_1.useFormalAttributes)(), loadFormalAttributes = _b.loadFormalAttributes, getAttributeName = _b.getAttributeName, areFormalAttributesLoading = _b.isLoading;
    var loadAttributes = (0, react_1.useCallback)(function () { return __awaiter(void 0, void 0, void 0, function () {
        var payload, attributes_1, e_1;
        var _a, _b, _c, _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    setIsLoading(true);
                    payload = { response: {} };
                    _e.label = 1;
                case 1:
                    _e.trys.push([1, 3, 4, 5]);
                    return [4 /*yield*/, client.products.getPage({
                            filter: (0, predicate_language_1.where)((0, predicate_language_1.f)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["organizationId"], ["organizationId"]))), '@<', selectedOrganizations.map(function (_a) {
                                var id = _a.id;
                                return (0, predicate_language_1.uuid)(id);
                            })),
                            fields: { meta: [types_1.CollectionMetaParam.REFINEMENTS] },
                            page: {
                                limit: 0,
                            },
                        }, payload)];
                case 2:
                    _e.sent();
                    attributes_1 = (_d = (_c = (_b = (_a = payload.response.meta) === null || _a === void 0 ? void 0 : _a.beta) === null || _b === void 0 ? void 0 : _b.refinements) === null || _c === void 0 ? void 0 : _c.actualAttributes) !== null && _d !== void 0 ? _d : [];
                    dispatch((0, store_1.attributesSetAttributes)({ attributes: attributes_1, isMultiFacetNavEnabled: isMultiFacetNavEnabled }));
                    return [3 /*break*/, 5];
                case 3:
                    e_1 = _e.sent();
                    addError(t({ key: 'PRODUCT_REFINEMENTS_LOADING_ERROR' }));
                    dispatch((0, store_1.attributesSetAttributes)({ attributes: [], isMultiFacetNavEnabled: isMultiFacetNavEnabled }));
                    return [3 /*break*/, 5];
                case 4:
                    setIsLoading(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); }, [addError, client, dispatch, selectedOrganizations, t, isMultiFacetNavEnabled]);
    (0, react_1.useEffect)(function () {
        if (selectedOrganizations.length && token) {
            void loadAttributes();
        }
    }, [loadAttributes, selectedOrganizations, token]);
    var actualAttributeNameIds = (0, react_1.useMemo)(function () { return Array.from(new Set(attributes.map(function (_a) {
        var attributeId = _a.attributeId;
        return attributeId;
    }))); }, [attributes]);
    (0, react_1.useEffect)(function () {
        if (actualAttributeNameIds.length) {
            void loadFormalAttributes(actualAttributeNameIds.map(function (id) { return ({ attributeId: id }); }));
        }
    }, [actualAttributeNameIds, loadFormalAttributes]);
    var attributeNameOptions = (0, react_1.useMemo)(function () { return actualAttributeNameIds.map(function (id) { return ({ id: id, label: getAttributeName(id) }); }); }, [actualAttributeNameIds, getAttributeName]);
    return {
        isLoading: isLoading || areFormalAttributesLoading,
        attributeNameOptions: attributeNameOptions,
        areFormalAttributesLoading: areFormalAttributesLoading,
    };
};
exports.useAttributes = useAttributes;
var templateObject_1;
