"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useTableRows = void 0;
var react_1 = __importStar(require("react"));
var date_fns_1 = require("date-fns");
var components_1 = require("../components");
var table_1 = require("../components/table");
var constants_1 = require("../../constants");
var icons_1 = require("../../icons");
var localization_1 = require("../../localization");
var state_1 = require("../../store/state");
var useTableRows = function (_a) {
    var activeColumns = _a.activeColumns, getAdditionalRows = _a.getAdditionalRows, getColumnFilterValue = _a.getColumnFilterValue;
    var t = (0, localization_1.useLocalization)().formatMessage;
    var getBaseRows = (0, react_1.useCallback)(function (product, options) {
        var _a, _b;
        var _c;
        var id = product.id, proprietaryId = product.proprietaryId, images = product.images, title = product.title, description = product.description, url = product.url, variantGroupId = product.variantGroupId, createdAt = product.createdAt, updatedAt = product.updatedAt, active = product.active, attributes = product.attributes;
        var curatedAttributesFilter = getColumnFilterValue === null || getColumnFilterValue === void 0 ? void 0 : getColumnFilterValue(constants_1.ColumnId.CuratedAttributes);
        var filteredAttributes = curatedAttributesFilter
            ? attributes.filter(function (_a) {
                var id = _a.id;
                return curatedAttributesFilter.includes(id);
            })
            : attributes;
        var standardFieldsMap = (_a = {},
            _a[state_1.PRODUCT_EDITOR_TABLE_COLUMNS.PRODUCT_ID] = id,
            _a[state_1.PRODUCT_EDITOR_TABLE_COLUMNS.SKU_ID] = proprietaryId,
            _a[state_1.PRODUCT_EDITOR_TABLE_COLUMNS.NAME] = title,
            _a[state_1.PRODUCT_EDITOR_TABLE_COLUMNS.DESCRIPTION] = description,
            _a[state_1.PRODUCT_EDITOR_TABLE_COLUMNS.PRODUCT_GROUP] = variantGroupId,
            _a[state_1.PRODUCT_EDITOR_TABLE_COLUMNS.CREATED_DATE] = (0, date_fns_1.format)(createdAt, constants_1.DATE_TIME_FORMAT_DASH),
            _a[state_1.PRODUCT_EDITOR_TABLE_COLUMNS.LAST_MODIFIED] = (0, date_fns_1.format)(updatedAt, constants_1.DATE_TIME_FORMAT_DASH),
            _a[state_1.PRODUCT_EDITOR_TABLE_COLUMNS.URL] = url,
            _a[state_1.PRODUCT_EDITOR_TABLE_COLUMNS.ACTIVE_STATUS] = t({ key: active ? 'ACTIVE' : 'INACTIVE' }).toLowerCase(),
            _a);
        var isExpanded = (options !== null && options !== void 0 ? options : {}).isExpanded;
        var imageSrc = (_c = images[0]) === null || _c === void 0 ? void 0 : _c.sourceUrl;
        return _b = {},
            _b[state_1.PRODUCT_EDITOR_TABLE_COLUMNS.PRODUCT_ID] = react_1.default.createElement(table_1.TextCell, { key: "id" }, id),
            _b[state_1.PRODUCT_EDITOR_TABLE_COLUMNS.SKU_ID] = react_1.default.createElement(table_1.TextCell, { key: "proprietaryId" }, proprietaryId),
            _b[state_1.PRODUCT_EDITOR_TABLE_COLUMNS.IMAGE] = react_1.default.createElement(table_1.ImageCell, { key: "image", expanded: isExpanded, src: imageSrc, alt: title }),
            _b[state_1.PRODUCT_EDITOR_TABLE_COLUMNS.NAME] = react_1.default.createElement(table_1.LinkCell, { key: "name", multiline: true, tooltipTitle: title, href: url, target: "_blank", textSize: "small", expanded: isExpanded }, title),
            _b[state_1.PRODUCT_EDITOR_TABLE_COLUMNS.DESCRIPTION] = react_1.default.createElement(table_1.TextCell, { key: "description", multiline: true, tooltipTitle: description, textSize: "small", expanded: isExpanded }, description),
            _b[state_1.PRODUCT_EDITOR_TABLE_COLUMNS.PRODUCT_GROUP] = react_1.default.createElement(table_1.TextCell, { key: "productGroup" }, variantGroupId),
            _b[state_1.PRODUCT_EDITOR_TABLE_COLUMNS.CREATED_DATE] = react_1.default.createElement(table_1.TextCell, { key: "createdDate" }, standardFieldsMap[state_1.PRODUCT_EDITOR_TABLE_COLUMNS.CREATED_DATE]),
            _b[state_1.PRODUCT_EDITOR_TABLE_COLUMNS.LAST_MODIFIED] = react_1.default.createElement(table_1.TextCell, { key: "updatedDate" }, standardFieldsMap[state_1.PRODUCT_EDITOR_TABLE_COLUMNS.LAST_MODIFIED]),
            _b[state_1.PRODUCT_EDITOR_TABLE_COLUMNS.URL] = react_1.default.createElement(table_1.LinkCell, { key: "url", href: url }, url && react_1.default.createElement(icons_1.LinkIcon, null)),
            // descoped for MVP
            // [COLUMNS.PRICE]: <StyledCell align="left">{null}</StyledCell>,
            _b[state_1.PRODUCT_EDITOR_TABLE_COLUMNS.ACTIVE_STATUS] = react_1.default.createElement(table_1.TextCell, { key: "activeStatus" }, standardFieldsMap[state_1.PRODUCT_EDITOR_TABLE_COLUMNS.ACTIVE_STATUS]),
            _b[state_1.PRODUCT_EDITOR_TABLE_COLUMNS.CURATED_ATTRIBUTES] = (react_1.default.createElement(table_1.ConsolidatedListCell, { key: "attributes", expanded: isExpanded, valueNumber: filteredAttributes.length },
                react_1.default.createElement(components_1.Attributes, { attributes: filteredAttributes }))),
            _b[state_1.PRODUCT_EDITOR_TABLE_COLUMNS.STANDARD_FIELDS] = (react_1.default.createElement(table_1.ConsolidatedListCell, { key: "standardFields", expanded: isExpanded, valueNumber: Object.keys(standardFieldsMap).length },
                react_1.default.createElement(components_1.ConsolidatedList, { data: standardFieldsMap }))),
            _b;
    }, [getColumnFilterValue, t]);
    var getTableRowsTemplate = (0, react_1.useCallback)(function (product, options) { return (__assign(__assign({}, getBaseRows(product, options)), getAdditionalRows === null || getAdditionalRows === void 0 ? void 0 : getAdditionalRows(product, options))); }, [getAdditionalRows, getBaseRows]);
    return (0, react_1.useCallback)(function (product, options) { return (activeColumns.map(function (item, index) { var _a; return (_a = getTableRowsTemplate(product, options)[item]) !== null && _a !== void 0 ? _a : react_1.default.createElement(table_1.TextCell, { key: index, align: "left" }); })); }, [activeColumns, getTableRowsTemplate]);
};
exports.useTableRows = useTableRows;
