"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SettingsSelector = void 0;
var react_1 = __importStar(require("react"));
var ui_components_1 = require("@groupby/ui-components");
var material_1 = require("@mui/material");
var options_list_1 = require("./options-list");
var settings_selector_styles_1 = require("./settings-selector.styles");
var localization_1 = require("../../../localization");
var SettingsSelector = function (_a) {
    var isLoading = _a.isLoading, options = _a.options, selectedId = _a.selectedId, error = _a.error, placeholder = _a.placeholder, selectedSectionTitle = _a.selectedSectionTitle, listSectionTitle = _a.listSectionTitle, emptyDataText = _a.emptyDataText, label = _a.label, onSelect = _a.onSelect, className = _a.className, hideSecondaryAction = _a.hideSecondaryAction;
    var t = (0, localization_1.useLocalization)().formatMessage;
    var _b = __read((0, react_1.useState)(null), 2), menuAnchorEl = _b[0], setMenuAnchorEl = _b[1];
    var _c = __read((0, react_1.useState)(''), 2), searchTerm = _c[0], setSearchTerm = _c[1];
    var isOpen = !!menuAnchorEl;
    var handleListItemClick = function (e) {
        setMenuAnchorEl(e.currentTarget);
    };
    var _d = (0, react_1.useMemo)(function () { return options === null || options === void 0 ? void 0 : options.reduce(function (acc, item) {
        var _a;
        if (item.id === selectedId) {
            acc.selectedOption = item;
            return acc;
        }
        acc.restOptions = ((_a = acc.restOptions) !== null && _a !== void 0 ? _a : []).concat(item);
        return acc;
    }, {}); }, [options, selectedId]), selectedOption = _d.selectedOption, restOptions = _d.restOptions;
    var handleMenuItemClick = function (id) {
        setMenuAnchorEl(null);
        onSelect(id);
    };
    var handleCloseMenu = function () {
        setMenuAnchorEl(null);
        setSearchTerm('');
    };
    var handleSearchChange = function (e) {
        setSearchTerm(e.currentTarget.value);
    };
    var filteredOptions = (0, react_1.useMemo)(function () { return (searchTerm === ''
        ? restOptions
        : restOptions === null || restOptions === void 0 ? void 0 : restOptions.filter(function (option) { return option.title.toLowerCase().includes(searchTerm.toLowerCase()); })); }, [searchTerm, restOptions]);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        label && (react_1.default.createElement(ui_components_1.Typography, { strong: true, variant: "overline", color: "textSecondary" }, label)),
        react_1.default.createElement(settings_selector_styles_1.StyledSettingsSelector, { className: className },
            react_1.default.createElement(settings_selector_styles_1.StyledList, null,
                react_1.default.createElement(settings_selector_styles_1.StyledListItem, { disabled: isLoading, onClick: handleListItemClick },
                    react_1.default.createElement(material_1.ListItemText, { primary: selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.title, secondary: selectedOption ? '' : placeholder }),
                    isLoading ? (react_1.default.createElement(material_1.CircularProgress, { size: "small" })) : (react_1.default.createElement(settings_selector_styles_1.StyledArrowIcon, { variant: "downArrow", "$menuOpen": !!menuAnchorEl, color: ui_components_1.baseColors.primaryBlueMed400 }))))),
        react_1.default.createElement(settings_selector_styles_1.StyledMenu, { keepMounted: true, anchorEl: menuAnchorEl, open: isOpen, onClose: handleCloseMenu, elevation: 0, anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
            }, transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
            } },
            react_1.default.createElement(settings_selector_styles_1.StyledMenuContentWrapper, null,
                react_1.default.createElement(settings_selector_styles_1.StyledSearchInput, { onChange: handleSearchChange, value: searchTerm, placeholder: t({ key: 'SEARCH' }), startAdornment: react_1.default.createElement(material_1.InputAdornment, { position: "start" },
                        react_1.default.createElement(ui_components_1.Icon, { variant: "search", color: ui_components_1.baseColors.primaryBlueMed400, className: "search-icon" })) }),
                selectedId && !error && (react_1.default.createElement(react_1.default.Fragment, null,
                    react_1.default.createElement(options_list_1.OptionsList, { isSelected: true, title: selectedSectionTitle, options: selectedOption ? [selectedOption] : [] }),
                    react_1.default.createElement(material_1.Divider, null))),
                react_1.default.createElement(options_list_1.OptionsList, { hideSecondaryAction: hideSecondaryAction, title: listSectionTitle, options: filteredOptions, onClick: handleMenuItemClick, noData: react_1.default.createElement(ui_components_1.Typography, { variant: "body2", color: "textSecondary" }, emptyDataText), errorMessage: error })))));
};
exports.SettingsSelector = SettingsSelector;
exports.default = exports.SettingsSelector;
