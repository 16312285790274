"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductValueControl = void 0;
var react_1 = __importStar(require("react"));
var bulk_edit_product_attributes_dialog_styles_1 = require("../bulk-edit-product-attributes-dialog.styles");
var attributes_1 = require("../../../../client/attributes");
var values_1 = require("../../../../client/values");
var autocomplete_tags_1 = require("../../../../generic/autocomplete-tags");
var icons_1 = require("../../../../../icons");
var localization_1 = require("../../../../../localization");
var store_1 = require("../../../../../store");
var ProductValueControl = function (_a) {
    var isDisabled = _a.isDisabled, selectedAttributeId = _a.selectedAttributeId, selectedValues = _a.selectedValues, onChange = _a.onChange;
    var t = (0, localization_1.useLocalization)().formatMessage;
    var productTreeNodeId = (0, store_1.useSelectedTreeNodeId)();
    var selectedCatalogId = (0, store_1.useSelectedCatalogId)();
    var _b = (0, values_1.useGetStrategyValueOptionList)({
        productTreeNodeId: productTreeNodeId,
        catalogId: selectedCatalogId,
        attributeName: selectedAttributeId,
        options: { skip: !selectedAttributeId, fetchPolicy: 'network-only' },
    }), data = _b.data, isLoading = _b.isLoading, _c = _b.pagination, hasNextPage = _c.hasNextPage, isLoadingMore = _c.isLoadingMore, onNextPage = _c.onNextPage, refetch = _b.refetch;
    var options = (0, react_1.useMemo)(function () { return (data ? __spreadArray(__spreadArray([], __read(data === null || data === void 0 ? void 0 : data.map(function (_a) {
        var value = _a.node.value;
        return ({ label: value, value: value });
    })), false), [
        {
            label: t({ key: 'SET_TO_NOT_APPLICABLE_LABEL' }),
            value: attributes_1.SpecialAttributeValue.NotApplicable,
        }
    ], false) : []); }, [data, t]);
    var getChipProps = function () { return ({ accent: true, icon: react_1.default.createElement(icons_1.AISparkleIcon, null) }); };
    var renderOptionLabel = (0, react_1.useCallback)(function (option) { return (react_1.default.createElement(bulk_edit_product_attributes_dialog_styles_1.StyledOptionWrapper, null,
        option.label,
        option.value !== attributes_1.SpecialAttributeValue.NotApplicable && react_1.default.createElement(icons_1.AISparkleIcon, null))); }, []);
    var renderTagLabel = (0, react_1.useCallback)(function (value) { return (value === attributes_1.SpecialAttributeValue.NotApplicable
        ? t({ key: 'NOT_APPLICABLE_SHORT' }) : value); }, [t]);
    return (react_1.default.createElement(autocomplete_tags_1.AutocompleteTags, { isDisabled: isDisabled, onChange: onChange, options: options, selected: selectedValues, placeholder: t({ key: 'BULK_EDIT_VALUES_DIALOG_PLACEHOLDER' }), hasNextPage: hasNextPage, isLoading: isLoading, moreItemsLoading: isLoadingMore, noOptionsText: t({ key: 'NO_DATA_AVAILABLE' }), onLoadMore: onNextPage, onSearch: refetch, getChipProps: getChipProps, renderOptionLabel: renderOptionLabel, renderTagLabel: renderTagLabel }));
};
exports.ProductValueControl = ProductValueControl;
exports.default = exports.ProductValueControl;
