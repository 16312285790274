"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCreateNewCatalog = void 0;
var react_1 = require("react");
var client_utils_1 = require("../../client.utils");
var product_tree_1 = require("../../product-tree");
var create_tags_hook_1 = require("../../tags/create-tags.hook");
var ticket_1 = require("../../ticket");
var create_catalog_supply_hook_1 = require("../create-catalog-supply.hook");
var create_catalog_hook_1 = require("../create-catalog.hook");
var get_catalogs_query_1 = require("../get-catalogs.query");
var upload_catalog_hook_1 = require("../upload-catalog.hook");
var store_1 = require("../../../../store");
var useCreateNewCatalog = function () {
    var selectedProjectId = (0, store_1.useSelectedProjectId)();
    var catalogDefaultTags = (0, store_1.useSelectedProjectTags)();
    var createCatalog = (0, create_catalog_hook_1.useCreateCatalog)(selectedProjectId).createCatalog;
    var uploadCatalog = (0, upload_catalog_hook_1.useUploadCatalog)().uploadCatalog;
    var createCatalogSupply = (0, create_catalog_supply_hook_1.useCreateCatalogSupply)().createCatalogSupply;
    var attachGlobalProductTreeToCatalog = (0, product_tree_1.useAttachGlobalProductTreeToCatalog)().attachGlobalProductTreeToCatalog;
    var createTicketSequenceForCatalog = (0, ticket_1.useCreateTicketSequenceForCatalog)().createTicketSequenceForCatalog;
    var createTags = (0, create_tags_hook_1.useCreateTags)().createTags;
    var _a = __read((0, react_1.useState)(false), 2), isLoading = _a[0], setIsLoading = _a[1];
    var _b = __read((0, react_1.useState)(null), 2), error = _b[0], setError = _b[1];
    var createNewCatalog = (0, react_1.useCallback)(function (fileToUpload) { return __awaiter(void 0, void 0, void 0, function () {
        var title, file, createCatalogResponse, _a, catalogId, catalogName, createTagsResponse, uploadCatalogResponseData, uploadId, createCatalogSupplyResponse, createTicketSequenceForCatalogResponse, e_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!selectedProjectId) {
                        return [2 /*return*/];
                    }
                    setError(null);
                    setIsLoading(true);
                    title = fileToUpload.id, file = fileToUpload.file;
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 8, 9, 10]);
                    return [4 /*yield*/, createCatalog({ variables: { title: title, description: 'test description' } })];
                case 2:
                    createCatalogResponse = _b.sent();
                    (0, client_utils_1.genericGraphQLErrorsHandler)(createCatalogResponse);
                    _a = createCatalogResponse.data.catalog, catalogId = _a.id, catalogName = _a.title;
                    return [4 /*yield*/, createTags({ variables: { reference: catalogId, tags: catalogDefaultTags } })];
                case 3:
                    createTagsResponse = _b.sent();
                    (0, client_utils_1.genericGraphQLErrorsHandler)(createTagsResponse);
                    return [4 /*yield*/, uploadCatalog({ variables: { name: catalogName, file: file } })];
                case 4:
                    uploadCatalogResponseData = (_b.sent()).data;
                    uploadId = uploadCatalogResponseData.upload.id;
                    return [4 /*yield*/, createCatalogSupply({ variables: { catalogId: catalogId, uploadId: uploadId } })];
                case 5:
                    createCatalogSupplyResponse = _b.sent();
                    (0, client_utils_1.genericGraphQLErrorsHandler)(createCatalogSupplyResponse);
                    return [4 /*yield*/, attachGlobalProductTreeToCatalog({ variables: { catalogId: catalogId } })];
                case 6:
                    _b.sent();
                    return [4 /*yield*/, createTicketSequenceForCatalog({
                            catalogId: catalogId,
                            tags: catalogDefaultTags,
                            tickets: ticket_1.TICKETS_FOR_THE_NEW_CATALOG,
                            variables: { projectId: selectedProjectId },
                            awaitRefetchQueries: true,
                            refetchQueries: [get_catalogs_query_1.GET_CATALOGS],
                        })];
                case 7:
                    createTicketSequenceForCatalogResponse = _b.sent();
                    (0, client_utils_1.genericGraphQLErrorsHandler)(createTicketSequenceForCatalogResponse);
                    return [2 /*return*/, catalogId];
                case 8:
                    e_1 = _b.sent();
                    setError(e_1);
                    throw new Error(e_1.message);
                case 9:
                    setIsLoading(false);
                    return [7 /*endfinally*/];
                case 10: return [2 /*return*/];
            }
        });
    }); }, [
        catalogDefaultTags,
        selectedProjectId,
        attachGlobalProductTreeToCatalog,
        createCatalog,
        createCatalogSupply,
        createTags,
        createTicketSequenceForCatalog,
        uploadCatalog,
    ]);
    return {
        createNewCatalog: createNewCatalog,
        loading: isLoading,
        error: error,
    };
};
exports.useCreateNewCatalog = useCreateNewCatalog;
