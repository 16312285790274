"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGetAttributesAsColumns = void 0;
var react_1 = require("react");
var graphql_1 = require("../../../../../../../__generated__/graphql");
var constants_1 = require("../../hooks/constants");
var attributes_1 = require("../../../../../../client/attributes");
var client_constants_1 = require("../../../../../../client/client.constants");
var measures_1 = require("../../../../../../client/measures");
var product_table_dynamic_columns_1 = require("../../../../../../hooks/product-table-dynamic-columns");
var models_1 = require("../../../../../../models");
var utils_1 = require("../../../../../../utils");
var feature_flags_1 = require("../../../../../../../feature-flags");
var hooks_1 = require("../../../../../../../hooks");
var useGetAttributesAsColumns = function (_a) {
    var catalogId = _a.catalogId, productTreeNodeId = _a.productTreeNodeId, productAttributeFilter = _a.productAttributeFilter, productMeasureFilter = _a.productMeasureFilter, _b = _a.sortingItem, sortingItem = _b === void 0 ? models_1.SortingItem.SortByTextAsc : _b, options = _a.options, filterOptions = _a.filterOptions;
    var featureFlagsService = (0, hooks_1.useFeatureFlagsService)().featureFlagsService;
    var strategyProductAttributeFilter = (0, react_1.useMemo)(function () { return (productAttributeFilter && {
        some: {
            label: productAttributeFilter.label,
        },
    }); }, [productAttributeFilter]);
    var _c = (0, attributes_1.useGetStrategyAttributes)({
        productTreeNodeId: productTreeNodeId,
        sortingItem: sortingItem,
        pageSize: client_constants_1.REQUEST_LIMIT_MEDIUM,
        filter: strategyProductAttributeFilter,
        options: options,
    }), attributesInStrategyData = _c.data, attributesInStrategyLoading = _c.isLoading, attributesInStrategyError = _c.error, _d = _c.pagination, attributesInStrategyMoreItemsLoading = _d.isLoadingMore, attributesInStrategyHasNextPage = _d.hasNextPage, fetchMoreAttributesInStrategy = _d.onNextPage;
    var productAttributesFilter = (0, react_1.useMemo)(function () { return ({
        label: __assign({}, productAttributeFilter === null || productAttributeFilter === void 0 ? void 0 : productAttributeFilter.label),
        values: {
            origin: {
                in: [graphql_1.ProductAttributeOrigin.Original],
            },
        },
        isRequirement: false,
    }); }, [productAttributeFilter]);
    var _e = (0, attributes_1.useGetAggregatedTextAttributes)({
        catalogId: catalogId,
        productTreeNodeId: productTreeNodeId,
        sortingItem: sortingItem,
        pageSize: client_constants_1.REQUEST_LIMIT_MEDIUM,
        options: options,
        filter: productAttributesFilter,
    }), productAggregateLabelsData = _e.data, productAggregateLabelsLoading = _e.isLoading, productAggregateLabelsError = _e.error, _f = _e.pagination, productAggregateLabelsMoreItemsLoading = _f.isLoadingMore, productLabelsHasNextPage = _f.hasNextPage, fetchMoreProductAggregateLabels = _f.onNextPage;
    var _g = (0, measures_1.useGetAggregatedProductMeasures)({
        catalogId: catalogId,
        productTreeNodeId: productTreeNodeId,
        sortingItem: sortingItem,
        filter: productMeasureFilter,
        pageSize: client_constants_1.REQUEST_LIMIT_MEDIUM,
        options: __assign(__assign({}, options), { skip: !featureFlagsService.isFeatureEnabled(feature_flags_1.FeatureFlag.Measures) || (options === null || options === void 0 ? void 0 : options.skip) }),
    }), productMeasuresData = _g.data, productMeasuresLoading = _g.isLoading, productMeasuresError = _g.error, _h = _g.pagination, productMeasuresMoreItemsLoading = _h.isLoadingMore, fetchMoreProductMeasures = _h.onNextPage, productMeasuresHasNextPage = _h.hasNextPage;
    var _j = __read((0, react_1.useState)(1), 2), pageNumber = _j[0], setPageNumber = _j[1];
    var hasNextPage = productLabelsHasNextPage || attributesInStrategyHasNextPage || productMeasuresHasNextPage;
    (0, react_1.useEffect)(function () {
        setPageNumber(1);
    }, [productTreeNodeId]);
    var onLoadMore = (0, react_1.useCallback)(function () { return __awaiter(void 0, void 0, void 0, function () {
        var promises, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!hasNextPage) {
                        return [2 /*return*/];
                    }
                    promises = [];
                    if (productLabelsHasNextPage) {
                        promises.push(fetchMoreProductAggregateLabels());
                    }
                    if (attributesInStrategyHasNextPage) {
                        promises.push(fetchMoreAttributesInStrategy());
                    }
                    if (productMeasuresHasNextPage) {
                        promises.push(fetchMoreProductMeasures());
                    }
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, Promise.all(promises)];
                case 2:
                    _a.sent();
                    setPageNumber(function (prevPage) { return prevPage + 1; });
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _a.sent();
                    throw new Error(error_1.message);
                case 4: return [2 /*return*/];
            }
        });
    }); }, [
        attributesInStrategyHasNextPage,
        hasNextPage,
        productLabelsHasNextPage,
        productMeasuresHasNextPage,
        fetchMoreAttributesInStrategy,
        fetchMoreProductAggregateLabels,
        fetchMoreProductMeasures,
    ]);
    var attributes = (0, react_1.useMemo)(function () {
        var _a, _b;
        if (attributesInStrategyLoading || productAggregateLabelsLoading || productMeasuresLoading) {
            return undefined;
        }
        if (!productAggregateLabelsData && !attributesInStrategyData) {
            return undefined;
        }
        var productAttributesList = (filterOptions === null || filterOptions === void 0 ? void 0 : filterOptions.includes(product_table_dynamic_columns_1.DynamicColumnsFilterOption.Original))
            ? (_a = productAggregateLabelsData === null || productAggregateLabelsData === void 0 ? void 0 : productAggregateLabelsData.map(function (_a) {
                var label = _a.node.label;
                return ({
                    id: label,
                    label: label,
                    isEditable: false,
                });
            })) !== null && _a !== void 0 ? _a : [] : [];
        var strategyAttributes = (filterOptions === null || filterOptions === void 0 ? void 0 : filterOptions.includes(product_table_dynamic_columns_1.DynamicColumnsFilterOption.Enriched))
            ? attributesInStrategyData !== null && attributesInStrategyData !== void 0 ? attributesInStrategyData : []
            : constants_1.BASE_PRODUCT_FIELDS.map(function (label) { return ({ node: { label: label } }); });
        var attributesInStrategyList = strategyAttributes.map(function (_a) {
            var label = _a.node.label;
            return ({
                id: (0, product_table_dynamic_columns_1.createColumnId)(label, { isAIEnriched: true }),
                label: label,
                isEditable: true,
            });
        });
        var productMeasuresList = (filterOptions === null || filterOptions === void 0 ? void 0 : filterOptions.includes(product_table_dynamic_columns_1.DynamicColumnsFilterOption.Measures))
            ? (_b = productMeasuresData === null || productMeasuresData === void 0 ? void 0 : productMeasuresData.map(function (_a) {
                var dimension = _a.node.dimension;
                return ({
                    id: (0, product_table_dynamic_columns_1.createColumnId)(dimension, { isMeasure: true }),
                    label: dimension,
                    isEditable: false,
                });
            })) !== null && _b !== void 0 ? _b : [] : [];
        // Order is important
        var attributeList = productAttributesList.concat(attributesInStrategyList)
            .reduce(function (acc, item) {
            var newAcc = __assign({}, acc);
            newAcc[item.label] = item;
            return newAcc;
        }, {});
        var sortedAttributes = __spreadArray(__spreadArray([], __read(Array.from(Object.values(attributeList))), false), __read(productMeasuresList), false).sort(function (a, b) { return (0, utils_1.compareTextCaseInsensitive)(a.label, b.label); });
        if (!hasNextPage) {
            return sortedAttributes;
        }
        var currentLastItemNumber = client_constants_1.REQUEST_LIMIT_MEDIUM * pageNumber;
        return sortedAttributes.slice(0, currentLastItemNumber);
    }, [
        attributesInStrategyLoading,
        productAggregateLabelsLoading,
        productMeasuresLoading,
        productAggregateLabelsData,
        attributesInStrategyData,
        filterOptions,
        productMeasuresData,
        hasNextPage,
        pageNumber,
    ]);
    return {
        isLoading: attributesInStrategyLoading || productAggregateLabelsLoading || productMeasuresLoading,
        pagination: {
            hasNextPage: hasNextPage,
            isLoadingMore: attributesInStrategyMoreItemsLoading || productAggregateLabelsMoreItemsLoading || productMeasuresMoreItemsLoading,
            onNextPage: onLoadMore,
        },
        error: attributesInStrategyError || productAggregateLabelsError || productMeasuresError,
        attributes: attributes !== null && attributes !== void 0 ? attributes : [],
    };
};
exports.useGetAttributesAsColumns = useGetAttributesAsColumns;
exports.default = exports.useGetAttributesAsColumns;
